import { type PropsWithChildren } from "react";

import ScrollContent from "~/components/shared/ScrollContent";
import { cn } from "~/utils/cn";

export type CalendarWrapperProps = PropsWithChildren<{
	isOverview?: boolean;
}>;

export default function CalendarWrapper({
	isOverview = false,
	children,
}: CalendarWrapperProps) {
	const content = (
		<div className={cn("module calendar", { overview: isOverview })}>
			<div className="calendar-inner">{children}</div>
		</div>
	);

	if (!isOverview) {
		return <ScrollContent>{content}</ScrollContent>;
	} else {
		return content;
	}
}
