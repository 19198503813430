export interface ContentElementProps {
	type: string;
}

export default function ContentElementUnknown({ type }: ContentElementProps) {
	return (
		<div className="module text my-6 bg-yellow-200 p-2 font-bold text-red-900">
			<>Missing Component for Content Element Type [{type}].</>
		</div>
	);
}
