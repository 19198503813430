import { useMemo } from "react";
import { FormattedMessage } from "react-intl";

import type * as typo3 from "~/modules/typo3/schema";
import ProjectCard, {
	type ProjectCardProps,
} from "~/components/shared/cards/ProjectCard";

type ProjectWithUid = ProjectCardProps["article"] & { uid: number };

function mapRelatedArticleToProjectCard(
	article: typo3.RelatedProject,
): ProjectWithUid {
	return {
		uid: article.uid,
		title: article.title,
		detailsUrl: article.detailsUrl,
		teasertext: article.teasertext,
		relatedMedia: article.relatedMedia,
		isGroupProject: false,
		students: article.students,
	};
}

export default function RelatedProjects({
	article,
}: {
	article: typo3.Article;
}) {
	const projectSubs = useMemo(
		() =>
			article.articleType === "projekt" &&
			article.isGroupProject &&
			article.subArticles &&
			article.subArticles.length > 0 &&
			article.subArticles.map(mapRelatedArticleToProjectCard),
		[article],
	);

	const projectSiblings = useMemo(
		() =>
			article.articleType === "projekt" &&
			article.articleParent &&
			article.siblingArticles &&
			article.siblingArticles.length > 0 &&
			article.siblingArticles.map(mapRelatedArticleToProjectCard),
		[article],
	);

	if (article.articleType !== "projekt") {
		return null;
	}

	let content;
	if (projectSubs) {
		content = (
			<>
				<div className="works-grid-header no-filter">
					<div className="title-container">
						<h2 className="content-head">
							<span>
								<FormattedMessage id="relatedProjects.subProjects.titleHead" />
							</span>
						</h2>
						<div className="label sub bottom">
							<FormattedMessage
								id="relatedProjects.subProjects.titleLabel"
								values={{ articleTitle: article.title }}
							/>
						</div>
					</div>
				</div>

				<div className="works-grid-container col-count-3 filterTargetWrapper">
					{projectSubs.map((sub) => (
						<ProjectCard key={sub.uid} article={sub} />
					))}
				</div>
			</>
		);
	} else if (projectSiblings) {
		content = (
			<>
				<div className="works-grid-header no-filter">
					<div className="title-container">
						<h2 className="content-head">
							<span>
								<FormattedMessage
									id="relatedProjects.siblingProjects.titleHead"
									values={{
										articleParentTitle:
											article.articleParent?.title ??
											undefined,
									}}
								/>
							</span>
						</h2>
					</div>
				</div>

				<div className="works-grid-container col-count-3 filterTargetWrapper">
					{projectSiblings.map((sibling) => (
						<ProjectCard key={sibling.uid} article={sibling} />
					))}
				</div>
			</>
		);
	} else {
		return null;
	}

	return (
		<div className="section works-grid module size-full" id="works">
			<div className="works-inner-wrapper inner">{content}</div>
		</div>
	);
}
