import type * as typo3 from "~/modules/typo3/schema";
import TeaserCard from "~/components/shared/cards/TeaserCard";

type LehrangebotCardProps = {
	article: typo3.ArticleCard;
};

export default function LehrangebotCard({ article }: LehrangebotCardProps) {
	return (
		<TeaserCard
			key={article.uid}
			mode="lehrangebot"
			title={article.title}
			image={article.relatedMedia[0]}
			href={article.detailsUrl}
			meta={
				<>
					<span>
						{article.relatedPersons
							.map(({ fullName }) => fullName)
							.join(", ")}
					</span>
					{article.articleType === "lehrangebot" && (
						<span>
							{article.courseModulAngebotColor && (
								<span
									className="color-dot"
									style={{
										backgroundColor:
											article.courseModulAngebotColor,
									}}
								/>
							)}
							{article.courseModulAngebotDescription ||
								article.courseModulAngebotTitle}
						</span>
					)}
				</>
			}
		/>
	);
}
