export class ScrollingToEvent extends CustomEvent<never> {
	constructor() {
		super("buha_scrollingTo");
	}
}

declare global {
	interface GlobalEventHandlersEventMap {
		buha_scrollingTo: ScrollingToEvent;
	}
}
