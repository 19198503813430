import type * as typo3 from "~/modules/typo3/schema";
import ProjectCard from "~/components/shared/cards/ProjectCard";

import { isProjectSearchDocument } from "./SearchResultsProject";

export type SearchResultsProjectProps = {
	documents: Array<typo3.SearchResultDocument>;
};

export default function SearchResultsSectionProject({
	documents,
}: SearchResultsProjectProps) {
	const colCount = Math.max(3, Math.min(7, Math.floor(documents.length / 3)));

	return (
		<div
			className={`works-grid-container filterTargetWrapper col-count-${colCount}`}
		>
			{documents
				.filter(isProjectSearchDocument)
				.map(({ data: article }) => (
					<ProjectCard key={article.uid} article={article} />
				))}
		</div>
	);
}
