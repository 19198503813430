import { Fragment, useMemo, type ReactNode } from "react";

export type OverviewAlphabeticalProps<T> = {
	mode: "persons-list" | "a-to-z";
	size: "full" | "half";

	/** Assumes the data is in the right order, might be important for pagination/windowing */
	data: Array<T>;
	getLetterForItem(value: T): string;
	renderItem(value: T): ReactNode;
	itemsPerRow: number;
};

export default function OverviewAlphabetical<T>({
	mode,
	size,
	data,
	getLetterForItem,
	renderItem,
	itemsPerRow,
}: OverviewAlphabeticalProps<T>) {
	const grouped = useMemo(() => {
		const grouped: Array<{ letter: string; items: Array<ReactNode> }> = [];

		for (const item of data) {
			const letter = getLetterForItem(item);
			let last = grouped[grouped.length - 1];
			if (last === undefined || last.letter !== letter) {
				last = { letter, items: [] };
				grouped.push(last);
			}
			last.items.push(renderItem(item));
		}

		return grouped;
	}, [data, getLetterForItem, renderItem]);

	return (
		<div className={`module overview-alphabetical size-${size} ${mode}`}>
			<div className="inner">
				<div
					className={`grid-container filterTargetWrapper columns-${itemsPerRow}`}
				>
					{grouped.map(({ letter, items }, groupIndex) => {
						const groupRows = Math.ceil(items.length / itemsPerRow);

						return (
							<Fragment key={groupIndex}>
								<div
									className="initial-letter"
									style={{ gridRowEnd: `span ${groupRows}` }}
								>
									<span className="initial-letter-content">
										{letter}
									</span>
								</div>

								{items.map((item, itemIndex) => (
									<Fragment key={itemIndex}>{item}</Fragment>
								))}
							</Fragment>
						);
					})}
				</div>
			</div>
		</div>
	);
}
