import SearchMarker from "~/components/SearchMarker";
import usePageData from "~/hooks/usePageData";

type EditInCMSButtonProps = {
	link: string;
};

function EditInCMSButton({ link }: EditInCMSButtonProps) {
	const pageData = usePageData();
	if (!pageData?.backendUser.logged) {
		return null;
	}

	return (
		<SearchMarker inverted>
			<a
				className="fixed bottom-0 right-0 z-[10000] mb-4 mr-1 flex items-center gap-1 rounded bg-zinc-200 p-1 text-black shadow-xl"
				href={link}
				target="_blank"
				rel="noreferrer"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 16 16"
					className="inline-block size-2"
					aria-hidden
				>
					<path
						fill="currentColor"
						d="m9.293 3.293-8 8A.997.997 0 0 0 1 12v3h3c.265 0 .52-.105.707-.293l8-8-3.414-3.414zM8.999 5l.5.5-5 5-.5-.5 5-5zM4 14H3v-1H2v-1l1-1 2 2-1 1zM13.707 5.707l1.354-1.354a.5.5 0 0 0 0-.707L12.354.939a.5.5 0 0 0-.707 0l-1.354 1.354 3.414 3.414z"
					/>
				</svg>
				<span>Edit</span>
			</a>
		</SearchMarker>
	);
}

export default EditInCMSButton;
