import { z } from "zod";

import { mapNonNullable } from "~/utils/nonNullable";

const VIDEO_URL_REGEX = /https:\/\/vimeo\.com\/([0-9]+)\/?$/;

export function getVimeoVideoIdFromUrl(url: string): undefined | number {
	return mapNonNullable(Number)(url.match(VIDEO_URL_REGEX)?.[1]);
}

export type VimeoVideoData = {
	// id: string;
	// title: string;
	uploadDate: string;
	// thumbnail: string;
	duration: number;
	// width: number;
	// height: number;
};

const vimeoVideoDataSchema = z.object({
	// id: z.number(),
	// title: z.string(),
	upload_date: z.string(),
	// thumbnail_small: z.string(),
	// thumbnail_medium: z.string(),
	// thumbnail_large: z.string(),
	duration: z.number(),
	// width: z.number(),
	// height: z.number(),
});

export async function getVimeoVideoData(
	videoId: number,
): Promise<VimeoVideoData> {
	const url = `https://vimeo.com/api/oembed.json?url=${encodeURIComponent(`https://vimeo.com/${videoId}`)}`;
	const res = await fetch(url);
	if (!res.ok) {
		throw new Error(
			`Got status code ${res.status} for vimeo video id "${videoId}"`,
		);
	}
	const vid = await vimeoVideoDataSchema.parseAsync(await res.json());
	return {
		// title: vid.title,
		uploadDate: String(new Date(vid.upload_date)),
		// thumbnail: vid.thumbnail_large,
		duration: vid.duration,
		// width: vid.width,
		// height: vid.height,
	};
}
