import { FormattedMessage } from "react-intl";

import { ShowConsentModalButton } from "~/components/ConsentModal";
import { useGlobal } from "~/context/global";
import type * as typo3 from "~/modules/typo3/schema";

export type PodcastProps = {
	podcast: typo3.Audio;
};

export default function Podcast({ podcast }: PodcastProps) {
	const havePodigeeConsent = useGlobal().consents.podigee;

	if (!havePodigeeConsent) {
		return (
			<div className="flex w-full flex-row items-center justify-center rounded-sm bg-gray-200 p-4 text-gray-800 shadow-md">
				<span className="text-center leading-relaxed">
					<FormattedMessage
						id="podigeePodcast.noConsent"
						values={{
							podcastLink: (children) => (
								<span className="button-wrapper">
									<a
										href={podcast.properties.originalUrl}
										target="_blank"
										rel="noreferrer"
										className="podcast-link"
									>
										{children}
									</a>
								</span>
							),
							showConsentModal: (children) => (
								<span className="button-wrapper">
									<ShowConsentModalButton>
										{children}
									</ShowConsentModalButton>
								</span>
							),
							br: <br />,
						}}
					/>
				</span>
			</div>
		);
	}

	if (podcast.properties.extension === "podigee") {
		return (
			<div className="podigee 2xl:h-34 h-44 sm:h-16 md:h-20 lg:h-64 xl:h-32">
				<iframe
					title={podcast.properties.title ?? undefined}
					src={podcast.publicUrl + "/embed?context=website"}
					className="podcast"
					style={{ width: "100%", height: "100%" }}
					frameBorder="0"
					allow=""
					allowFullScreen
				/>
			</div>
		);
	}
}
