import type * as typo3 from "~/modules/typo3/schema";
import { findFirstMediaByType } from "~/modules/typo3/media";
import HorizontalScrollCardContainer from "~/components/shared/HorizontalScrollCardContainer";
import Marquee from "~/components/shared/Marquee";
import TeaserCard from "~/components/shared/cards/TeaserCard";
import DateComp from "~/components/shared/Date";

export type PodcastStripeProps = {
	title: string;
	podcasts: Array<typo3.ArticleCard>;
};

export default function PodcastStripe({ title, podcasts }: PodcastStripeProps) {
	return (
		<div className="module podcast-stripe">
			<Marquee>{title}</Marquee>

			<HorizontalScrollCardContainer
				mode="podcasts"
				rows={[podcasts]}
				renderItem={renderPodcastCard}
			/>
		</div>
	);
}

function renderPodcastCard(article: typo3.ArticleCard) {

	const hasPodcast = findFirstMediaByType(article.relatedMedia, 'audio') ? true : false;

	return (
		<TeaserCard
			mode="timeline"
			className="small-basic"
			title={article.title}
			imageSourceName={hasPodcast ? "thumbnailFree" : "thumbnail"}
			meta={
				article.pubDate ? (
					<DateComp date={new Date(article.pubDate)} year />
				) : null
			}
			image={hasPodcast ? findFirstMediaByType(article.relatedMedia, 'audio') : article.relatedMedia[0]}
			href={article.detailsUrl}
		/>
	);
}
