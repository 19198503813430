import { useIntl } from "react-intl";

export function NewsletterSignupForm() {
	const intl = useIntl();

	return (
		<form
			className="newsletter-signup-form"
			action="https://www.burg-halle.de/phplist/lists/"
			target="_blank"
		>
			<input type="hidden" name="p" value="subscribe" />
			<input
				className="newsletter-signup-form__input"
				type="email"
				name="email"
				required
				placeholder={intl.formatMessage({
					id: "newsletterSignup.email",
				})}
			/>
			<input
				type="submit"
				className="newsletter-signup-form__button"
				value={intl.formatMessage({ id: "newsletterSignup.submit" })}
			/>
		</form>
	);
}
