export default function DarkSun() {
	return (
		<span id="darksun" className="icon" tabIndex={-1} aria-hidden={true}>
			<svg width="100" height="100" viewBox="0 0 100 100" fill="none">
				<rect
					x="33.5"
					y="19.5"
					width="34"
					height="62"
					rx="1.5"
					stroke="black"
					strokeWidth="3"
				/>
				<rect id="charge" x="35" y="21" width="31" height="59" />

				<path
					d="M41 16C41 14.3431 42.3431 13 44 13H57C58.6569 13 60 14.3431 60 16V18H41V16Z"
					fill="black"
				/>
			</svg>
		</span>
	);
}
