import type * as typo3 from "~/modules/typo3/schema";
import ContentHead from "~/components/shared/ContentHead";
import { Calendar } from "~/components/shared/calendar/Calendar";

type CalendarPluginProps = {
	data: typo3.ContentElementCalendar;
};

export default function CalendarPlugin({ data }: CalendarPluginProps) {
	const pluginData = data.content.data;

	return (
		<>
			<ContentHead data={data} />
			<Calendar data={pluginData} isOverview />
		</>
	);
}
