import { useMemo } from "react";

import { cn } from "~/utils/cn";
import type * as typo3 from "~/modules/typo3/schema";
import { getTypo3BackendUrl } from "~/modules/typo3/backend";
import ScrollContent from "~/components/shared/ScrollContent";
import ScrollContentMain from "~/components/shared/ScrollContentMain";
import EssentialsPageTitle from "~/components/shared/EssentialsPageTitle";
import EssentialsItem from "~/components/essentials/EssentialsItem";
import ContentElementsGroup from "~/components/content-elements/ContentElementsGroup";
import Calendar from "~/components/shared/calendar/Calendar";
import AppLayout from "~/components/shared/AppLayout";
import EditInCMSButton from "~/components/EditInCMSButton";
import ContentElementApplication from "~/components/content-elements/ContentElementApplication";
import { useGlobal } from "~/context/global";

type ApplicationLayoutProps = {
	page: typo3.Response;
};

export function useEditPageLink(page: typo3.Response) {
	return useMemo(() => {
		const url = getTypo3BackendUrl("/typo3/module/web/layout");
		url.searchParams.append("id", String(page.id));
		return url.toString();
	}, [page.id]);
}

export default function ApplicationLayout({ page }: ApplicationLayoutProps) {
	const { content, meta } = page;
	const editLink = useEditPageLink(page);
	const { isMobile } = useGlobal();
	return (
		<AppLayout layout="page">
			<EditInCMSButton link={editLink} />

			<ScrollContentMain>
				<div className="column col-main">
					<div
						className={
							"main-content" +
							(isMobile ? " jump-to-nav mobile" : "")
						}
					>
						<EssentialsPageTitle page={page} />

						<ContentElementsGroup
							elements={content.colPos0 ?? []}
							size="full"
						/>
					</div>
				</div>

				<div className="column col-aside right">
					<Calendar sectionDescription={page.meta.title} />
				</div>

				<div className="column col-aside left">
					<div className="essentials module">
						<ScrollContent className="essentials-inner">
							<EssentialsPageTitle page={page} />

							<div
								className={cn("essentials-content", {
									"no-title": meta.title.trim() === "",
								})}
							>
								{content.colPos150?.map((contentElement) => (
									<EssentialsItem
										key={contentElement.id}
										data={contentElement}
									/>
								))}
							</div>
						</ScrollContent>
					</div>
				</div>
			</ScrollContentMain>

			{content.colPos20
				?.filter(
					(
						contentElement,
					): contentElement is typo3.ContentElementApplication =>
						contentElement.type === "nnbuha22base_application",
				)
				.map((contentElement) => (
					<ContentElementApplication
						key={contentElement.id}
						data={contentElement}
					/>
				))}
		</AppLayout>
	);
}
