import ContentHead from "~/components/shared/ContentHead";
import type { PersonsPluginListProps } from "~/components/plugins/Persons";
import PersonCard from "~/components/shared/cards/PersonCard";

export function PersonsMainContentList({
	data,
	persons,
}: PersonsPluginListProps) {
	return (
		<div className="module persons-list size-full">
			<ContentHead data={data} />

			<div className="inner">
				<div className="grid-container filterTargetWrapper">
					{persons.map((person) => (
						<PersonCard person={person} key={person.uid} />
					))}
				</div>
			</div>
		</div>
	);
}
