import type { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import type * as typo3 from "~/modules/typo3/schema";
import usePageData from "~/hooks/usePageData";
import ContentHead from "~/components/shared/ContentHead";
import EssentialsOnPageNav from "~/components/shared/EssentialsOnPageNav";

export type EssentialsItemMenuProps = {
	data: typo3.ContentElementMenuSection;
};

export default function EssentialsItemMenu({ data }: EssentialsItemMenuProps) {
	const pageData = usePageData();

	const page = data.content.menu[0];
	const isCurrentPage =
		page !== undefined &&
		data.content.menu.length === 1 &&
		page.current === 1;
	if (!isCurrentPage) {
		return (
			<div className="essential-item text my-6 bg-yellow-200 p-2 font-bold text-red-900">
				{/* TODO: intl? */}
				Menu section only implemented for the current page.
			</div>
		);
	}

	const items = page.content.map(({ uid, header }) => ({
		anchor: `c${uid}`,
		label: header as ReactNode,
	}));

	if (pageData?.archive) {
		items.push({
			anchor: "archive",
			label: <FormattedMessage id="navigation.archive" />,
		});
	}

	if (pageData?.projects) {
		items.push({
			anchor: "works",
			label: <FormattedMessage id="navigation.works" />,
		});
	}

	return (
		<div className="essential-item module">
			<ContentHead mode="label" data={data} />
			<EssentialsOnPageNav items={items} />
		</div>
	);
}
