import type { ReactNode } from "react";

import Obfuscate from "~/components/shared/Obfuscate";

type EmailLinkProps = {
	emailAddress: string;
	children?: ReactNode;
};

export default function EmailLink({
	emailAddress,
	children = emailAddress,
}: EmailLinkProps) {
	return (
		<Obfuscate
			element="a"
			href={`mailto:${emailAddress.trim()}`}
			// @ts-expect-error broken prop types
			itemProp="email"
			className="email text-nowrap break-keep text-left"
		>
			{children}
		</Obfuscate>
	);
}
