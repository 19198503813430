import type * as typo3 from "~/modules/typo3/schema";
import PublicationCard from "~/components/shared/cards/PublicationCard";

import { isArticleSearchDocument } from "./SearchResultsArticle";

export type SearchResultsLehrangebotProps = {
	documents: Array<typo3.SearchResultDocument>;
};

export default function SearchResultsLehrangebot({
	documents,
}: SearchResultsLehrangebotProps) {
	return (
		<div className="lehrangebot module overview">
			<div className="inner filterTargetWrapper">
				{documents
					.filter(isArticleSearchDocument)
					.map(({ data: article }) => (
						<PublicationCard key={article.uid} article={article} />
					))}
			</div>
		</div>
	);
}
