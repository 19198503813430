import { Link } from "@remix-run/react";
import { useCallback } from "react";
import { FormattedMessage } from "react-intl";

import { useGlobal } from "~/context/global";
import { cn } from "~/utils/cn";
import useSpecialPageUrl from "~/hooks/useSpecialPageUrl";

export default function CalendarControl() {
	const calendarPageUrl = useSpecialPageUrl("calendarPage") ?? "/calendar";
	const { isMobile, isCalendarOpen, setGlobal } = useGlobal();

	const handleClick = useCallback(() => {
		setGlobal((g) => {
			g.isCalendarOpen = !g.isCalendarOpen;
		});
	}, [setGlobal]);

	return (
		<div
			className={cn(
				"element calendar-control",
				isMobile ? "mobile-button" : "in-nav",
				{
					active: isCalendarOpen,
				},
			)}
		>
			<div className="inner flex h-full content-center">
				<button className="calendar-toggle" onClick={handleClick}>
					<div className="calendar-toggle-title nav-top-link">
						<span className="title">
							<FormattedMessage id="calendar" />
						</span>

						{!isMobile && (
							<div className="button button--blank button--close">
								<span className="icon fold-state-indicator">
									<span className="inner" />
								</span>
							</div>
						)}
					</div>

					{isMobile && (
						<div className="icon-container">
							<span className="close-icon" />
						</div>
					)}
				</button>

				{!isMobile && (
					<div className="label sub calendar-link-container">
						<Link to={calendarPageUrl}>
							<span>
								<FormattedMessage id="calendar.allEvents" />
							</span>
						</Link>
					</div>
				)}
			</div>
		</div>
	);
}
