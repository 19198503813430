import type * as typo3 from "~/modules/typo3/schema";
import BodyText from "~/components/shared/BodyText";

type EssentialsItemHTMLProps = {
	data: typo3.ContentElementHTML;
};

export default function EssentialsItemHTML({ data }: EssentialsItemHTMLProps) {
	return (
		<div className="essential-item module">
			<div className="essential-item-content">
				<BodyText
					dangerouslySetInnerHTML={{ __html: data.content.bodytext }}
				/>
			</div>
		</div>
	);
}
