import type * as typo3 from "~/modules/typo3/schema";
import MediaStripe from "~/components/shared/MediaStripe";
import MediaCard from "~/components/shared/cards/MediaCard";

type MediaPluginProps = {
	data: typo3.ContentElementMedia;
};

export default function MediaPlugin({ data }: MediaPluginProps) {
	return (
		<MediaStripe title={data.content.header}>
			{data.content.data.articles.map((project) => (
				<MediaCard
					key={project.uid}
					mode="media"
					size="small"
					title={project.title}
					date={
						project.pubDate ? new Date(project.pubDate) : undefined
					}
					media={project.relatedMedia[0]}
					link={project.detailsUrl}
				/>
			))}
		</MediaStripe>
	);
}
