export type ButtonArrowProps = {
	type: "swiper-button-next" | "swiper-button-prev" | "next" | "prev";
	onClick?(): void;
};

export default function ButtonArrow({ type, onClick }: ButtonArrowProps) {
	return (
		// Do not apply the "button" class, it seems "button" is reserved only for real buttons?!
		// While this being an "arrow-button"!
		<button className={`button--arrow ${type}`} onClick={onClick}>
			<div className="svg-container">
				<svg
					viewBox="0 0 200 40"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					xmlnsXlink="http://www.w3.org/1999/xlink"
				>
					<path d="M3 39C3 39 40 25 100 2C160 25 197 39 197 39" />
				</svg>
			</div>
		</button>
	);
}
