import { Link } from "@remix-run/react";
import {
	useCallback,
	useEffect,
	useMemo,
	useState,
	type ReactNode,
} from "react";
import VimeoPlayer from "@vimeo/player";

import type * as typo3 from "~/modules/typo3/schema";
import Date from "~/components/shared/Date";
import { cn } from "~/utils/cn";
import Image from "~/components/shared/Image";
import Duration from "~/components/shared/Duration";
import { getVimeoVideoIdFromUrl } from "~/utils/vimeo";
import { spawnDetached } from "~/utils/async";
import { energySliderValueIsDark, useGlobal } from "~/context/global";

export type MediaCardSize = "full" | "small";

export type MediaCardProps = {
	/** @default "media" */
	mode?: "media";
	size: MediaCardSize;
	title?: ReactNode;
	author?: ReactNode;
	date?: Date;
	category?: ReactNode;
	duration?: number;
	area?: ReactNode;
	link?: string;
	media?: typo3.Media;
	imageUrl?: string;
};

export default function MediaCard({
	mode = "media",
	size,
	title,
	author,
	date,
	category,
	duration,
	area,
	link,
	media,
	imageUrl,
}: MediaCardProps) {
	const {
		consents: { vimeo: haveVimeoConsent },
		energySliderValue,
	} = useGlobal();
	const isDark = energySliderValueIsDark(energySliderValue);

	const [isMouseOver, setIsMouseOver] = useState(false);
	const [videoElem, setVideoElem] = useState<HTMLDivElement | null>(null);

	const vimeoId = useMemo(() => {
		if (!isDark && haveVimeoConsent && media && media.type === "video") {
			return getVimeoVideoIdFromUrl(media.properties.originalUrl);
		}
	}, [media, isDark, haveVimeoConsent]);

	const [vimeoPlayer, setVimeoPlayer] = useState<undefined | VimeoPlayer>(
		undefined,
	);
	useEffect(() => {
		if (!videoElem || vimeoId === undefined) {
			return;
		}

		const player = new VimeoPlayer(videoElem, {
			id: vimeoId,
			loop: true,
			muted: true,
			controls: false,
			background: false,
		});
		setVimeoPlayer(player);

		return () => {
			spawnDetached(async () => {
				setVimeoPlayer(undefined);
				await player.destroy();
			});
		};
	}, [vimeoId, videoElem]);

	useEffect(
		() =>
			spawnDetached(async (signal) => {
				if (!vimeoPlayer || !isMouseOver || signal.aborted) {
					return;
				}

				signal.addEventListener("abort", () =>
					spawnDetached(async () => {
						await vimeoPlayer.pause();
					}),
				);

				await vimeoPlayer.play();
			}),
		[vimeoPlayer, isMouseOver],
	);

	const handleMouseEnter = useCallback(() => setIsMouseOver(true), []);
	const handleMouseLeave = useCallback(() => setIsMouseOver(false), []);

	const content = (
		<>
			<div className="container media">
				<div className="card-img">
					{media && <Image image={media} />}
					{!media && imageUrl !== undefined && (
						<img src={imageUrl} alt="" />
					)}

					{vimeoId !== undefined && (
						<div ref={setVideoElem} className="background-video" />
					)}
				</div>
			</div>

			<div className="container info">
				<div className="grid">
					{date ? (
						<div className="pill-label card-date center">
							<Date date={date} year />
						</div>
					) : category ? (
						<div className="pill-label card-category center">
							{category}
						</div>
					) : (
						<div />
					)}

					{duration !== undefined ? (
						<div className="pill-label card-duration center">
							<span>
								<Duration duration={duration} />
							</span>
						</div>
					) : (
						<div />
					)}

					{title !== undefined && size === "full" && (
						<div className="card-title mode-invert">{title}</div>
					)}

					{author !== undefined && size === "full" && (
						<div className="pill-label card-author center">
							<span>{author}</span>
						</div>
					)}
				</div>
			</div>

			{size === "small" && (
				<div className="container title">
					<div className="card-sub label head">
						{author !== undefined && (
							<span className="author">{author}</span>
						)}
						{area !== undefined && (
							<span className="area">{area}</span>
						)}
					</div>

					{title !== undefined && (
						<div className="card-title">{title}</div>
					)}
				</div>
			)}
		</>
	);

	return (
		<div
			className={cn(`element media-card mode-${mode} size-${size}`, {
				"no-img": media === undefined && imageUrl === undefined,
			})}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			{link !== undefined ? (
				<Link to={link} className="card-inner">
					{content}
				</Link>
			) : (
				<div className="card-inner">{content}</div>
			)}
		</div>
	);
}
