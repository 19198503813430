import TeaserCard from "~/components/shared/cards/TeaserCard";
import type * as typo3 from "~/modules/typo3/schema";
import ContentElementUnknown from "~/components/content-elements/ContentElementUnknown";
import ContentHead from "~/components/shared/ContentHead";
import { FormattedDate } from "react-intl";

type ArticlesPluginProps = {
	data: typo3.ContentElementEvents;
};

export default function ContentElementGrantTeaser({
	data,
}: ArticlesPluginProps) {
	const pluginData = data.content.data;

	if (pluginData.action !== "list") {
		return (
			<ContentElementUnknown type={`${data.type}@${pluginData.action}`} />
		);
	}

	return (
		<div className="grant-teaser module">
			<div className="inner">
				<ContentHead data={data} />

				<div className="content pushy pushy-column">
					{pluginData.articles.map((article) => (
						<TeaserCard
							key={article.uid}
							mode="grant-teaser"
							title={article.title}
							href={article.detailsUrl}
							date={
								article.eventEndDate && (
									<FormattedDate
										value={article.eventEndDate}
										month="2-digit"
										day="2-digit"
										year="numeric"
									/>
								)
							}
							meta={article.articleSubtypeCategory?.title}
						/>
					))}
				</div>
			</div>
		</div>
	);
}
