import type { ReactNode } from "react";

type OverviewHeaderProps = {
	title: ReactNode;
	children: ReactNode;
};

export default function OverviewHeader({
	title,
	children,
}: OverviewHeaderProps) {
	return (
		<div className="overview-header module">
			<div className="content-head mode-page element module">
				<div className="title-wrapper">
					<h2 className="content-title">{title}</h2>
				</div>
			</div>

			{children}
		</div>
	);
}
