import { getFunctionDisplayName } from "~/utils/function";

export type ComparableValue = number | string;

/**
 * Compares two comparable values `a` and `b`. Returns -1 if `a` is less than `b`, 1 if `a`
 * is greater than `b`, and 0 if they are equal.
 * @template T The type of the comparable values to be compared. This must extend ComparableValue.
 * @param a The first value to compare.
 * @param b The second value to compare.
 * @returns -1, 0 or 1 based on whether `a` is less than, equal to, or greater than `b`.
 */
export function defaultCmp<T extends ComparableValue>(a: T, b: T): -1 | 0 | 1 {
	if (a < b) {
		return -1;
	} else if (a > b) {
		return 1;
	} else {
		return 0;
	}
}

export function insensitiveCaseCmp(a: string, b: string): number {
	return a.localeCompare(b, undefined, { sensitivity: "accent" });
}

/**
 * Creates a comparison function that compares objects based on a specified key or property.
 * This function takes in another function `f`, which extracts a comparable value from an object of type T.
 * The resulting comparison function can then be used to compare two objects of type T by their extracted values.
 * @template T The type of the objects to be compared.
 * @template U The type of the comparable value extracted from the objects. This must extend ComparableValue.
 * @param map A function that extracts a comparable value from an object of type T.
 * @returns A comparison function that takes two objects of type T and returns -1, 0, or 1 based on their extracted values.
 */
export function cmpByKey<T, U extends ComparableValue>(
	getKey: (val: T) => U,
	cmp: (a: U, b: U) => number = defaultCmp,
): (a: T, b: T) => number {
	function cmpByKeyInner(a: T, b: T): number {
		return cmp(getKey(a), getKey(b));
	}
	cmpByKeyInner.displayName = `cmpByKeyInner_${getFunctionDisplayName(getKey)}_${getFunctionDisplayName(cmp)}`;
	return cmpByKeyInner;
}
