import { type CSSProperties, useMemo, useState } from "react";
import { Link } from "@remix-run/react";

import type * as typo3 from "~/modules/typo3/schema";
import { findFirstMediaByType } from "~/modules/typo3/media";
import ContentHead from "~/components/shared/ContentHead";
import HoverMarquee from "~/components/shared/HoverMarquee";
import Image, {
	getImageSourceRatio,
	getMainImageSource,
} from "~/components/shared/Image";
import Swiper from "~/components/shared/Swiper";
import { chunkArray } from "~/utils/array";
import { isNonNullable } from "~/utils/nonNullable";
import { useGlobal } from "~/context/global";
import ContentElementUnknown from "~/components/content-elements/ContentElementUnknown";
import { type ContentElementSize } from "~/components/content-elements/ContentElement";

const MAX_PUBLICATIONS_PER_SLIDE = 4;

export type PublicationsPluginProps = {
	data: typo3.ContentElementPublications;
	size: ContentElementSize;
};

export default function PublicationsPlugin({
	data,
	size,
}: PublicationsPluginProps) {
	const pluginData = data.content.data;

	if (pluginData.action !== "list") {
		return (
			<ContentElementUnknown type={`${data.type}@${pluginData.action}`} />
		);
	}

	return <PublicationsList size={size} data={data} pluginData={pluginData} />;
}

function PublicationsList({
	data,
	pluginData,
	size,
}: PublicationsPluginProps & {
	pluginData: typo3.ContentElementPublications["content"]["data"] & {
		action: "list";
	};
}) {
	const slides = useMemo(() => {
		const pubs = pluginData.articles
			.map((publication) => {
				const image = findFirstMediaByType(
					publication.relatedMedia,
					"image",
				);
				if (image === undefined) {
					return undefined;
				}
				return { image, publication };
			})
			.filter(isNonNullable);

		if (size === "half") {
			return pubs.map((pub) => [pub]);
		}

		const first = pubs[0];
		const chunks = chunkArray(MAX_PUBLICATIONS_PER_SLIDE, pubs.slice(1));
		if (first) {
			chunks.unshift([first]);
		}
		return chunks;
	}, [pluginData.articles, size]);

	return (
		<div className="publications module">
			<div className="inner">
				<ContentHead data={data} />

				<div className="stage">
					<Swiper swiperClassname="swiper-gallery">
						{slides.map((slide, slideIndex) => (
							<div
								key={slideIndex}
								className={`inner grid-${slide.length}`}
							>
								{slide.map((publication) => (
									<Publication
										key={publication.publication.uid}
										{...publication}
										numberOfPublicationsOnSlide={
											slide.length
										}
									/>
								))}
							</div>
						))}
					</Swiper>
				</div>
			</div>
		</div>
	);
}

type PublicationProps = {
	publication: typo3.PublicationCard;
	image: typo3.Media;
	numberOfPublicationsOnSlide: number;
};

function Publication({
	publication,
	image,
	// numberOfPublicationsOnSlide,
}: PublicationProps) {
	const { isMobile } = useGlobal();
	const [linkElem, setLinkElem] = useState<null | HTMLAnchorElement>(null);

	const ratio = getImageSourceRatio(
		getMainImageSource(image.imageSources.main),
	);

	const bookStyle = useMemo((): undefined | CSSProperties => {
		if (isMobile) {
			// const longSide = numberOfPublicationsOnSlide === 4 ? 42 : 100;
			const longSide = 42;

			if (ratio > 1) {
				return {
					width: `${longSide}vw`,
					height: `${longSide / ratio}vw`,
					maxWidth: "none",
					maxHeight: "none",
				};
			} else {
				return {
					width: `${longSide * ratio}vw`,
					height: `${longSide}vw`,
					maxWidth: "none",
					maxHeight: "none",
				};
			}
		}
	}, [isMobile, ratio]);

	return (
		<Link
			to={publication.detailsUrl ?? "#"}
			className="publication"
			ref={setLinkElem}
		>
			<div className="pub-main-container">
				<div className="booktilt-container">
					<div
						// don't use `getImageFormat(publication)` here, this has its own
						// definition of format, it's missing the "square" variant
						className={`book ${
							ratio <= 1 ? "portrait" : "landscape"
						}`}
						style={{
							...bookStyle,
							aspectRatio: ratio,
						}}
					>
						<div className="spine" style={{ width: "8%" }}>
							<Image image={image} />
						</div>
						<div className="cover">
							<Image image={image} />
						</div>
					</div>
				</div>
			</div>

			<div className="pub-title-container">
				<HoverMarquee
					containerClassName="title"
					hoverElem={linkElem ?? undefined}
					dangerouslySetInnerHTML={{
						__html: publication.title,
					}}
				/>

				<HoverMarquee
					containerClassName="caption"
					hoverElem={linkElem ?? undefined}
					dangerouslySetInnerHTML={{
						__html: publication.teasertext,
					}}
				/>
			</div>
		</Link>
	);
}
