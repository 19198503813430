import { useCallback } from "react";
import { useIntl } from "react-intl";

import BrightSun from "~/components/icons/BrightSun";
import DarkSun from "~/components/icons/DarkSun";
import { useGlobal } from "~/context/global";
//import usePrefersDarkColorScheme from "~/hooks/usePrefersDarkColorScheme";

export default function EnergySlider() {
	const intl = useIntl();
	const { energySliderValue, setGlobal } = useGlobal();
	//const isPrefersDark = usePrefersDarkColorScheme();
	//useEffect(
	//	() => submitEnergySliderValue(isPrefersDark ? 0 : 100),
	//	[isPrefersDark, submitEnergySliderValue],
	//);

	const handleMinClick = useCallback(
		() =>
			setGlobal((g) => {
				g.energySliderValue = 0;
			}),
		[setGlobal],
	);
	const handleMaxClick = useCallback(
		() =>
			setGlobal((g) => {
				g.energySliderValue = 100;
			}),
		[setGlobal],
	);

	const handleChange = useCallback(
		(ev: React.ChangeEvent<HTMLInputElement>) => {
			const newVal = Number(ev.currentTarget.value);
			setGlobal((g) => {
				g.energySliderValue = newVal;
			});
		},
		[setGlobal],
	);

	return (
		<div className="slidecontainer energy-slider" aria-hidden>
			<button
				type="button"
				className="darksun"
				aria-label={intl.formatMessage({
					id: "energySlider.energySaverMode",
				})}
				title={intl.formatMessage({
					id: "energySlider.energySaverMode",
				})}
				onClick={handleMinClick}
			>
				<DarkSun />
			</button>
			<input
				type="range"
				min="0"
				max="100"
				step="0.1"
				value={energySliderValue}
				className="slider"
				onChange={handleChange}
			/>
			<button
				type="button"
				className="brightsun"
				aria-label={intl.formatMessage({
					id: "energySlider.animationMode",
				})}
				title={intl.formatMessage({
					id: "energySlider.animationMode",
				})}
				onClick={handleMaxClick}
			>
				<BrightSun />
			</button>
		</div>
	);
}
