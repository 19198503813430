import { type ContentElementSize } from "~/components/content-elements/ContentElement";
import ContentHead from "~/components/shared/ContentHead";
import Video from "~/components/shared/Video";
import type * as typo3 from "~/modules/typo3/schema";

export type ContentElementVideoProps = {
	data: typo3.ContentElementVideo;
	size: ContentElementSize;
};

export default function ContentElementVideo({
	data,
	size,
}: ContentElementVideoProps) {
	return (
		<div className={`module video size-${size}`}>
			<ContentHead data={data} />

			{data.content.video.map((video, videoIndex) => (
				<figure key={videoIndex} className="!my-2">
					<Video video={video} />

					{video.properties.description && (
						<figcaption>{video.properties.description}</figcaption>
					)}
				</figure>
			))}
		</div>
	);
}
