import type * as typo3 from "~/modules/typo3/schema";
import ContentElement from "~/components/content-elements/ContentElement";
import ContentElementUnsupported from "~/components/content-elements/ContentElementUnsupported";

export type ContentElementProps = {
	data: typo3.ContentElementShortcut;
};

export default function ContentElementShortcut({ data }: ContentElementProps) {
	return data.content.shortcut.map((shortcut) => {
		const contentElement = shortcut as typo3.AnyContentElement;

		switch (contentElement.type) {
			case "text":
			case "header":
			case "table":
			case "menu_sitemap":
			case "menu_sitemap_pages":
			case "menu_pages":
			case "menu_subpages":
				// Render the ContentElement for supported types
				return (
					<ContentElement
						key={contentElement.id}
						data={contentElement}
						size="full"
					/>
				);
			default:
				return <ContentElementUnsupported type={contentElement.type} />;
		}
	});
}
