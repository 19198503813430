import { getFunctionDisplayName } from "~/utils/function";

export function isNonNullable<T>(val: null | undefined | void | T): val is T {
	return val !== null && val !== undefined;
}

export { isNonNullable as is };

export function assertNonNullable<T>(
	value: null | undefined | void | T,
	msg: string = "NonNullable assertion failure on value",
): asserts value is T {
	if (value === null || value === undefined) {
		throw new Error(msg);
	}
}

export { assertNonNullable as assert };

export function ensureNonNullable<T>(
	value: null | undefined | void | T,
	msg: string = "NonNullable assertion failure on value",
): T {
	if (value === null || value === undefined) {
		throw new Error(msg);
	}
	return value;
}

export { ensureNonNullable as ensure };

export function createUnwrapOr<U>(
	or: U,
): <T>(val: T | null | undefined | void) => T | U {
	function unwrapOr<T>(val: T | null | undefined | void): T | U {
		if (val !== null && val !== undefined) {
			return val;
		}
		return or;
	}
	return unwrapOr;
}

export function mapNonNullable<T, U>(
	mapFn: (value: T) => U,
): (value: T | null | undefined | void) => U | undefined {
	const mapNonNullableInner = (
		value: T | null | undefined | void,
	): U | undefined => {
		if (value === null || value === undefined) {
			return undefined;
		}
		return mapFn(value);
	};

	mapNonNullableInner.displayName = `mapNonNullable_${getFunctionDisplayName(
		mapFn,
	)}`;
	return mapNonNullableInner;
}

export { mapNonNullable as map };

/**
 * Same as `mapNonNullable`, but catches exceptions from the map function and returns `undefined`
 * in that case.
 *
 * @see {mapNonNullable}
 */
export function tryMapNonNullable<T, U>(
	mapFn: (value: T) => U,
): (value: T | null | undefined | void) => U | undefined {
	const tryMapNonNullableInner = (
		value: T | null | undefined | void,
	): U | undefined => {
		if (value === null || value === undefined) {
			return undefined;
		}

		try {
			return mapFn(value);
		} catch (_) {
			return undefined;
		}
	};

	tryMapNonNullableInner.displayName = `tryMapNonNullable_${getFunctionDisplayName(
		mapFn,
	)}`;
	return tryMapNonNullableInner;
}

export { tryMapNonNullable as tryMap };

export function filterNonNullable<T, U extends T>(
	filterFn: (value: T) => value is U,
): (value: T | null | undefined | void) => U | undefined;

export function filterNonNullable<T>(
	filterFn: (value: T) => boolean,
): (value: T | null | undefined | void) => T | undefined {
	const filterNonNullableInner = (
		value: T | null | undefined | void,
	): T | undefined => {
		if (value !== null && value !== undefined && filterFn(value)) {
			return value;
		}
		return undefined;
	};

	filterNonNullableInner.displayName = `filterNonNullable_${getFunctionDisplayName(
		filterFn,
	)}`;
	return filterNonNullableInner;
}

export { filterNonNullable as filter };
