import {
	type ReactNode,
	Children,
	useRef,
	useCallback,
	useState,
	useMemo,
} from "react";
import * as swiper from "swiper/react";
import * as swiperMods from "swiper/modules";
import type { PaginationOptions } from "swiper/types";

import ButtonArrow from "~/components/icons/ButtonArrow";

export { PaginationOptions as SwiperPaginationOptions };

export interface SwiperProps
	extends Pick<
		swiper.SwiperProps,
		"slidesPerView" | "initialSlide" | "spaceBetween" | "centeredSlides"
	> {
	pagination?: PaginationOptions;
	swiperClassname?: string;
	slideClassname?: string;
	paginationPosition?: "start" | "end";
	children: ReactNode;
}

const defaultSwiperPagination = {
	type: "fraction",
	renderFraction: (currentClass, totalClass) =>
		`<span class="${currentClass}"></span>/<span class="${totalClass}"></span>`,
} as const satisfies PaginationOptions;

export default function Swiper({
	children,
	swiperClassname,
	slideClassname,
	paginationPosition = "start",
	pagination = defaultSwiperPagination,
	initialSlide,
	slidesPerView,
	centeredSlides = false,
	spaceBetween = 100,
}: SwiperProps) {
	const swiperRef = useRef<swiper.SwiperRef>(null);
	const [paginationTarget, setPaginationTarget] =
		useState<HTMLDivElement | null>(null);

	const handlePrev = useCallback(
		() => swiperRef.current?.swiper.slidePrev(),
		[],
	);

	const handleNext = useCallback(
		() => swiperRef.current?.swiper.slideNext(),
		[],
	);

	const paginationOptions = useMemo<PaginationOptions>(
		() => ({
			...pagination,
			enabled: paginationTarget !== null,
			el: paginationTarget,
		}),
		[paginationTarget, pagination],
	);

	const paginationNode = (
		<div
			className="swiper-pagination"
			ref={(el) => setPaginationTarget(el)}
		/>
	);

	const numberOfSlides = Children.count(children);
	const showArrows = numberOfSlides > 1;
	const showPagination = numberOfSlides > 1 && pagination !== null;

	return (
		<div className="swiper-gallery-container">
			{showArrows && (
				<ButtonArrow type="swiper-button-next" onClick={handleNext} />
			)}
			{showArrows && (
				<ButtonArrow type="swiper-button-prev" onClick={handlePrev} />
			)}
			{showPagination && paginationPosition === "start" && paginationNode}

			<swiper.Swiper
				ref={swiperRef}
				direction="horizontal"
				/** Can't be enabled together with centeredSlides */
				loop={!centeredSlides}
				speed={400}
				spaceBetween={spaceBetween}
				slidesPerView={slidesPerView}
				className={swiperClassname}
				initialSlide={initialSlide}
				modules={[swiperMods.Pagination]}
				pagination={paginationOptions}
				centeredSlides={centeredSlides}
				autoHeight={true}
			>
				{Children.map(children, (slide) => (
					<swiper.SwiperSlide className={slideClassname}>
						{slide}
					</swiper.SwiperSlide>
				))}
			</swiper.Swiper>

			{showPagination && paginationPosition === "end" && paginationNode}
		</div>
	);
}
