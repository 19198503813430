import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import { Link } from "@remix-run/react";

export type ClickFilterOptions = {
	id: string;
	label: string;
};

export type ClickFilterProps = {
	resetUri?: string;
	onResetClick?(): void;
	activeOption: null | string;
	setActiveOption(id: null | string): void;
	options: Array<ClickFilterOptions>;
};

export default function ClickFilter({
	resetUri,
	onResetClick,
	options,
	activeOption,
	setActiveOption,
}: ClickFilterProps) {
	if (options.length > 0) {
		return (
			<div className="click-filter">
				<ul>
					{resetUri !== undefined && (
						<li>
							<Link to={resetUri} preventScrollReset>
								<FormattedMessage id="all" />
							</Link>
						</li>
					)}

					{onResetClick !== undefined && resetUri === undefined && (
						<li>
							<button type="button" onClick={onResetClick}>
								<FormattedMessage id="all" />
							</button>
						</li>
					)}

					{/* TODO: Filter Ausgewählte */}

					{options.map(({ id, label }) => (
						<li
							key={id}
							className={clsx("dropdown-trigger", {
								active: activeOption === id,
							})}
						>
							<button
								type="button"
								onClick={() =>
									setActiveOption(
										activeOption === id ? null : id,
									)
								}
							>
								{label}
							</button>
						</li>
					))}
				</ul>
			</div>
		);
	}
}
