import { Link } from "@remix-run/react";
import { useState } from "react";
import { motion } from "framer-motion";
import { FormattedMessage, useIntl } from "react-intl";

import Search from "~/components/icons/Search";
import type * as typo3 from "~/modules/typo3/schema";
import { FEATURE_SHOW_RANGE_DATE_FACET } from "~/components/plugins/solr/SearchHead";
import { buildRangeDateSetUrlForSingleDate } from "~/utils/solr";
import Options, { type RenderOptionReturn } from "~/components/shared/Options";

type SearchFacetMultiProps = {
	facet: typo3.ContentElementSolr["content"]["data"]["facets"][0];
};

export default function SearchFacetMulti({ facet }: SearchFacetMultiProps) {
	const intl = useIntl();

	const [optionFilter, setOptionFilter] = useState("");

	if (FEATURE_SHOW_RANGE_DATE_FACET && facet.type === "rangeDate") {
		const url = buildRangeDateSetUrlForSingleDate(
			facet.uris.set,
			new Date(),
		);

		return (
			<motion.div
				initial={{ opacity: 0, height: 0, y: -20 }}
				animate={{ opacity: 1, height: "auto", y: 0 }}
				exit={{ opacity: 0, height: 0, y: -20 }}
				className="element dropdown single-filter-wrapper"
				style={{
					display: "block",
				}}
			>
				<div className="tile">
					<div className="inner-container">
						<div className="inner-content">
							<ul>
								<li className="option">
									<Link
										to={url}
										className="pill-label center"
										preventScrollReset={true}
									>
										Heute
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</motion.div>
		);
	}

	if (facet.type !== "options") {
		return null;
	}

	const optionFilterLowCase = optionFilter.toLocaleLowerCase();

	return (
		<motion.div
			variants={{
				initial: { opacity: 0, height: 0, y: -20 },
				animate: { opacity: 1, height: "auto", y: 0 },
				exit: { opacity: 0, height: 0, y: -20 },
			}}
			initial="initial"
			animate="animate"
			exit="exit"
			className="element dropdown single-filter-wrapper"
			style={{
				display: "block",
			}}
			transition={{
				duration: 0.3,
				delayChildren: 0.1,
				staggerChildren: 0.1,
				staggerDirection: 1,
			}}
		>
			<div className="tile">
				{10 < facet.options.length && (
					<div className="element search-bar">
						<div className="content">
							<form
								className="large"
								onSubmit={(ev) => ev.preventDefault()}
							>
								{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
								<label className="id">
									<span className="title">
										<FormattedMessage id="search" />
									</span>
									<input
										type="text"
										inputMode="text"
										autoComplete="on"
										placeholder={intl.formatMessage({
											id: "search",
										})}
										value={optionFilter}
										onChange={(ev) =>
											setOptionFilter(
												ev.currentTarget.value,
											)
										}
									/>

									<button
										type="submit"
										className="search-submit"
									>
										<Search
											label={intl.formatMessage({
												id: "search",
											})}
										/>
									</button>
								</label>
							</form>
						</div>
					</div>
				)}

				<div className="inner-container">
					<div className="inner-content">
						<Options
							options={facet.options.filter((option) =>
								option.label
									.toLocaleLowerCase()
									.includes(optionFilterLowCase),
							)}
							renderOption={renderOption}
							staggerAnimation
						/>
					</div>
				</div>
			</div>
		</motion.div>
	);
}

type Option = typo3.SolrOptionsFacet["options"][number];

function renderOption(option: Option): RenderOptionReturn {
	return {
		key: option.value,
		isActive: option.active,
		content: (
			<Link
				to={option.active ? option.uris.unset : option.uris.set}
				className="pill-label center"
				preventScrollReset={true}
			>
				<span>
					{option.label} ({option.count})
				</span>
			</Link>
		),
	};
}
