import { FormattedMessage } from "react-intl";

import type * as typo3 from "~/modules/typo3/schema";
import { isProjectType } from "~/modules/typo3/schema";
import TextList from "~/components/shared/TextList";

type ProjectTypeProps = Pick<
	typo3.ProjectCard,
	"isGroupProject" | "students" | "projectType"
>;

export function renderProjectType({
	students,
	isGroupProject,
	projectType,
}: ProjectTypeProps) {
	if (students) {
		return <TextList text={students} />;
	}

	if (isGroupProject) {
		return <FormattedMessage id="projectType.groupProject" />;
	}

	if (projectType === "0") {
		return null;
	}

	if (isProjectType(projectType)) {
		return <FormattedMessage id={`projectType.${projectType}`} />;
	}

	return <FormattedMessage id="projectType.default" />;
}

export default function ProjectType(props: ProjectTypeProps) {
	return renderProjectType(props);
}
