import { Suspense } from "react";
import { Await } from "@remix-run/react";
import { FormattedMessage } from "react-intl";

import { useEventsData } from "~/hooks/usePageData";
import type * as typo3 from "~/modules/typo3/schema";
import EventCard from "~/components/shared/cards/EventCard";
import CalendarWrapper from "~/components/shared/calendar/CalendarWrapper";
import CalendarUnit from "~/components/shared/calendar/CalendarUnit";
import SearchMarker from "~/components/SearchMarker";

type CalendarProps = {
	isOverview?: boolean;
	sectionDescription?: string;
};

function CalendarContent({
	data,
	sectionDescription,
	isOverview,
}: CalendarProps & {
	data: Awaited<ReturnType<typeof useEventsData>>;
}) {
	if (!data) return null;

	return (
		<>
			{data.inSection.length > 0 && (
				<CalendarUnit
					className="filter-hint pinned"
					title={
						<FormattedMessage
							id="calendar.inSection"
							values={{
								section: sectionDescription,
								count: data.inSection.length,
							}}
						/>
					}
				>
					{data.inSection.map((article) => (
						<EventCard
							key={article.uid}
							article={article as typo3.ArticleCard}
							isHighlight
						/>
					))}
				</CalendarUnit>
			)}

			{data.today.length > 0 && (
				<CalendarUnit
					className="today"
					title={
						<FormattedMessage
							id="calendar.today"
							values={{
								count: data.today.length,
							}}
						/>
					}
				>
					{data.today.map((article) => (
						<EventCard
							key={article.uid}
							article={article as typo3.ArticleCard}
							isOverview={isOverview}
						/>
					))}
				</CalendarUnit>
			)}

			{data.highlights.length > 0 && (
				<CalendarUnit
					className="highlights"
					title={
						<FormattedMessage
							id="calendar.highlightsInNearFuture"
							values={{
								count: data.highlights.length,
							}}
						/>
					}
				>
					{data.highlights.map((article) => (
						<EventCard
							key={article.uid}
							article={article as typo3.ArticleCard}
							isOverview={isOverview}
							isHighlight
						/>
					))}
				</CalendarUnit>
			)}

			{data.current.length > 0 && (
				<CalendarUnit
					title={
						<FormattedMessage
							id="calendar.ongoing"
							values={{
								count: data.current.length,
							}}
						/>
					}
				>
					{data.current.map((article) => (
						<EventCard
							key={article.uid}
							article={article as typo3.ArticleCard}
							isOverview={isOverview}
						/>
					))}
				</CalendarUnit>
			)}

			{data.upcoming.length > 0 && (
				<CalendarUnit
					title={
						<FormattedMessage
							id="calendar.upcoming"
							values={{
								count: data.upcoming.length,
							}}
						/>
					}
				>
					{data.upcoming.map((article) => (
						<EventCard
							key={article.uid}
							article={article as typo3.ArticleCard}
							isOverview={isOverview}
						/>
					))}
				</CalendarUnit>
			)}
		</>
	);
}

export function Calendar({
	data,
	isOverview = false,
	sectionDescription = "",
}: CalendarProps & {
	data: typo3.CalendarData;
}) {
	return (
		<CalendarWrapper isOverview={isOverview}>
			<SearchMarker inverted>
				<CalendarContent
					data={data}
					sectionDescription={sectionDescription}
					isOverview={isOverview}
				/>
			</SearchMarker>
		</CalendarWrapper>
	);
}

export default function PageEventsCalendar({
	isOverview = false,
	sectionDescription = "",
}: CalendarProps) {
	const dataPromise = useEventsData();

	return (
		<CalendarWrapper isOverview={isOverview}>
			<SearchMarker inverted>
				<Suspense fallback={<p>Loading events</p>}>
					<Await resolve={dataPromise}>
						{(data) => (
							<CalendarContent
								data={data}
								sectionDescription={sectionDescription}
								isOverview={isOverview}
							/>
						)}
					</Await>
				</Suspense>
			</SearchMarker>
		</CalendarWrapper>
	);
}
