import VideoCard from "~/components/shared/cards/VideoCard";
import type * as typo3 from "~/modules/typo3/schema";
import { cn } from "~/utils/cn";
import * as nonNull from "~/utils/nonNullable";

export type VideoGroupProps = {
	articles: Array<typo3.ArticleCard>;
	layout?: "quartet";
};

export default function VideoGroup({ articles, layout }: VideoGroupProps) {
	return (
		<div className="module video-group">
			<div className="inner">
				<div
					className={cn(
						"content",
						nonNull.map((l) => `layout-${l}`)(layout),
					)}
				>
					{articles.map((article) => (
						<VideoCard
							key={article.uid}
							size="full"
							article={article}
						/>
					))}
				</div>
			</div>
		</div>
	);
}
