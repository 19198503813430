import type * as typo3 from "~/modules/typo3/schema";

import ContentHead from "../shared/ContentHead";

type EssentialsItemHeaderProps = {
	data: typo3.ContentElementHeader;
};

export default function EssentialsItemHeader({
	data,
}: EssentialsItemHeaderProps) {
	return (
		<div className="essential-item module">
			<ContentHead mode="label" data={data} />
		</div>
	);
}
