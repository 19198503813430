import { Link } from "@remix-run/react";

import type * as typo3 from "~/modules/typo3/schema";
import ContentHead from "~/components/shared/ContentHead";
import ContentElementUnknown from "~/components/content-elements/ContentElementUnknown";
import News from "~/components/News";
import Image from "~/components/shared/Image";
import usePageData from "~/hooks/usePageData";

type ArticlesPluginProps = {
	data: typo3.ContentElementArticles;
};

export default function ArticlesPlugin({ data }: ArticlesPluginProps) {
	const pageData = usePageData();
	const pluginData = data.content.data;

	if (pluginData.action !== "list") {
		return (
			<ContentElementUnknown type={`${data.type}@${pluginData.action}`} />
		);
	}

	// In 3-col layout with different layout:
	if (data.colPos > 200 && data.colPos < 300) {
		const article = pluginData.articles[0];
		const image = article?.relatedMedia[0];

		return (
			<div className="module burg-galerie-teaser">
				<div className="inner">
					<ContentHead data={data} />

					{article && (
						<div className="content">
							<Link to={article.detailsUrl ?? "#"}>
								<div className="poster">
									{image && <Image image={image} />}
								</div>

								<div className="pub-title-container">
									<div className="title">
										<span>{article.title}</span>
									</div>
									<div className="caption whitespace-pre-line">
										<p>{article.teasertext}</p>
									</div>
								</div>
							</Link>
						</div>
					)}
				</div>
			</div>
		);
	}

	if (data.type === "nnbuhaarticlesandpersons_articlestable") {
		return (
			<div className="news module layout-table">
				<ContentHead data={data} />
				<News layout="table" articles={pluginData.articles} />
			</div>
		);
	}

	return (
		<section className="news module layout-rows">
			<ContentHead data={data} />
			<News
				layout="rows"
				cols={pageData?.appearance.backendLayout === "home" ? 10 : 3}
				articles={pluginData.articles}
			/>
		</section>
	);
}
