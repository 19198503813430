import { NavLink } from "@remix-run/react";
import { FormattedMessage } from "react-intl";

import type * as typo3 from "~/modules/typo3/schema";
import { cn } from "~/utils/cn";
import { PersonMenuThumb } from "~/components/shared/PersonMenuThumb";

export default function RelatedGueastTeachersEssentials({
	article,
}: {
	article: typo3.Article;
}) {
	const guestTeacher =
		article.articleType === "lehrangebot" ? article.courseGuests : "";

	return (
		<div className="essential-item essential-foldout-section module">
			<div className="content-head element group/heading mode-label">
				<div className="title-wrapper">
					<h2 className="content-title">
						<FormattedMessage id="relatedPersonsTitle.teachers" />
					</h2>
				</div>
			</div>
			<div className="link-list">
				<ul className="contents">
					{guestTeacher && <li>{guestTeacher}</li>}
				</ul>
			</div>
		</div>
	);
}
