import {
	type MetaFunction,
	type HeadersFunction,
	type LoaderFunctionArgs,
	type ActionFunctionArgs,
	json,
	defer,
} from "@remix-run/node";
import {
	isRouteErrorResponse,
	useLoaderData,
	useRouteError,
} from "@remix-run/react";

import type * as typo3 from "~/modules/typo3/schema";
import ErrorLayout from "~/components/layouts/ErrorLayout";
import { renderLayoutComponent } from "~/modules/typo3/backend-layouts";
import { mergeHeaders } from "~/utils/headers";
import { findSinglePlugin } from "~/components/layouts/PageLayout";
import { getAllChildContentElementsForResponse } from "~/utils/typo3";
import { getVimeoVideoIdFromUrl } from "~/utils/vimeo";
import { isNonNullable } from "~/utils/nonNullable";
import { makeVimeoVideoDataQuery, queryClient } from "~/data";
import {
	buildTypo3Url,
	fetchHeadlessJSON,
	getTypo3ApiUrl,
} from "~/modules/typo3/fetch";
import { calendarDataSchema, responseSchema } from "~/modules/typo3/schema";

async function fetchEventsData(path: string, request: Request) {
	const eventsUrl = buildTypo3Url(path, request);
	eventsUrl.searchParams.set("type", "6833");

	try {
		const response = await fetchHeadlessJSON(eventsUrl, {
			headers: request.headers,
		});
		if (response instanceof Response) {
			return null;
		}
		return (await calendarDataSchema.safeParseAsync(response.data)).data;
	} catch (error) {
		console.error("Failed to fetch events data", error);
		return null;
	}
}

export async function loader({ request, params }: LoaderFunctionArgs) {
	const start = Date.now();
	const path = params["*"] ?? "/";
	const eventsData = fetchEventsData(path, request);

	const response = await fetchHeadlessJSON(buildTypo3Url(path, request), {
		headers: request.headers,
	});
	if (response instanceof Response) {
		throw response;
	}

	const validated = await responseSchema.safeParseAsync(response.data);
	if (!validated.success) {
		console.error(
			"Schema error in TYPO3 response (Loader)",
			validated.error,
		);
		throw new Response("Internal error (Schema)", {
			status: 500,
		});
	}

	const vimeoVideoData = fetchVimeoVideoData(validated.data);

	const st = response.headers["server-timing"];

	return defer(
		{
			pageData: validated.data,
			eventsData,
			vimeoVideoData,
		},
		{
			headers: {
				...response.headers,
				"server-timing": `${st ? `${st}, ` : ""}pageloader;dur=${Date.now() - start}`,
				"x-page-loader-duration": `${Date.now() - start}`,
			},
		},
	);
}

function extractVideoIdsFromMedias(medias: Array<typo3.Media>) {
	return medias
		.map((media) =>
			media.type === "video"
				? getVimeoVideoIdFromUrl(media.properties.originalUrl)
				: undefined,
		)
		.filter(isNonNullable);
}

function extractVideoIdsFromContent(res: typo3.Response) {
	const videoIds = getAllChildContentElementsForResponse(res)
		.flatMap((ce) => {
			switch (ce.type) {
				case "video":
					return extractVideoIdsFromMedias(ce.content.video);

				case "nnbuhaarticlesandpersons_mediaquartet":
				case "nnbuhaarticlesandpersons_media":
				case "nnbuhaarticlesandpersons_podcaststripe":
					return ce.content.data.articles.flatMap(
						({ relatedMedia }) =>
							extractVideoIdsFromMedias(relatedMedia),
					);
			}

			if (
				"content" in ce &&
				"relatedMedia" in ce.content &&
				Array.isArray(ce.content.relatedMedia)
			) {
				return extractVideoIdsFromMedias(ce.content.relatedMedia);
			}
		})
		.filter(isNonNullable);
	return new Set(videoIds);
}

async function fetchVimeoVideoData(res: typo3.Response) {
	const videoIds = extractVideoIdsFromContent(res);
	const queries = Array.from(videoIds.values()).map(async (id) => {
		try {
			const data = await queryClient.fetchQuery(
				makeVimeoVideoDataQuery(id),
			);
			return [id, data] as const;
		} catch (err) {
			console.warn(`Unable to fetch data for vimeo video "${id}"`, err);
			return null;
		}
	});
	const dataEntries = await Promise.all(queries);
	return Object.fromEntries(dataEntries.filter(isNonNullable));
}

export const headers: HeadersFunction = ({ loaderHeaders }) =>
	mergeHeaders(
		{},
		{
			"cache-control": loaderHeaders.get("cache-control"),
			"x-typo3-parsetime": loaderHeaders.get("x-typo3-parsetime"),
			"server-timing": loaderHeaders.get("server-timing"),
		},
	);

export async function action({ request }: ActionFunctionArgs) {
	const url = new URL(request.url);

	const response = await fetchHeadlessJSON(
		getTypo3ApiUrl(url.pathname + url.search),
		{
			headers: request.headers,
			method: request.method,
			body: await request.formData(),
		},
	);
	if (response instanceof Response) {
		throw response;
	}

	//const validated = await responseSchema.safeParseAsync(data);
	//if (!validated.success) {
	//	console.error(
	//		"Schema error in TYPO3 response (Action)",
	//		validated.error,
	//	);
	//	throw new Response("Internal Error (Schema)", {
	//		status: 500,
	//	});
	//}

	return json(response.data, {
		headers: mergeHeaders(response.headers, {
			"cache-control": "no-store, max-age=0",
		}),
	});
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
	if (!data) return [];

	const { meta } = data.pageData;

	const singlePlugin = findSinglePlugin({ page: data.pageData });

	const pageTitle =
		data.pageData.id === 1
			? "Burg Giebichenstein Kunsthochschule Halle"
			: (singlePlugin?.meta.title ?? meta.title) +
				" - Burg Giebichenstein Kunsthochschule Halle";

	return [
		{ title: pageTitle },
		{ description: singlePlugin?.meta.description ?? meta.description },
		{ keywords: singlePlugin?.meta.keywords ?? meta.keywords },
		{
			robots: [
				meta.robots.noIndex ? "noindex" : "index",
				meta.robots.noFollow ? "nofollow" : "follow",
			].join(", "),
		},

		{ "og:title": singlePlugin?.meta.title ?? meta.ogTitle },
		{
			"og:description":
				singlePlugin?.meta.description ?? meta.ogDescription,
		},

		{ "twitter:card": meta.twitterCard },
		{ "twitter:title": singlePlugin?.meta.title ?? meta.twitterTitle },
		{
			"twitter:description":
				singlePlugin?.meta.description ?? meta.twitterDescription,
		},
		/*
		 * TODO:
		 * 	canonical: z.string(), //typo3LinkSchema,
		 * 	author: z.string(),
		 * 	authorEmail: z.string(),
		 * 	twitterImage: z.object({}).nullable(),
		 * 	schema: z.string().optional(),
		 * { "og:image": data.meta.ogImage },  // z.object({}).nullable(), // TODO shape
		 */
	];
};

export function ErrorBoundary() {
	const error = useRouteError();

	// when true, this is what used to go to `CatchBoundary`
	if (isRouteErrorResponse(error)) {
		return (
			<ErrorLayout title="Oops">
				<p>Status: {error.status}</p>
				<p>{error.data.message}</p>

				{process.env.NODE_ENV === "development" && (
					<pre className="mt-4">DEV Error: {error.data}</pre>
				)}
			</ErrorLayout>
		);
	}

	return (
		<ErrorLayout title="Uh oh ...">
			<p>Something went wrong.</p>
			<pre>Unknown Error</pre>

			{process.env.NODE_ENV === "development" && (
				<pre className="mt-4">
					DEV Error: {JSON.stringify(error, null, 2)}
				</pre>
			)}
		</ErrorLayout>
	);
}

export default function Catchall() {
	const data = useLoaderData<typeof loader>();
	return renderLayoutComponent(data.pageData);
}
