import { useEffect, useReducer, useRef, useState } from "react";
import useMeasure from "react-use-measure";
import { AnimatePresence, motion } from "framer-motion";
import { mergeRefs } from "react-merge-refs";
import * as immer from "immer";

import MainNav from "~/components/shared/MainNav";
import LogoType from "~/components/shared/LogoType";
import type { RgbColor } from "~/utils/colors";
import { rgbColorToStyleColor } from "~/utils/colors";
import Toolbar from "~/components/shared/Toolbar";
import { useGlobal } from "~/context/global";
import MobileNav from "~/components/shared/MobileNav";
import { unreachableValue } from "~/utils/unreachable";
import CalendarControl from "~/components/shared/CalendarControl";
import { cn } from "~/utils/cn";
import { calculateContentDistance } from "~/utils/html";
import { mapNonNullable } from "~/utils/nonNullable";
import OverlayTools from "~/components/shared/OverlayTools";

export type NavOpenState = { isOpenByClick: boolean } & NavItemStateProps;

export type NavState = immer.Immutable<{
	open: null | NavOpenState;
	isMobile: boolean;
}>;
export type NavItemStateProps = { id: string };
export type NavAction =
	| { type: "setIsMobile"; isMobile: boolean }
	| { type: "mouseEnter"; data: NavItemStateProps }
	| { type: "mouseLeave" }
	| { type: "click"; data: NavItemStateProps }
	| { type: "forceClose" };

const navReducer = immer.produce<NavState, [NavAction]>((draft, action) => {
	switch (action.type) {
		case "setIsMobile":
			draft.isMobile = action.isMobile;
			break;

		case "mouseEnter":
			draft.open = {
				isOpenByClick:
					draft.open && draft.open.id === action.data.id
						? draft.open.isOpenByClick
						: false,
				...action.data,
			};
			break;

		case "mouseLeave":
			if (draft.open && !draft.open.isOpenByClick) {
				draft.open = null;
			}
			break;

		case "forceClose":
			draft.open = null;
			break;

		case "click":
			if (draft.open && draft.open.id === action.data.id) {
				if (draft.open.isOpenByClick) {
					draft.open = null;
				} else {
					draft.open.isOpenByClick = true;
				}
			} else {
				draft.open = {
					isOpenByClick: true,
					...action.data,
				};
			}
			break;

		default:
			unreachableValue(action);
	}
});

const INITIAL_NAV_STATE = immer.freeze<NavState>({
	open: null,
	isMobile: false,
});

/** in pixel */
const MOBILE_SCROLL_THRESHOLD = 50;

function MainHeader() {
	const mainHeaderRef = useRef<Element>(null);
	const [mainHeaderMeasureRef, { height: mainHeaderHeight }] = useMeasure();
	const { isMobile, isInCinemode, openToolbarOverlay } = useGlobal();
	const navRef = useRef<HTMLDivElement>(null);

	// `null` = not sticky
	const [stickyOffset, setStickyOffset] = useState<null | number>(null);

	const [isVisibleOnMobile, setIsVisibleOnMobile] = useState(true);

	const scrollPosLastVisibilityChangeOnMobRef = useRef<null | number>(null);

	const [navState, navDispatch] = useReducer(navReducer, INITIAL_NAV_STATE);

	useEffect(() => {
		navDispatch({ type: "setIsMobile", isMobile });
		setStickyOffset(null);
	}, [isMobile]);

	useEffect(() => {
		if (!navRef.current) {
			return;
		}

		const navElem = navRef.current;

		const handleScroll = () => {
			const winScrollPos = Math.round(window.scrollY);

			if (isMobile) {
				if (scrollPosLastVisibilityChangeOnMobRef.current === null) {
					scrollPosLastVisibilityChangeOnMobRef.current =
						winScrollPos;
				}

				const scrollPosLastVisibilityChange =
					scrollPosLastVisibilityChangeOnMobRef.current;

				if (!isVisibleOnMobile && winScrollPos <= mainHeaderHeight) {
					setIsVisibleOnMobile(true);
				} else {
					if (
						(!isVisibleOnMobile &&
							scrollPosLastVisibilityChange < winScrollPos) ||
						(isVisibleOnMobile &&
							winScrollPos < scrollPosLastVisibilityChange)
					) {
						// scrolling further in the direction of the current state, no state
						// change needed => just update the stored position
						scrollPosLastVisibilityChangeOnMobRef.current =
							winScrollPos;
					} else {
						// scroll direction changed, waiting for the threshold to pass
						const delta =
							winScrollPos - scrollPosLastVisibilityChange;

						if (MOBILE_SCROLL_THRESHOLD < Math.abs(delta)) {
							scrollPosLastVisibilityChangeOnMobRef.current =
								winScrollPos;

							setIsVisibleOnMobile(delta < 0);
						}
					}
				}
			} else {
				const contentDistTop = calculateContentDistance(navElem).top;
				setStickyOffset(
					contentDistTop <= winScrollPos ? contentDistTop : null,
				);
			}
		};
		handleScroll();

		document.addEventListener("scroll", handleScroll, { passive: true });
		return () => {
			document.removeEventListener("scroll", handleScroll);
		};
	}, [isMobile, isVisibleOnMobile, mainHeaderHeight]);

	const [navColor, setNavColor] = useState<RgbColor | undefined>();

	if (isInCinemode) {
		return null;
	}

	return (
		<header
			ref={mergeRefs([mainHeaderRef, mainHeaderMeasureRef])}
			id="main-header"
			style={{
				"--color-sub-nav-bg":
					mapNonNullable(rgbColorToStyleColor)(navColor),
				transform:
					stickyOffset !== null
						? `translateY(${-stickyOffset}px`
						: undefined,
			}}
			className={cn({
				stick: stickyOffset !== null,
				"nav-down": isMobile && isVisibleOnMobile,
				"nav-up": isMobile && !isVisibleOnMobile,
			})}
		>
			{!isMobile && (
				<>
					<Toolbar />

					<AnimatePresence>
						{openToolbarOverlay !== null && (
							<motion.div
								className="toolbar-overlay-container"
								initial={{ y: "-100%", opacity: 0 }}
								animate={{ y: 0, opacity: 1 }}
								exit={{ y: "-100%", opacity: 0 }}
								transition={{ ease: "linear" }}
							>
								{openToolbarOverlay === "tools" && (
									<OverlayTools
										mainHeaderRef={mainHeaderRef}
									/>
								)}

								{/* TODO includeSnippet('elements/overlay-login') */}
								{/* TODO includeSnippet('elements/overlay-search') */}
							</motion.div>
						)}
					</AnimatePresence>
				</>
			)}

			<div className="main-header-background">
				<div className="cover_col-aside-right bg-cover"></div>
			</div>

			<div
				id="head-2"
				className="header-main-nav transition-[background-color]"
				ref={navRef}
			>
				<div className="inner-wrapper">
					<LogoType />
					{!isMobile && (
						<>
							<MainNav
								setSubNavColor={setNavColor}
								state={navState}
								dispatch={navDispatch}
							/>
							<CalendarControl />
						</>
					)}
				</div>
			</div>

			{isMobile && (
				<MobileNav
					setSubNavColor={setNavColor}
					state={navState}
					dispatch={navDispatch}
				/>
			)}
		</header>
	);
}

export default MainHeader;
