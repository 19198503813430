import { FormattedMessage } from "react-intl";

import type * as typo3 from "~/modules/typo3/schema";
import ArticleCard from "~/components/shared/cards/ArticleCard";

export type ArticleSearchDocument = typo3.SearchResultDocument & {
	type: "tx_nnbuhaarticlesandpersons_domain_model_article";
	data: typo3.ArticleCard;
};

export const isArticleSearchDocument = (
	document: typo3.SearchResultDocument,
): document is ArticleSearchDocument =>
	document.data !== null &&
	document.type === "tx_nnbuhaarticlesandpersons_domain_model_article";

export type SearchResultsArticleProps = {
	documents: Array<typo3.SearchResultDocument>;
	showEmptyError?: boolean;
};

export default function SearchResultsArticle({
	documents,
	showEmptyError,
}: SearchResultsArticleProps) {
	if (documents.length === 0) {
		if (!showEmptyError) {
			return null;
		}

		// TODO: design
		return (
			<p>
				<FormattedMessage id="noResults" />
			</p>
		);
	}

	return (
		<div className="news-overview module">
			<div className="wrapper">
				<div className="content filterTargetWrapper">
					{documents
						.filter(isArticleSearchDocument)
						.map(({ data: article }) => (
							<ArticleCard key={article.uid} article={article} />
						))}
				</div>
			</div>
		</div>
	);
}
