import type * as typo3 from "~/modules/typo3/schema";
import HomeLayout from "~/components/layouts/HomeLayout";
import PageLayout, { findSinglePlugin } from "~/components/layouts/PageLayout";
import CalendarLayout from "~/components/layouts/CalendarLayout";
import MediaLayout from "~/components/layouts/MediaLayout";
import ApplicationLayout from "~/components/layouts/ApplicationLayout";

export type LayoutProps = {
	page: typo3.Response;
};

export function renderLayoutComponent(data: typo3.Response) {
	const layoutProps: LayoutProps = { page: data };

	switch (data.appearance.backendLayout) {
		case "home":
			return <HomeLayout {...layoutProps} />;

		case "calendar":
			return <CalendarLayout {...layoutProps} />;

		case "media":
			return <MediaLayout {...layoutProps} />;

		case "bewerben":
			return <ApplicationLayout {...layoutProps} />;

		default: {
			const singlePlugin = findSinglePlugin(layoutProps);
			if (singlePlugin !== undefined) {
				return singlePlugin.renderLayoutComponent(layoutProps);
			}
			return <PageLayout {...layoutProps} />;
		}
	}
}
