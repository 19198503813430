import { type ReactNode, useState } from "react";
import { FormattedMessage } from "react-intl";
import { AnimatePresence } from "framer-motion";

import type * as typo3 from "~/modules/typo3/schema";
import SearchFacetMulti from "~/components/plugins/solr/SearchFacetMulti";
import SearchFacetSingle from "~/components/plugins/solr/SearchFacetSingle";
import SearchActiveFacets from "~/components/plugins/solr/SearchActiveFacets";
import SearchBar from "~/components/plugins/solr/SearchBar";
import OverviewHeader from "~/components/shared/OverviewHeader";
import ClickFilter from "~/components/shared/ClickFilter";

export const FEATURE_SHOW_RANGE_DATE_FACET: boolean = false;

export type SearchHeaderProps = Pick<
	typo3.SolrData,
	"facets" | "template" | "form"
> & { title?: string | null };

export default function SearchHead({
	title,
	template,
	facets,
	form,
}: SearchHeaderProps) {
	const [openedFacet, setOpenedFacet] = useState<string | null>(null);

	if (template === "person") {
		return (
			<OverviewHeader
				title={
					title || <FormattedMessage id={`solr.title.${template}`} />
				}
			>
				<div className="page-nav-sub">
					<div className="sub-nav" data-id="personen">
						<div className="content">
							<SearchBar />

							{facets.map(
								(facet) =>
									facet.type === "options" && (
										<SearchFacetSingle
											key={facet.label}
											facet={facet}
											template={template}
										/>
									),
							)}
						</div>
					</div>
				</div>
			</OverviewHeader>
		);
	}

	const filter = (
		<>
			<ClickFilter
				resetUri={form?.resetUri}
				activeOption={openedFacet}
				setActiveOption={setOpenedFacet}
				options={facets
					.filter(
						(facet) =>
							facet.type === "options" ||
							(FEATURE_SHOW_RANGE_DATE_FACET &&
								facet.type === "rangeDate"),
					)
					.map((facet) => ({
						id: facet.label,
						label: facet.label,
					}))}
			/>

			<div className="dropdown-container">
				<AnimatePresence mode="wait">
					{facets
						.filter((facet) => openedFacet === facet.label)
						.map((facet) => (
							<SearchFacetMulti key={facet.label} facet={facet} />
						))}
				</AnimatePresence>
			</div>
			<SearchActiveFacets facets={facets} />
		</>
	);

	let head: ReactNode;
	if (template === "sectionProject" || template === "sectionArchive") {
		head = (
			<div className="works-grid-header has-filter">
				<div className="title-container">
					<h2 className="content-head">
						{title || (
							<FormattedMessage id={`solr.title.${template}`} />
						)}
					</h2>
				</div>

				<div className="filter-container works-filter-container">
					{filter}
				</div>
			</div>
		);
	} else {
		head = (
			<div className="filter-container">
				<SearchBar />

				{filter}
			</div>
		);

		const isWithTitle = template !== "event";
		if (isWithTitle) {
			head = (
				<OverviewHeader
					title={
						title || (
							<FormattedMessage id={`solr.title.${template}`} />
						)
					}
				>
					{head}
				</OverviewHeader>
			);
		}
	}

	return head;
}
