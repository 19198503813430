import type * as typo3 from "~/modules/typo3/schema";
import useBackendLayoutLabel from "~/hooks/useBackendLayoutLabel";

type MainPageTitleProps = {
	page: typo3.Response;
};

export default function MainPageTitle({
	page: { meta, appearance },
}: MainPageTitleProps) {
	const label = useBackendLayoutLabel(appearance.backendLayout);

	return (
		<div className="content-head mode-page element module">
			<div className="title-wrapper">
				{label && <div className="label head category">{label}</div>}
				<h2 className="content-title">{meta.title}</h2>
			</div>
		</div>
	);
}
