import { Fragment, type ReactNode } from "react";

import MouseXScroll from "~/components/shared/MouseXScroll";

export type HorizontalScrollCardContainerProps<T> = {
	rows: Array<Array<T>>;
	mode: "news" | "podcasts";
	renderItem(data: T): ReactNode;
};

export default function HorizontalScrollCardContainer<T>({
	rows,
	renderItem,
	mode,
}: HorizontalScrollCardContainerProps<T>) {
	const maxColCount = Math.max(...rows.map((cols) => cols.length));

	return (
		<div
			className={`horizontal-scroll-card-container element mode-${mode}`}
		>
			<MouseXScroll
				isEnabled={3 < maxColCount}
				className={`inner col-${maxColCount}`}
			>
				{rows.map((items, rowIndex) => (
					<div key={rowIndex} className="layout-row">
						{items.map((item, itemIndex) => (
							<Fragment key={itemIndex}>
								{renderItem(item)}
							</Fragment>
						))}
					</div>
				))}
			</MouseXScroll>
		</div>
	);
}
