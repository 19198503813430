import { useNavigate } from "@remix-run/react";
import { useCallback, useEffect, useRef } from "react";

import type * as typo3 from "~/modules/typo3/schema";
import SearchResults, {
	type SearchResultsProps,
} from "~/components/plugins/solr/results/SearchResults";
import SearchHead from "~/components/plugins/solr/SearchHead";
import useLanguage from "~/hooks/useLanguage";

type SolrResultsProps = SearchResultsProps & {
	showResult?: boolean;
	title?: string;
};

export function SolrResults({
	data,
	showResult = true,
	title,
	...resultProps
}: SolrResultsProps) {
	useOverrideFacets(data);

	return (
		<>
			<SearchHead
				template={data.template}
				facets={data.facets}
				form={data.form}
				title={title}
			/>

			{showResult && <SearchResults data={data} {...resultProps} />}
		</>
	);
}

type SolrPluginProps = {
	data: typo3.ContentElementSolr;
	showEmptyError?: boolean;
	showResult?: boolean;
};

export default function SolrPlugin({
	data,
	showEmptyError,
	showResult,
}: SolrPluginProps) {
	return (
		<SolrResults
			id={data.id}
			title={data.content.header || undefined}
			data={data.content.data}
			showEmptyError={showEmptyError}
			showResult={showResult}
			showLoadMore
		/>
	);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function generateCurrentSemesterLabel() {
	const now = new Date();
	const year = now.getFullYear();
	const month = now.getMonth() + 1;

	if (month < 2) {
		// winter semester (previous year)
		return `${year - 1}-WiSe`;
	}

	if (month > 8) {
		// winter semester
		return `${year}-WiSe`;
	}

	// summer semester
	return `${year}-SoSe`;
}

function useOverrideFacets({ template, facets }: typo3.SolrData): void {
	const lang = useLanguage();
	const nav = useNavigate();
	const navSetFacetOption = useCallback(
		(facetOption: string) => {
			const facet = facets
				.flatMap((f) => (f.type === "options" ? f.options : undefined))
				.find((o) => o?.label === facetOption);
			if (facet) {
				nav(facet.uris.set, { replace: true });
			}
		},
		[facets, nav],
	);
	const pageInitedRef = useRef(false);

	useEffect(() => {
		// reset on template change
		pageInitedRef.current = false;
	}, [template]);

	useEffect(() => {
		if (pageInitedRef.current) return;
		pageInitedRef.current = true;

		if (template === "lehrangebot") {
			navSetFacetOption(generateCurrentSemesterLabel());
		}

		const currentUrl = new URL(window.location.href);
		if (
			template === "news" &&
			!currentUrl.searchParams.has("tx_solr[filter][0]")
		) {
			navSetFacetOption(
				lang === "de" ? "Hochschule allgemein" : "University culture",
			);
		}
	}, [lang, navSetFacetOption, template]);
}
