import type * as typo3 from "~/modules/typo3/schema";
import { cn } from "~/utils/cn";

type ContentElementHTMLProps = {
	data: typo3.ContentElementHTML;
};

export default function ContentElementHTML({ data }: ContentElementHTMLProps) {
	return (
		<section className="text module">
			<div className="text-container">
				<div className="inner">
					<div
						className={cn({
							"columns-2 gap-x-[0.3rem]":
								data.appearance.layout ===
								"layout-two-col-section",
						})}
						dangerouslySetInnerHTML={{
							__html: data.content.bodytext,
						}}
					/>
				</div>
			</div>
		</section>
	);
}
