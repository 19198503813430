import type * as typo3 from "~/modules/typo3/schema";
import ProjectCard from "~/components/shared/cards/ProjectCard";

export type SearchResultsProjectProps = {
	documents: Array<typo3.SearchResultDocument>;
};

export type ProjectSearchDocument = typo3.SearchResultDocument & {
	type: "tx_nnbuhaarticlesandpersons_domain_model_article";
	data: typo3.ProjectCard;
};

export const isProjectSearchDocument = (
	document: typo3.SearchResultDocument,
): document is ProjectSearchDocument =>
	document.type === "tx_nnbuhaarticlesandpersons_domain_model_article" &&
	document.data !== null &&
	document.data.articleType === "projekt";

export default function SearchResultsProject({
	documents,
}: SearchResultsProjectProps) {
	const colCount = Math.max(3, Math.min(7, Math.floor(documents.length / 3)));

	return (
		<section className="section works-grid module size-full">
			<div className="works-inner-wrapper inner">
				<div
					className={`works-grid-container filterTargetWrapper col-count-${colCount}`}
				>
					{documents
						.filter(isProjectSearchDocument)
						.map(({ data: article }) => (
							<ProjectCard key={article.uid} article={article} />
						))}
				</div>
			</div>
		</section>
	);
}
