import type * as typo3 from "~/modules/typo3/schema";
import Image from "~/components/shared/Image";

export type EssentialsItemMediaProps = {
	medias: typo3.Media[];
};

export default function MediaEssentials({ medias }: EssentialsItemMediaProps) {
	return (
		<div className="gallery-item">
			{medias.map((media, index) => (
				<figure key={index}>
					<Image image={media} />
					{media.properties.description !== null && (
						<figcaption>
							<span>{media.properties.description}</span>
						</figcaption>
					)}
				</figure>
			))}
		</div>
	);
}
