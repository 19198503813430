import type * as typo3 from "~/modules/typo3/schema";
import { SolrResults } from "~/components/plugins/solr/Solr";

type SectionProjectsProps = {
	page: typo3.Response;
};

const loadMoreUrlTemplate = ({ loadMoreLink }: { loadMoreLink: string }) =>
	`section-archive/${loadMoreLink}`;

export default function SectionArchive({ page }: SectionProjectsProps) {
	if (!page.archive) return null;

	return (
		<div className="section works-grid module size-full" id="archive">
			<div className="works-inner-wrapper inner">
				<SolrResults
					title="Archiv"
					data={page.archive}
					showLoadMore
					loadMoreUrlTemplate={loadMoreUrlTemplate}
				/>
			</div>
		</div>
	);
}
