import { type IconProps } from "~/components/icons";

export default function FooterIcon({ label }: IconProps) {
	return (
		<svg
			version="1.1"
			x="0px"
			y="0px"
			viewBox="0 0 841.9 119.8"
			style={{
				// Deprecated: https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/enable-background
				// @ts-expect-error Not defined css property
				enableBackground: "new 0 0 841.9 119.8",
				fill: "var(--color-main-font)",
			}}
			xmlSpace="preserve"
			aria-label={label ?? "Burg Giebichenstein Kunsthochschule Halle"}
		>
			<g transform="translate(0 -3)">
				<path
					d="M545,100.3v-1.2c-2.6-0.3-2.9-0.5-2.9-3.6v-8.8c0-4.4-2.2-6.9-5.9-6.9c-1.3,0-2.5,0.5-3.8,1.4
				c-1.1,0.8-2.1,1.5-3.1,2.1v-3.6c-2,0.9-4.2,1.4-6.4,1.8v1.1c2.5,0.5,2.7,0.6,2.7,3.6v9.4c0,3-0.2,3.2-3.3,3.6v1.2h9.9v-1.2
				c-2.7-0.3-3-0.5-3-3.6V84.8c1.4-1.2,3.2-2.3,5-2.3c3.1,0,4.2,2.1,4.2,5.2v7.6c0,3.2-0.3,3.5-3,3.8v1.2H545z M510.4,100.8
				c2,0,3.3,0.1,4.5,0.6c1.3,0.5,2.1,1.7,2.1,3.2c0,2.8-2.2,5.1-6.6,5.1c-3.4,0-6-1.9-6-4.4c0-1.3,0.9-2.6,2.1-3.6
				C507.3,101.1,508.3,100.8,510.4,100.8 M509.4,81.2c2.6,0,4.3,2.6,4.3,6.1c0,3.6-1.8,5.4-3.8,5.4c-2.5,0-4.3-2.6-4.3-6.1
				C505.7,83.1,507.5,81.2,509.4,81.2 M520.6,80.3c-1.7,0.3-4,0.5-6.2,0.7c-1.4-0.8-2.9-1.2-4.4-1.2h0c-3.5,0-8.2,2.4-8.2,7.6
				c0,3.1,2,5.3,4,6.1c-0.7,0.9-2.2,2.1-4,2.8c0,0.5,0.1,1.3,0.5,1.9c0.6,1,1.7,2,3.4,2.5c-1.1,0.9-2.9,2.3-3.8,3.2
				c-0.7,0.7-1.1,1.6-1.1,2.6c0,2.6,2.8,5.6,8,5.6c6.1,0,11.4-4.4,11.4-9c0-4.5-3.7-5.4-6.5-5.4c-1.6,0-3.2,0-4.8,0
				c-2.1,0-2.9-0.9-2.9-1.7c0-0.6,0.8-1.5,1.4-1.9c0.7,0.1,1.6,0.2,2.3,0.2c3.9,0,8-2.4,8-7.3c0-1.6-0.5-3.1-1.4-4l2.6,0.1
				c0.7-0.4,1.6-1.6,2.1-2.3L520.6,80.3z M498.7,100.3v-1.2c-2.9-0.3-3.2-0.5-3.2-3.7V79.7c-2,0.8-4.2,1.4-6.5,1.8v1.1
				c2.7,0.4,2.9,0.6,2.9,3.5v9.4c0,3.1-0.3,3.4-3.2,3.7v1.2H498.7z M493.3,75.8c1.3,0,2.3-1.1,2.3-2.3c0-1.4-1-2.4-2.3-2.4
				c-1.2,0-2.3,1-2.3,2.4C491.1,74.7,492.2,75.8,493.3,75.8 M485,85.5c-0.1-1.1-0.6-3.8-1-4.8c-0.9-0.4-2.1-0.8-4-0.8
				c-4.2,0-6.9,2.7-6.9,5.9c0,3.5,3,5,5.7,6.3c2.3,1,3.6,2.1,3.6,4.2c0,2-1.6,3.2-3.2,3.2c-2.7,0-4.8-2.6-5.6-5.7l-1.2,0.2
				c0,1.6,0.5,4.8,0.8,5.4c0.8,0.7,2.9,1.6,5.2,1.6c3.7,0,7.5-2.1,7.5-6.3c0-3.2-2.3-4.8-5.8-6.2c-2-0.9-3.8-1.9-3.8-3.9
				c0-1.8,1.2-3.2,3-3.2c2.2,0,3.8,1.8,4.7,4.5L485,85.5z M461.3,81.5c2.6,0,4.1,2.2,4.1,4.6c0,0.7-0.3,0.9-1.1,0.9
				c-2.7,0.1-5.2,0.1-7.8,0.1C457.2,83.4,459.2,81.5,461.3,81.5 M468.4,95.5c-1.7,1.6-3.4,2.3-5.3,2.3c-3.2,0-6.8-2.6-6.9-8.8
				c5.5-0.2,11.2-0.6,12-0.8c0.9-0.2,1.1-0.5,1.1-1.5c0-3.5-3.1-6.9-6.9-6.9h0c-2.3,0-4.8,1.3-6.7,3.3c-1.9,2.1-3,4.9-3,8
				c0,5.2,3.3,9.7,8.9,9.7c1.4,0,4.6-0.8,7.4-4.4L468.4,95.5z M426.9,75.3c0-1.8,0.2-2.3,0.6-2.6c0.5-0.4,1.6-0.6,3.6-0.6
				c3.1,0,5.7,0.6,7.8,1.9c3.7,2.1,5.4,6.3,5.4,11c0,7.3-4,13.7-12.5,13.7c-3.3,0-4.8-0.9-4.8-4.3V75.3z M419.1,70.6v1.3
				c3.5,0.3,3.9,0.5,3.9,4.4v18.4c0,3.9-0.4,4.2-4.3,4.4v1.2h10.8c5.6,0,10.1-1.1,13.6-3.8c3.5-2.6,5.6-6.7,5.6-12.2
				c0-4.3-1.6-7.6-3.9-9.8c-2.8-2.7-7.1-3.9-12.9-3.9H419.1z M399.2,96.4c-1.1,0.8-2.8,1.6-4.4,1.6c-3,0-6.3-2.6-6.3-8.1
				c0-6.6,3.8-8.4,6-8.4c1.7,0,3.6,0.7,4.7,2.3V96.4z M406.3,98l-1.5-0.1c-1.7-0.2-2-0.4-2-2.8V67.8c-1.7,0.5-4.8,1.3-7.1,1.6v1.1
				c3.3,0.2,3.5,0.4,3.5,3.3v6.5c-1.1-0.4-2.4-0.5-3.1-0.5c-6.2,0-11.7,5-11.7,11.3c0,6.4,4.8,9.7,8.5,9.7c0.5,0,1.7-0.3,3.6-1.3
				l2.8-1.6v3c2.7-1,6.1-1.6,7.1-1.7V98z M381.8,100.3v-1.2c-2.7-0.3-2.9-0.5-2.9-3.6v-8.8c0-4.4-2.2-6.9-5.9-6.9
				c-1.3,0-2.5,0.5-3.8,1.4c-1.1,0.8-2.1,1.5-3.1,2.1v-3.6c-2,0.9-4.2,1.4-6.4,1.8v1.1c2.5,0.5,2.7,0.6,2.7,3.6v9.4
				c0,3-0.2,3.2-3.3,3.6v1.2h9.9v-1.2c-2.7-0.3-3-0.5-3-3.6V84.8c1.4-1.2,3.2-2.3,5-2.3c3.1,0,4.2,2.1,4.2,5.2v7.6
				c0,3.2-0.3,3.5-3,3.8v1.2H381.8z M350.7,96.5c-0.9,0.9-2.5,1.6-3.7,1.6c-1.4,0-3.2-1.1-3.2-3.5c0-1.7,1-2.6,2.8-3.4
				c1-0.5,3.2-1.4,4.1-1.9V96.5z M358,98.5l-0.5-1.2c-0.5,0.3-1.1,0.5-1.6,0.5c-0.8,0-1.6-0.7-1.6-2.7v-8.5c0-2.4-0.5-4.2-2.1-5.6
				c-1-0.8-2.4-1.2-3.7-1.2c-2.6,0.5-5.6,2.3-6.9,3.2c-0.9,0.6-1.4,1.2-1.4,1.8c0,1.1,1.1,2,2,2c0.6,0,1.1-0.3,1.3-1
				c0.4-1.4,0.8-2.4,1.3-3c0.5-0.5,1.2-0.9,2.1-0.9c2.3,0,3.9,1.8,3.9,5.2v0.8c-1,0.8-4.3,2.1-7.1,3c-2.5,0.9-3.7,2.3-3.7,4.5
				c0,2.9,2.2,5.6,5.5,5.6c1.4,0,3.6-1.7,5.4-2.9c0.2,0.8,0.5,1.4,1.1,2c0.5,0.5,1.5,0.9,2.1,0.9L358,98.5z M327.5,98.7l-0.4-1.2
				c-0.6,0.3-1.6,0.8-2.8,0.8c-1.2,0-2.7-0.7-2.7-4.4V82.2h4.9c0.6-0.4,0.7-1.3,0.3-1.8h-5.2v-6.3L321,74l-3,3.1v3.3h-2.1l-1.3,1.3
				l0.2,0.5h3.2v13.2c0,3.6,1.6,5.4,4.5,5.4c0.5,0,1.1-0.2,1.9-0.6L327.5,98.7z M304.7,84.7v-5c-2.2,0.9-4.2,1.5-6.4,1.8v1.1
				c2.6,0.3,2.8,0.5,2.8,3.5v9.5c0,3-0.3,3.3-3.2,3.6v1.2h10.5v-1.2c-3.5-0.3-3.7-0.5-3.7-3.6v-8.4c1-2.6,2.5-3.9,3.7-3.9
				c0.7,0,1.3,0.3,2,0.9c0.3,0.3,0.6,0.3,1.2,0.1c0.9-0.4,1.5-1.3,1.5-2.2c0-1.1-1.1-2.3-2.7-2.3C308.2,79.8,306.2,82.4,304.7,84.7
				L304.7,84.7z M284.1,87.5h-8.4c1.4-3.7,2.8-7.8,4.3-11.7h0.1L284.1,87.5z M295.8,100.3V99c-3-0.3-3.6-0.7-5-4.6
				c-2.9-7.9-6-16.7-8.8-24.5l-1.4,0.4l-9.5,23.9c-1.6,4.1-2.2,4.3-5.1,4.8v1.3h10V99c-3.5-0.3-3.8-0.8-2.9-3.7c0.6-1.9,1.3-3.8,2-5.9
				h9.7c1,2.8,1.9,5.3,2.5,7c0.6,1.8,0.2,2.1-2.7,2.5v1.3H295.8z M243.6,80.3l-1.2,1.3l0.2,0.5h3.2v13.4c0,3-0.3,3.2-3.2,3.5v1.2h10.8
				v-1.1c-3.6-0.4-3.9-0.5-3.9-3.9V82.2h4.9c0.5-0.3,0.7-1.4,0.5-1.8h-5.4v-1.8c0-3.9,0.5-6.1,1.1-7c0.5-0.8,1.2-1.3,2.4-1.3
				c1.5,0,2.8,0.8,3.7,1.7c0.4,0.5,0.8,0.5,1.2,0.1c0.5-0.4,0.7-0.7,0.9-1.1c0.2-0.5,0.2-1.1-0.2-1.6c-0.7-0.9-2.1-1.5-3.6-1.6
				c-1.8,0.2-3.4,1.1-5,2.4c-2.5,2.1-3.2,4.4-3.5,5.4c-0.3,1-0.5,2.3-0.5,3.8v1H243.6z M229.5,81.3c4.1,0,6.2,4.4,6.2,9.7
				c0,6.3-2.3,8.4-5.2,8.4c-3.8,0-6.3-4.3-6.3-10C224.3,84.1,226.8,81.3,229.5,81.3 M230.1,79.8c-4.2,0-10.1,3.4-10.1,11
				c0,5.1,3.7,10.1,10,10.1c4.5,0,9.9-3.5,9.9-10.9C240,84.4,235.8,79.8,230.1,79.8 M208.9,80.3h-8.3v1.2c2.7,0.3,2.9,0.9,2.4,2.6
				c-0.9,2.9-2.9,8.3-4.2,11.4L194.7,84c-0.6-1.7-0.4-2.2,1.7-2.5l0-1.2h-9.2v1.2c2.5,0.3,2.9,0.9,3.9,3.4c1.3,3.2,3.4,8.8,5.2,14
				c0.3,0.8,0.3,1.4,0.1,2.1c-0.2,0.6-0.6,1.6-1.4,3.2c-1.1,2-2,3.2-3.4,4c-0.8,0.5-1.1,1-1.1,1.7c0,1.1,1.3,2.2,2.6,2.2
				c0.6,0,1.2-0.2,1.5-1.1c0.6-2.4,1.6-5,4.7-12.3c3.2-7.5,4.6-11.3,5.8-13.8c1.2-2.6,1.6-2.9,4-3.3V80.3z M186.6,98.7l-0.4-1.2
				c-0.6,0.3-1.6,0.8-2.8,0.8c-1.2,0-2.7-0.7-2.7-4.4V82.2h4.9c0.6-0.4,0.7-1.3,0.3-1.8h-5.2v-6.3l-0.6-0.1l-3,3.1v3.3H175l-1.3,1.3
				l0.2,0.5h3.2v13.2c0,3.6,1.6,5.4,4.5,5.4c0.5,0,1.1-0.2,1.9-0.6L186.6,98.7z M172.8,100.3v-1.2c-2.9-0.3-3.2-0.5-3.2-3.7V79.7
				c-2,0.8-4.2,1.4-6.5,1.8v1.1c2.7,0.4,2.9,0.6,2.9,3.5v9.4c0,3.1-0.3,3.4-3.2,3.7v1.2H172.8z M167.5,75.8c1.3,0,2.3-1.1,2.3-2.3
				c0-1.4-1-2.4-2.3-2.4c-1.2,0-2.3,1-2.3,2.4C165.2,74.7,166.3,75.8,167.5,75.8 M159.1,85.5c-0.1-1.1-0.6-3.8-1-4.8
				c-0.9-0.4-2.1-0.8-4-0.8c-4.2,0-6.9,2.7-6.9,5.9c0,3.5,3,5,5.7,6.3c2.3,1,3.6,2.1,3.6,4.2c0,2-1.6,3.2-3.2,3.2
				c-2.7,0-4.8-2.6-5.6-5.7l-1.2,0.2c0,1.6,0.5,4.8,0.8,5.4c0.8,0.7,2.9,1.6,5.2,1.6c3.7,0,7.5-2.1,7.5-6.3c0-3.2-2.3-4.8-5.8-6.2
				c-2-0.9-3.8-1.9-3.8-3.9c0-1.8,1.2-3.2,3-3.2c2.2,0,3.8,1.8,4.7,4.5L159.1,85.5z M136.1,84.7v-5c-2.2,0.9-4.2,1.5-6.4,1.8v1.1
				c2.7,0.3,2.8,0.5,2.8,3.5v9.5c0,3-0.3,3.3-3.2,3.6v1.2h10.5v-1.2c-3.5-0.3-3.7-0.5-3.7-3.6v-8.4c1-2.6,2.5-3.9,3.7-3.9
				c0.7,0,1.3,0.3,2,0.9c0.3,0.3,0.6,0.3,1.2,0.1c0.9-0.4,1.5-1.3,1.5-2.2c0-1.1-1.1-2.3-2.6-2.3C139.5,79.8,137.6,82.4,136.1,84.7
				L136.1,84.7z M118.7,81.5c2.6,0,4.1,2.2,4.1,4.6c0,0.7-0.3,0.9-1.1,0.9c-2.6,0.1-5.2,0.1-7.8,0.1C114.6,83.4,116.7,81.5,118.7,81.5
			 	M125.8,95.5c-1.7,1.6-3.4,2.3-5.3,2.3c-3.2,0-6.8-2.6-6.9-8.8c5.5-0.2,11.2-0.6,12-0.8c0.9-0.2,1.1-0.5,1.1-1.5
				c0-3.5-3.1-6.9-6.9-6.9h0c-2.3,0-4.8,1.3-6.7,3.3c-1.9,2.1-3,4.9-3,8c0,5.2,3.3,9.7,8.9,9.7c1.4,0,4.6-0.8,7.4-4.4L125.8,95.5z
			 	M109.5,80.3h-8.1v1.2c2.6,0.3,2.9,0.8,2.1,3c-1,3-2.5,7.1-4.1,10.9c-1.3-3.2-3.2-8.1-4.3-11.3c-0.7-2-0.6-2.3,1.8-2.6v-1.2h-9.4
				v1.2c2.5,0.4,2.8,0.7,3.8,3.3c2.2,5.3,4.3,10.5,6.3,16H99c2.6-6.8,4.9-12.4,6.5-15.9c1.2-2.7,1.6-3.1,4-3.4V80.3z M87.2,100.3v-1.2
				c-2.9-0.3-3.2-0.5-3.2-3.7V79.7c-2,0.8-4.2,1.4-6.5,1.8v1.1c2.7,0.4,2.9,0.6,2.9,3.5v9.4c0,3.1-0.3,3.4-3.2,3.7v1.2H87.2z
			 	M81.9,75.8c1.3,0,2.3-1.1,2.3-2.3c0-1.4-1-2.4-2.3-2.4c-1.2,0-2.3,1-2.3,2.4C79.6,74.7,80.7,75.8,81.9,75.8 M74.7,100.3v-1.2
				c-2.7-0.3-2.9-0.5-2.9-3.6v-8.8c0-4.4-2.2-6.9-5.9-6.9c-1.3,0-2.5,0.5-3.8,1.4c-1.1,0.8-2.1,1.5-3.1,2.1v-3.6
				c-2,0.9-4.2,1.4-6.3,1.8v1.1c2.5,0.5,2.7,0.6,2.7,3.6v9.4c0,3-0.2,3.2-3.3,3.6v1.2H62v-1.2c-2.7-0.3-3-0.5-3-3.6V84.8
				c1.4-1.2,3.2-2.3,5-2.3c3.1,0,4.2,2.1,4.2,5.2v7.6c0,3.2-0.3,3.5-3,3.8v1.2H74.7z M51.8,70.6H40.9v1.3c3.2,0.2,4.2,0.8,4.4,2.6
				c0.2,1.4,0.3,3.3,0.3,7.5v3.4c0,7.5-2.1,13.1-8.6,13.1c-5.7,0-8.6-4.2-8.6-11.6V76.5c0-4.1,0.4-4.4,3.9-4.6v-1.3H20.6v1.3
				c3.6,0.3,4,0.5,4,4.6v11.4c0,5.4,1.3,8.8,4,10.9c2,1.6,4.6,2.2,7.3,2.2c3,0,6.1-1,8.2-3.2c2.6-2.8,3.4-6.7,3.4-12.1V82
				c0-4.2,0.1-6.2,0.3-7.4c0.2-1.9,1.2-2.5,4-2.7V70.6z"
				/>
			</g>
			<g>
				<path
					d="M814.1,26c2.6,0,4.1,2.2,4.1,4.6c0,0.7-0.3,0.9-1.1,0.9c-2.6,0.1-5.2,0.1-7.8,0.1C810,27.9,812,26,814.1,26
				M821.2,40c-1.7,1.6-3.4,2.3-5.3,2.3c-3.2,0-6.8-2.6-6.9-8.8c5.5-0.2,11.2-0.6,12-0.8c0.9-0.2,1-0.5,1-1.5c0-3.5-3.1-6.9-6.9-6.9h0
				c-2.3,0-4.8,1.3-6.7,3.3c-1.9,2.1-3,4.9-3,8c0,5.2,3.3,9.7,8.9,9.7c1.4,0,4.6-0.8,7.4-4.4L821.2,40z M803.1,44.8v-1.2
				c-2.9-0.3-3.3-0.5-3.3-3.6V12.3c-1.6,0.5-4.4,1.3-6.8,1.6V15c2.9,0.3,3.2,0.4,3.2,3.5v21.6c0,3-0.4,3.3-3.3,3.6v1.2H803.1z
			 	M791.3,44.8v-1.2c-2.9-0.3-3.3-0.5-3.3-3.6V12.3c-1.6,0.5-4.4,1.3-6.8,1.6V15c2.9,0.3,3.2,0.4,3.2,3.5v21.6c0,3-0.4,3.3-3.3,3.6
				v1.2H791.3z M772.8,41c-0.9,0.9-2.5,1.6-3.7,1.6c-1.4,0-3.2-1.1-3.2-3.5c0-1.7,1-2.6,2.8-3.4c1-0.5,3.2-1.4,4.1-1.9V41z M780,43.1
				l-0.5-1.2c-0.5,0.3-1.1,0.5-1.6,0.5c-0.8,0-1.6-0.7-1.6-2.7v-8.5c0-2.4-0.5-4.2-2.1-5.6c-1-0.8-2.4-1.2-3.7-1.2
				c-2.6,0.5-5.6,2.3-6.9,3.2c-0.9,0.6-1.4,1.2-1.4,1.8c0,1.1,1.1,2,2,2c0.6,0,1.1-0.3,1.3-1.1c0.4-1.4,0.8-2.4,1.3-3
				c0.5-0.5,1.2-0.9,2.1-0.9c2.3,0,3.9,1.8,3.9,5.2v0.8c-1,0.8-4.3,2.1-7.1,3c-2.5,0.9-3.7,2.3-3.7,4.5c0,2.9,2.2,5.6,5.5,5.6
				c1.4,0,3.6-1.7,5.4-2.9c0.2,0.8,0.5,1.4,1.1,2c0.5,0.5,1.5,0.9,2.1,0.9L780,43.1z M759.6,15.1h-11.9v1.3c3.6,0.3,3.9,0.5,3.9,4.3
				v7.4H736v-7.4c0-3.8,0.4-4.1,3.8-4.3v-1.3H728v1.3c3.7,0.3,4.1,0.5,4.1,4.3v18.5c0,3.8-0.4,4.1-4,4.3v1.3h12v-1.3
				c-3.7-0.3-4.1-0.5-4.1-4.3v-9.1h15.6v9.1c0,3.8-0.4,4.1-4.3,4.3v1.3h12.2v-1.3c-3.7-0.3-4.1-0.5-4.1-4.3V20.8c0-3.8,0.4-4,4.1-4.3
				V15.1z M706.8,26c2.6,0,4.1,2.2,4.1,4.6c0,0.7-0.3,0.9-1.1,0.9c-2.7,0.1-5.2,0.1-7.8,0.1C702.7,27.9,704.7,26,706.8,26 M713.9,40
				c-1.7,1.6-3.4,2.3-5.3,2.3c-3.2,0-6.8-2.6-6.9-8.8c5.5-0.2,11.2-0.6,12-0.8c0.9-0.2,1.1-0.5,1.1-1.5c0-3.5-3.1-6.9-6.9-6.9h0
				c-2.3,0-4.8,1.3-6.7,3.3c-1.9,2.1-3,4.9-3,8c0,5.2,3.3,9.7,8.9,9.7c1.4,0,4.6-0.8,7.4-4.4L713.9,40z M695.8,44.8v-1.2
				c-2.9-0.3-3.3-0.5-3.3-3.6V12.3c-1.6,0.5-4.4,1.3-6.8,1.6V15c2.9,0.3,3.2,0.4,3.2,3.5v21.6c0,3-0.4,3.3-3.3,3.6v1.2H695.8z
			 	M684.1,42.6l-1.2,0c-1.8-0.1-2.1-0.4-2.1-2.7V24.6c-0.7,0.1-1.8,0.3-3.1,0.5c-1.3,0.2-2.9,0.3-4.1,0.4v1l1.6,0.3
				c1.5,0.2,1.9,0.5,1.9,2.9v10.7c-1.6,1.5-3.3,2.2-4.9,2.2c-2.1,0-4-1.1-4-5.2v-6.2c0-3.1,0-5.2,0.1-6.6c-0.7,0.1-2,0.3-3.2,0.5
				c-1.2,0.1-2.3,0.3-3.3,0.3v1l1.3,0.3c1.1,0.3,1.5,0.5,1.5,2.9v9c0,4.9,2.6,6.8,5.7,6.8c1.2,0,2.2-0.4,3.5-1.1
				c1.3-0.8,2.3-1.6,3.4-2.4v3.2l0.3,0.3c0.9-0.2,2-0.6,3.2-0.9c1.2-0.3,2.5-0.6,3.5-0.7V42.6z M660.4,44.8v-1.2c-2.7-0.2-3-0.5-3-3.5
				v-8c0-5.5-2.6-7.8-6.3-7.8c-2.5,0-4.9,1.7-6.5,3.3V12.3c-1.8,0.5-4.5,1.3-6.7,1.6V15c3,0.3,3.1,0.5,3.1,3.3v21.8
				c0,3-0.4,3.3-3.1,3.5l0,1.2h9.7v-1.2c-2.7-0.3-3-0.6-3-3.5V29.3c1.3-1.5,3-2.3,4.8-2.3c3.1,0,4.5,1.9,4.5,5.6v7.6
				c0,3-0.4,3.2-3,3.5v1.2H660.4z M635.7,39.8c-2.1,2-3.6,2.5-5.5,2.5c-2.6,0-6.9-2.1-6.9-8.5c0-4.8,2.6-7.3,5.6-7.3
				c1.6,0,3.2,0.6,4.6,1.9c0.4,0.4,0.6,0.5,0.9,0.5c0.5,0,1.5-1,1.5-2c0-0.5-0.1-0.9-0.7-1.4c-0.7-0.6-2.2-1.2-4.1-1.2h0
				c-1.7,0-4.4,0.8-6.8,2.6c-3.1,2.3-4.6,5.4-4.6,8.9c0,4.9,3.4,9.5,9.2,9.5c2.7,0,5.7-2,7.7-4.7L635.7,39.8z M615.3,30
				c-0.1-1.1-0.6-3.8-1-4.8c-0.9-0.4-2.1-0.8-4-0.8c-4.2,0-6.9,2.7-6.9,5.9c0,3.5,3,5,5.7,6.3c2.3,1,3.6,2.1,3.6,4.2
				c0,2-1.6,3.2-3.2,3.2c-2.7,0-4.8-2.6-5.6-5.7l-1.2,0.2c0,1.6,0.5,4.8,0.8,5.4c0.8,0.7,2.9,1.6,5.2,1.6c3.7,0,7.5-2.1,7.5-6.3
				c0-3.2-2.3-4.8-5.8-6.2c-2-0.9-3.8-1.9-3.8-3.9c0-1.8,1.2-3.2,3-3.2c2.2,0,3.8,1.8,4.7,4.5L615.3,30z M600.2,44.8v-1.2
				c-2.6-0.2-3-0.5-3-3.5v-8c0-5.5-2.6-7.8-6.3-7.8c-2.5,0-4.9,1.7-6.5,3.3V12.3c-1.8,0.5-4.5,1.3-6.7,1.6V15c3,0.3,3.1,0.5,3.1,3.3
				v21.8c0,3-0.4,3.3-3.1,3.5l0,1.2h9.7v-1.2c-2.7-0.3-3-0.6-3-3.5V29.3c1.3-1.5,3-2.3,4.8-2.3c3.1,0,4.5,1.9,4.5,5.6v7.6
				c0,3-0.4,3.2-3,3.5v1.2H600.2z M575.5,39.8c-2.1,2-3.6,2.5-5.5,2.5c-2.6,0-6.9-2.1-6.9-8.5c0-4.8,2.6-7.3,5.6-7.3
				c1.6,0,3.2,0.6,4.6,1.9c0.4,0.4,0.6,0.5,0.9,0.5c0.5,0,1.5-1,1.5-2c0-0.5-0.1-0.9-0.7-1.4c-0.7-0.6-2.2-1.2-4.1-1.2h0
				c-1.7,0-4.4,0.8-6.8,2.6c-3.1,2.3-4.6,5.4-4.6,8.9c0,4.9,3.4,9.5,9.2,9.5c2.7,0,5.7-2,7.7-4.7L575.5,39.8z M545.1,25.8
				c4.1,0,6.2,4.4,6.2,9.7c0,6.3-2.3,8.4-5.2,8.4c-3.8,0-6.3-4.3-6.3-10C539.9,28.7,542.4,25.8,545.1,25.8 M545.7,24.3
				c-4.2,0-10.1,3.4-10.1,11c0,5.1,3.7,10.1,10,10.1c4.5,0,9.9-3.5,9.9-10.9C555.6,28.9,551.4,24.3,545.7,24.3 M533.2,44.8v-1.2
				c-2.6-0.2-3-0.5-3-3.5v-8c0-5.5-2.6-7.8-6.3-7.8c-2.5,0-4.9,1.7-6.5,3.3V12.3c-1.8,0.5-4.5,1.3-6.7,1.6V15c3,0.3,3.1,0.5,3.1,3.3
				v21.8c0,3-0.4,3.3-3.1,3.5l0,1.2h9.7v-1.2c-2.7-0.3-3-0.6-3-3.5V29.3c1.3-1.5,3-2.3,4.8-2.3c3.1,0,4.5,1.9,4.5,5.6v7.6
				c0,3-0.4,3.2-3,3.5v1.2H533.2z M509.2,43.2l-0.4-1.2c-0.6,0.3-1.6,0.8-2.8,0.8c-1.2,0-2.7-0.7-2.7-4.4V26.7h4.9
				c0.6-0.4,0.7-1.3,0.3-1.8h-5.2v-6.3l-0.6-0.1l-3,3.1v3.3h-2.1l-1.3,1.3l0.2,0.5h3.2v13.2c0,3.6,1.6,5.4,4.5,5.4
				c0.5,0,1.1-0.2,1.9-0.6L509.2,43.2z M493.6,30c-0.1-1.1-0.6-3.8-1-4.8c-0.9-0.4-2.1-0.8-4-0.8c-4.2,0-6.9,2.7-6.9,5.9
				c0,3.5,3,5,5.7,6.3c2.3,1,3.6,2.1,3.6,4.2c0,2-1.6,3.2-3.2,3.2c-2.7,0-4.8-2.6-5.6-5.7l-1.2,0.2c0,1.6,0.5,4.8,0.8,5.4
				c0.8,0.7,2.9,1.6,5.2,1.6c3.7,0,7.5-2.1,7.5-6.3c0-3.2-2.3-4.8-5.8-6.2c-2-0.9-3.8-1.9-3.8-3.9c0-1.8,1.2-3.2,3-3.2
				c2.2,0,3.8,1.8,4.7,4.5L493.6,30z M478.3,44.8v-1.2c-2.6-0.3-2.9-0.5-2.9-3.6v-8.8c0-4.4-2.2-6.9-5.9-6.9c-1.3,0-2.5,0.5-3.8,1.4
				c-1.1,0.8-2.1,1.5-3.1,2.1v-3.6c-2,0.9-4.2,1.4-6.3,1.8v1c2.5,0.5,2.7,0.6,2.7,3.6v9.4c0,3-0.2,3.2-3.3,3.6v1.2h9.9v-1.2
				c-2.7-0.3-3-0.5-3-3.6V29.3c1.4-1.2,3.2-2.3,5-2.3c3.1,0,4.2,2.1,4.2,5.2v7.6c0,3.2-0.3,3.5-3,3.8v1.2H478.3z M453.8,42.6l-1.2,0
				c-1.8-0.1-2.1-0.4-2.1-2.7V24.6c-0.7,0.1-1.8,0.3-3.1,0.5c-1.3,0.2-2.9,0.3-4.1,0.4v1l1.6,0.3c1.5,0.2,1.9,0.5,1.9,2.9v10.7
				c-1.6,1.5-3.3,2.2-4.9,2.2c-2.1,0-4-1.1-4-5.2v-6.2c0-3.1,0-5.2,0.1-6.6c-0.7,0.1-2,0.3-3.2,0.5c-1.2,0.1-2.3,0.3-3.3,0.3v1
				l1.3,0.3c1.1,0.3,1.5,0.5,1.5,2.9v9c0,4.9,2.6,6.8,5.7,6.8c1.2,0,2.2-0.4,3.5-1.1c1.3-0.8,2.3-1.6,3.4-2.4v3.2l0.3,0.3
				c0.9-0.2,2-0.6,3.2-0.9c1.2-0.3,2.5-0.6,3.5-0.7V42.6z M431.9,44.8v-1.3c-2.2-0.3-3.1-0.7-5.5-3.3c-2.3-2.5-7.3-8.1-10.7-12.4
				c3.1-3.1,6.3-6.4,8.1-8c2.9-2.7,4-3.1,7.4-3.4v-1.3h-11v1.3l1.1,0.1c2.1,0.2,2.1,0.7,0.7,2.3c-2.1,2.5-5.1,5.7-7.6,8.1
				c-1.3,1.2-2.2,1.8-3.2,2.1v-8c0-4.1,0.5-4.4,4-4.7v-1.3h-11.8v1.3c3.6,0.3,4,0.5,4,4.7V39c0,4-0.4,4.3-3.8,4.6v1.3h11.9v-1.3
				c-3.8-0.3-4.2-0.6-4.2-4.6v-8.7c0.8-0.1,1.4,0,2.5,1.2c4.1,4.8,7.8,9.3,11.3,13.3H431.9z M390,44.8v-1.2c-2.7-0.3-2.9-0.5-2.9-3.6
				v-8.8c0-4.4-2.2-6.9-5.9-6.9c-1.3,0-2.5,0.5-3.8,1.4c-1.1,0.8-2.1,1.5-3.1,2.1v-3.6c-2,0.9-4.2,1.4-6.3,1.8v1
				c2.5,0.5,2.7,0.6,2.7,3.6v9.4c0,3-0.2,3.2-3.3,3.6v1.2h9.9v-1.2c-2.7-0.3-3-0.5-3-3.6V29.3c1.4-1.2,3.2-2.3,5-2.3
				c3.1,0,4.2,2.1,4.2,5.2v7.6c0,3.2-0.3,3.5-3,3.8v1.2H390z M365.4,44.8v-1.2c-2.9-0.3-3.2-0.5-3.2-3.7V24.2c-2,0.8-4.2,1.4-6.5,1.8
				v1.1c2.7,0.4,2.9,0.6,2.9,3.5V40c0,3.1-0.3,3.4-3.2,3.7v1.2H365.4z M360,20.4c1.3,0,2.3-1.1,2.3-2.3c0-1.4-1-2.4-2.3-2.4
				c-1.2,0-2.3,1-2.3,2.4C357.7,19.3,358.8,20.4,360,20.4 M344.7,26c2.6,0,4.1,2.2,4.1,4.6c0,0.7-0.3,0.9-1.1,0.9
				c-2.6,0.1-5.2,0.1-7.8,0.1C340.6,27.9,342.7,26,344.7,26 M351.8,40c-1.7,1.6-3.4,2.3-5.3,2.3c-3.2,0-6.8-2.6-6.9-8.8
				c5.5-0.2,11.2-0.6,12-0.8c0.9-0.2,1-0.5,1-1.5c0-3.5-3.1-6.9-6.9-6.9h0c-2.3,0-4.8,1.3-6.7,3.3c-1.9,2.1-3,4.9-3,8
				c0,5.2,3.3,9.7,8.9,9.7c1.4,0,4.6-0.8,7.4-4.4L351.8,40z M334.4,43.2L334,42c-0.6,0.3-1.6,0.8-2.8,0.8c-1.2,0-2.7-0.7-2.7-4.4V26.7
				h4.9c0.6-0.4,0.7-1.3,0.3-1.8h-5.2v-6.3l-0.6-0.1l-3,3.1v3.3h-2.1l-1.3,1.3l0.2,0.5h3.2v13.2c0,3.6,1.6,5.4,4.5,5.4
				c0.5,0,1.1-0.2,1.9-0.6L334.4,43.2z M318.8,30c-0.1-1.1-0.6-3.8-1-4.8c-0.9-0.4-2.1-0.8-4-0.8c-4.2,0-6.9,2.7-6.9,5.9
				c0,3.5,3,5,5.7,6.3c2.3,1,3.6,2.1,3.6,4.2c0,2-1.6,3.2-3.2,3.2c-2.7,0-4.8-2.6-5.6-5.7l-1.2,0.2c0,1.6,0.5,4.8,0.8,5.4
				c0.8,0.7,2.9,1.6,5.2,1.6c3.7,0,7.5-2.1,7.5-6.3c0-3.2-2.3-4.8-5.8-6.2c-2-0.9-3.8-1.9-3.8-3.9c0-1.8,1.2-3.2,3-3.2
				c2.2,0,3.8,1.8,4.7,4.5L318.8,30z M303.6,44.8v-1.2c-2.7-0.3-2.9-0.5-2.9-3.6v-8.8c0-4.4-2.2-6.9-5.9-6.9c-1.3,0-2.5,0.5-3.8,1.4
				c-1.1,0.8-2.1,1.5-3.1,2.1v-3.6c-2,0.9-4.2,1.4-6.3,1.8v1c2.5,0.5,2.7,0.6,2.7,3.6v9.4c0,3-0.2,3.2-3.3,3.6v1.2h9.9v-1.2
				c-2.7-0.3-3-0.5-3-3.6V29.3c1.4-1.2,3.2-2.3,5-2.3c3.1,0,4.2,2.1,4.2,5.2v7.6c0,3.2-0.3,3.5-3,3.8v1.2H303.6z M270.5,26
				c2.6,0,4.1,2.2,4.1,4.6c0,0.7-0.3,0.9-1.1,0.9c-2.6,0.1-5.2,0.1-7.8,0.1C266.4,27.9,268.4,26,270.5,26 M277.6,40
				c-1.7,1.6-3.4,2.3-5.3,2.3c-3.2,0-6.8-2.6-6.9-8.8c5.5-0.2,11.2-0.6,12-0.8c0.9-0.2,1.1-0.5,1.1-1.5c0-3.5-3.1-6.9-6.9-6.9h0
				c-2.3,0-4.8,1.3-6.7,3.3c-1.9,2.1-3,4.9-3,8c0,5.2,3.3,9.7,8.9,9.7c1.4,0,4.6-0.8,7.4-4.4L277.6,40z M259.4,44.8v-1.2
				c-2.6-0.2-3-0.5-3-3.5v-8c0-5.5-2.6-7.8-6.3-7.8c-2.5,0-4.9,1.7-6.5,3.3V12.3c-1.8,0.5-4.5,1.3-6.7,1.6V15c3,0.3,3.1,0.5,3.1,3.3
				v21.8c0,3-0.4,3.3-3.1,3.5l0,1.2h9.7v-1.2c-2.7-0.3-3-0.6-3-3.5V29.3c1.3-1.5,3-2.3,4.8-2.3c3.1,0,4.5,1.9,4.5,5.6v7.6
				c0,3-0.4,3.2-3,3.5v1.2H259.4z M234.8,39.8c-2.1,2-3.6,2.5-5.5,2.5c-2.6,0-6.9-2.1-6.9-8.5c0-4.8,2.6-7.3,5.6-7.3
				c1.6,0,3.2,0.6,4.6,1.9c0.4,0.4,0.6,0.5,0.9,0.5c0.5,0,1.5-1,1.5-2c0-0.5-0.1-0.9-0.7-1.4c-0.7-0.6-2.2-1.2-4.1-1.2h0
				c-1.7,0-4.4,0.8-6.8,2.6c-3.1,2.3-4.6,5.4-4.6,8.9c0,4.9,3.4,9.5,9.2,9.5c2.7,0,5.7-2,7.7-4.7L234.8,39.8z M216.2,44.8v-1.2
				c-2.9-0.3-3.2-0.5-3.2-3.7V24.2c-2,0.8-4.2,1.4-6.5,1.8v1.1c2.7,0.4,2.9,0.6,2.9,3.5V40c0,3.1-0.3,3.4-3.2,3.7v1.2H216.2z
			 	M210.9,20.4c1.3,0,2.3-1.1,2.3-2.3c0-1.4-1-2.4-2.3-2.4c-1.2,0-2.3,1-2.3,2.4C208.6,19.3,209.7,20.4,210.9,20.4 M189,27.8
				c0.8-0.5,2.3-0.9,3.6-0.9c3.8,0,6.9,3.1,6.9,8.2c0,5.3-2.4,8.5-6.2,8.5c-2.4,0-3.7-1.6-4-2.5c-0.2-0.5-0.3-0.9-0.3-1.6V27.8z
			 	M189,26.4V12.3c-1.7,0.6-4.5,1.3-6.6,1.6V15c2.9,0.3,3,0.5,3,3.4v25.4c2.5,1.2,4.8,1.6,6.7,1.6c6.1,0,11.5-4.8,11.5-11.4
				c0-5.4-4.1-9.6-9-9.6c-0.5,0-1.1,0.1-1.9,0.5L189,26.4z M173,26c2.6,0,4.1,2.2,4.1,4.6c0,0.7-0.3,0.9-1.1,0.9
				c-2.7,0.1-5.2,0.1-7.8,0.1C168.9,27.9,171,26,173,26 M180.1,40c-1.7,1.6-3.4,2.3-5.3,2.3c-3.2,0-6.8-2.6-6.9-8.8
				c5.5-0.2,11.2-0.6,12-0.8c0.9-0.2,1.1-0.5,1.1-1.5c0-3.5-3.1-6.9-6.9-6.9h0c-2.3,0-4.8,1.3-6.7,3.3c-1.9,2.1-3,4.9-3,8
				c0,5.2,3.3,9.7,8.9,9.7c1.4,0,4.6-0.8,7.4-4.4L180.1,40z M162,44.8v-1.2c-2.9-0.3-3.2-0.5-3.2-3.7V24.2c-2,0.8-4.2,1.4-6.5,1.8v1.1
				c2.7,0.4,2.9,0.6,2.9,3.5V40c0,3.1-0.3,3.4-3.2,3.7v1.2H162z M156.7,20.4c1.3,0,2.3-1.1,2.3-2.3c0-1.4-1-2.4-2.3-2.4
				c-1.2,0-2.3,1-2.3,2.4C154.4,19.3,155.5,20.4,156.7,20.4 M149.8,31h-11.6v1.3c4.5,0.3,4.8,0.6,4.8,3.9v3.9c0,1.9-0.5,2.5-1.5,3
				c-1,0.5-2.4,0.8-4,0.8c-8.8,0-12.7-7.7-12.7-14.3c0-8.4,5.1-13.6,12.1-13.6c4.4,0,7.8,1.5,8.9,7.1l1.3-0.3c-0.3-3-0.5-5.5-0.7-7.1
				c-1.5-0.3-5-1.3-8.8-1.3c-9,0-17.5,5.2-17.5,15.7c0,9.1,6.7,15.3,16.8,15.3c3.7,0,7.4-1,10.2-1.6c-0.3-1.3-0.3-2.7-0.3-4.3v-3.9
				c0-2.7,0.2-2.9,2.9-3.3V31z M97.5,45.3c2,0,3.3,0.1,4.5,0.6c1.3,0.5,2.1,1.7,2.1,3.2c0,2.8-2.2,5.1-6.6,5.1c-3.4,0-6-1.9-6-4.4
				c0-1.3,0.9-2.6,2.1-3.6C94.3,45.6,95.4,45.3,97.5,45.3 M96.5,25.7c2.7,0,4.3,2.6,4.3,6.1c0,3.6-1.8,5.4-3.8,5.4
				c-2.5,0-4.3-2.6-4.3-6.1C92.7,27.7,94.5,25.7,96.5,25.7 M107.6,24.9c-1.7,0.3-4,0.5-6.2,0.7c-1.4-0.8-2.9-1.2-4.4-1.2h0
				c-3.5,0-8.2,2.4-8.2,7.6c0,3.1,2,5.3,4,6.1c-0.7,0.9-2.2,2.1-4,2.8c0,0.5,0.1,1.3,0.5,1.9c0.6,1,1.7,2,3.4,2.5
				c-1.1,0.9-2.9,2.3-3.8,3.2c-0.7,0.7-1.1,1.6-1.1,2.6c0,2.6,2.8,5.6,7.9,5.6c6.1,0,11.4-4.4,11.4-9c0-4.5-3.7-5.4-6.5-5.4
				c-1.6,0-3.2,0-4.8,0c-2.1,0-2.9-0.9-2.9-1.7c0-0.6,0.8-1.5,1.4-1.9c0.7,0.1,1.6,0.2,2.3,0.2c3.9,0,8-2.4,8-7.3c0-1.6-0.5-3.1-1.4-4
				l2.6,0.1c0.7-0.4,1.6-1.6,2.1-2.3L107.6,24.9z M78.1,29.2v-5c-2.2,0.9-4.2,1.5-6.4,1.8v1c2.7,0.3,2.8,0.5,2.8,3.5v9.5
				c0,3-0.3,3.3-3.2,3.6v1.2h10.5v-1.2c-3.5-0.3-3.7-0.5-3.7-3.6v-8.4c1-2.6,2.5-3.9,3.7-3.9c0.7,0,1.3,0.3,2,0.9
				c0.3,0.3,0.6,0.3,1.2,0.1c0.9-0.4,1.5-1.3,1.5-2.2c0-1.1-1.1-2.3-2.7-2.3C81.6,24.3,79.6,26.9,78.1,29.2L78.1,29.2z M69.3,42.6
				l-1.2,0c-1.8-0.1-2.1-0.4-2.1-2.7V24.6c-0.7,0.1-1.8,0.3-3.1,0.5c-1.3,0.2-2.9,0.3-4.1,0.4v1l1.6,0.3c1.5,0.2,1.9,0.5,1.9,2.9v10.7
				c-1.6,1.5-3.3,2.2-4.9,2.2c-2.1,0-4-1.1-4-5.2v-6.2c0-3.1,0-5.2,0.1-6.6c-0.7,0.1-2,0.3-3.2,0.5c-1.2,0.1-2.3,0.3-3.3,0.3v1
				l1.3,0.3c1.1,0.3,1.5,0.5,1.5,2.9v9c0,4.9,2.6,6.8,5.7,6.8c1.2,0,2.2-0.4,3.5-1.1c1.3-0.8,2.3-1.6,3.4-2.4v3.2l0.3,0.3
				c0.9-0.2,2-0.6,3.2-0.9c1.2-0.3,2.5-0.6,3.5-0.7V42.6z M28.8,29.9h2.1c5.6,0,8.8,2.1,8.8,6.7c0,4.8-3.3,6.7-7,6.7
				c-3,0-3.8-0.9-3.8-4.2V29.9z M28.8,28.3v-9c0-1.5,0.1-2,0.6-2.3c0.4-0.3,1.5-0.5,2.6-0.5c4.3,0,6.1,2.7,6.1,5.8
				c0,3.8-2.1,5.8-7.1,5.8H28.8z M36.1,28.8c2.5-0.7,6.2-2.5,6.2-6.9c0-2.1-1-4.1-2.6-5.2c-1.4-1-3.4-1.6-6.9-1.6H21.1v1.3
				c3.7,0.3,3.8,0.5,3.8,4.3v18.5c0,3.8-0.3,4.1-4.2,4.3v1.3h10.1c3.6,0,6.6-0.5,9-1.8c2.7-1.5,4.3-3.8,4.3-6.9
				C44.1,31.5,40.6,29.5,36.1,28.8L36.1,28.8z"
				/>
			</g>
		</svg>
	);
}
