import type * as typo3 from "~/modules/typo3/schema";
import ContentHead from "~/components/shared/ContentHead";
import Slideshow from "~/components/Slideshow";
import { type ContentElementSize } from "~/components/content-elements/ContentElement";

export type ContentElementSlideshowProps = {
	data: typo3.ContentElementSlideshow;
	size: ContentElementSize;
};

export default function ContentElementSlideshow({
	data,
	size,
}: ContentElementSlideshowProps) {
	return (
		<div className={`works-showcase module ${size} hero`}>
			<ContentHead data={data} />

			<div className="inner">
				<div className="content">
					<Slideshow
						medias={data.content.images}
						size={size}
						mode="work-showcase"
					/>
				</div>
			</div>
		</div>
	);
}
