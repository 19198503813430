import { Link } from "@remix-run/react";

import type * as typo3 from "~/modules/typo3/schema";
import Swiper, {
	type SwiperPaginationOptions,
} from "~/components/shared/Swiper";
import Image from "~/components/shared/Image";
import ContentHead from "~/components/shared/ContentHead";
import type { PersonsPluginListProps } from "~/components/plugins/Persons";

const swiperPagination = {
	type: "bullets",
	clickable: true,
} as const satisfies SwiperPaginationOptions;

function NewsFacesPerson({ person }: { person: typo3.PersonCard }) {
	const content = (
		<div className="layout">
			<div className="container image">
				<div className="card-img">
					{person.image && (
						<Image
							image={person.image}
							widthPercentage={30}
							widthPercentageMobile={100}
						/>
					)}
				</div>
			</div>
			<div className="container title">
				<div className="card-title">{person.fullName}</div>
				<div className="card-meta">{person.job}</div>
			</div>
		</div>
	);

	return (
		<article className="teaser-card mode-person img-cover element">
			{person.detailsUrl ? (
				<Link to={person.detailsUrl} className="card-inner">
					{content}
				</Link>
			) : (
				content
			)}
		</article>
	);
}

export function NewFaces({ data, persons }: PersonsPluginListProps) {
	return (
		<div className="module new-faces">
			<div className="inner">
				<ContentHead data={data} />
				<Swiper
					swiperClassname="content slider"
					paginationPosition="end"
					pagination={swiperPagination}
					slidesPerView="auto"
					centeredSlides
					initialSlide={Math.floor(persons.length / 2)}
					spaceBetween={0}
				>
					{persons.map((person) => (
						<NewsFacesPerson person={person} key={person.uid} />
					))}
				</Swiper>
			</div>
		</div>
	);
}
