import { useEffect, type PropsWithChildren } from "react";
import useMeasure from "react-use-measure";
import { useMotionValue, motion, useTransform, useScroll } from "framer-motion";

import { cn } from "~/utils/cn";
import { useScrollContentContext } from "~/components/shared/ScrollContentMain";
import { useGlobal } from "~/context/global";

export type ScrollContentProps = PropsWithChildren<{
	/** @default true */
	isEnabled?: boolean;
	id?: string;
	className?: string;
}>;

export default function ScrollContent({
	isEnabled = true,
	className,
	children,
	...propRests
}: ScrollContentProps) {
	const { isMobile } = useGlobal();
	const { mainHeight } = useScrollContentContext();
	const [ref, { height: elemHeight }] = useMeasure();

	const { scrollY: scrollYMotionValue } = useScroll();
	const isSticky = useMotionValue(false);
	const yPos = useMotionValue(0);

	useEffect(() => {
		if (!isEnabled || isMobile) {
			return;
		}

		function updateScrollPosition() {
			const scrollY = scrollYMotionValue.get();
			const winHeight = window.innerHeight;

			const vw = Math.floor(window.innerWidth / 100);

			const headerHeight = Math.floor(vw * 8.6);
			// const paddingBottom = Math.floor(vw * 6);

			let scrollBreakpointMiddle = headerHeight;
			if (winHeight < elemHeight && elemHeight < mainHeight) {
				scrollBreakpointMiddle = elemHeight - winHeight;
			}

			const scrollBreakpointBottom = mainHeight - winHeight;

			if (scrollY <= scrollBreakpointMiddle) {
				// "top" part - breakpoint between scrolling and sticky (for the height of the header)
				isSticky.set(false);
				yPos.set(0);
			} else if (scrollY <= scrollBreakpointBottom) {
				// "middle" part
				if (mainHeight <= elemHeight) {
					// applies a custom scroll position mapping
					const scrollProgress =
						(scrollY - headerHeight) /
						(scrollBreakpointBottom - headerHeight);

					const scrollHeight = elemHeight - mainHeight;

					isSticky.set(false);
					yPos.set(-(scrollProgress * scrollHeight));
				} else {
					isSticky.set(true);
					if (winHeight < elemHeight) {
						yPos.set(-(elemHeight - winHeight));
					} else {
						yPos.set(-headerHeight);
					}
				}
			} else {
				// "bottom" part - breakpoint between sticky and scrolling again
				isSticky.set(false);

				if (winHeight < elemHeight) {
					yPos.set(-(elemHeight - mainHeight));
				} else {
					yPos.set(
						-(
							elemHeight -
							mainHeight -
							(elemHeight - winHeight) +
							headerHeight
						),
					);
				}
			}
		}

		updateScrollPosition();

		// needed for updating "vw"
		window.addEventListener("resize", updateScrollPosition, {
			passive: true,
		});
		const unsubscribeScrollChange = scrollYMotionValue.on(
			"change",
			updateScrollPosition,
		);
		return () => {
			window.removeEventListener("resize", updateScrollPosition);
			unsubscribeScrollChange();
		};
	}, [
		isEnabled,
		isMobile,
		mainHeight,
		elemHeight,
		yPos,
		isSticky,
		scrollYMotionValue,
	]);

	const position = useTransform(() =>
		isSticky.get() ? "fixed" : "absolute",
	);

	if (isEnabled && !isMobile)
		return (
			<motion.div
				{...propRests}
				ref={ref}
				className={cn("scroll-content", className)}
				style={
					isEnabled && !isMobile
						? {
								y: yPos,
								position,
							}
						: {}
				}
			>
				{children}
			</motion.div>
		);

	return (
		<div className={className} {...propRests}>
			{children}
		</div>
	);
}
