import { useCallback } from "react";
import { useIntl } from "react-intl";

import Fullscreen from "~/components/icons/Fullscreen";
import { useGlobal } from "~/context/global";

function CineModeToggle() {
	const { setGlobal } = useGlobal();
	const intl = useIntl();
	const title = intl.formatMessage({ id: "toolbar.toggleCineMode" });

	const handleClick = useCallback(() => {
		setGlobal((g) => {
			g.isInCinemode = !g.isInCinemode;
		});
	}, [setGlobal]);

	return (
		<button
			className="cinemode-trigger"
			aria-label={title}
			onClick={handleClick}
		>
			<Fullscreen label={title} />
			<span className="tooltip" aria-hidden="true">
				{title}
			</span>
		</button>
	);
}

export default CineModeToggle;
