import { FormattedMessage } from "react-intl";

import type * as typo3 from "~/modules/typo3/schema";
import EventCard from "~/components/shared/cards/EventCard";
import CalendarWrapper from "~/components/shared/calendar/CalendarWrapper";
import CalendarUnit from "~/components/shared/calendar/CalendarUnit";

type EventSearchDocument = typo3.SearchResultDocument & {
	type: "tx_nnbuhaarticlesandpersons_domain_model_article";
	data: typo3.ArticleCard;
};

const isEventSearchDocument = (
	document: typo3.SearchResultDocument,
): document is EventSearchDocument =>
	document.type === "tx_nnbuhaarticlesandpersons_domain_model_article" &&
	document.data !== null &&
	document.data.isEvent;

export type SearchResultsEventProps = {
	documents: Array<typo3.SearchResultDocument>;
	showEmptyError?: boolean;
};

export default function SearchResultsEvent({
	documents,
	showEmptyError,
}: SearchResultsEventProps) {
	if (documents.length === 0) {
		if (!showEmptyError) {
			return null;
		}

		// TODO: i18n
		return (
			<p>
				<FormattedMessage id="noResults" />
			</p>
		);
	}

	return (
		<CalendarWrapper isOverview>
			<CalendarUnit className="filter-hint pinned" title="Suchergebnis">
				{documents
					.filter(isEventSearchDocument)
					.map(({ data: article }) => (
						<EventCard
							key={article.uid}
							article={article}
							isOverview
						/>
					))}
			</CalendarUnit>
		</CalendarWrapper>
	);
}
