import { useMemo } from "react";

import { useGlobal } from "~/context/global";
import type * as typo3 from "~/modules/typo3/schema";
import { cmpByKey } from "~/utils/cmp";

export type ImageProps = {
	image: typo3.Media;
	/** @default "main" */
	sourceName?: typo3.ImageSourceName;
	/**
	 * Width relative to the width of the page, in percent.
	 * @default 100
	 */
	widthPercentage?: number;
	/**
	 * Width relative to the width of the page, only for mobile, in percent.
	 */
	widthPercentageMobile?: number;
	/** @default true */
	load?: boolean;
};

export default function Image({
	image,
	sourceName = "main",
	widthPercentage = 100,
	widthPercentageMobile,
	load = true,
}: ImageProps) {
	const { isMobile } = useGlobal();
	const { imageSources, properties } = image;

	const imgSrcs = imageSources[sourceName] ?? imageSources.main;
	const mainSrc = getMainImageSource(imgSrcs);

	/**
	 * The width breakpoint factor based on the device and percentage values provided.
	 */
	const widthBpFactor =
		widthPercentageMobile !== undefined && isMobile
			? 100 / widthPercentageMobile
			: 100 / widthPercentage;

	const srcSet = useMemo(
		() =>
			imgSrcs
				.sort(cmpByKey(({ width }) => width))
				.map(({ url, width }) => `${url} ${width * widthBpFactor}w`)
				.join(", "),
		[imgSrcs, widthBpFactor],
	);

	return (
		<img
			className={getImageFormat(mainSrc)}
			loading="lazy"
			src={load ? mainSrc.url : undefined}
			srcSet={load ? srcSet : undefined}
			alt={properties.alternative ?? ""}
			style={{
				aspectRatio: getImageSourceRatio(mainSrc),
			}}
		/>
	);
}

export function getMainImageSource(
	srcSizes: typo3.ImageSourceSizes,
): typo3.ImageSource {
	return srcSizes.reduce(
		(acc, curr) => (!acc || acc.width < curr.width ? curr : acc),
		srcSizes[0],
	);
}

export function getImageSourceRatio(src: typo3.ImageSource): number {
	return src.width / src.height;
}

export function getImageFormat(
	src: typo3.ImageSource,
): "portrait" | "landscape" | "square" {
	const ratio = getImageSourceRatio(src);
	if (ratio < 0.9) {
		return "portrait";
	} else if (ratio > 1.1) {
		return "landscape";
	} else {
		return "square";
	}
}
