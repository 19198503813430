import { useState } from "react";

import type * as typo3 from "~/modules/typo3/schema";
import { findFirstMediaByType } from "~/modules/typo3/media";
import TeaserCard from "~/components/shared/cards/TeaserCard";

type ContentElementSuperNewsProps = {
	data: typo3.ContentElementSupernews;
};

export default function ContentElementSuperNews({
	data,
}: ContentElementSuperNewsProps) {
	const [activeCardKey, setActiveCardKey] = useState(0);

	const layout = [
		[0, 3],
		[3, 6],
	] as const;

	const rows = layout.map(([start, end]) => data.supernews.slice(start, end));

	return (
		<div className="supernews module">
			{rows.map((row, rowIndex) => {
				if (!row.length) return null;

				return (
					<div key={rowIndex} className="inner layout-row">
						{row.map((entry, index) => {
							const cardKey = index;

							const video = findFirstMediaByType(
								entry.media,
								"video",
							);
							const image = video
								? undefined
								: findFirstMediaByType(entry.media, "image");

							return (
								<TeaserCard
									key={index}
									mode="supernews"
									image={image}
									imageSourceName="main"
									video={video}
									title={entry.header}
									label={
										<div
											dangerouslySetInnerHTML={{
												__html: entry.subheader,
											}}
										/>
									}
									imageStyle={
										entry.layout === "contain"
											? "contain"
											: "cover"
									}
									active={cardKey === activeCardKey}
									onMouseEnter={() =>
										setActiveCardKey(cardKey)
									}
									href={
										entry.headerLink === ""
											? "#"
											: entry.headerLink.href
									}
								/>
							);
						})}
					</div>
				);
			})}
		</div>
	);
}
