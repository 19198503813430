import { Link, useLocation } from "@remix-run/react";

import type * as typo3 from "~/modules/typo3/schema";
import useBackendLayoutLabel from "~/hooks/useBackendLayoutLabel";
import Return from "~/components/icons/Return";

type PageTitleProps = { page: typo3.Response };

function EssentialsPageTitle({ page }: PageTitleProps) {
	const path = useLocation().pathname;

	let subtitle = page.meta.subtitle;
	let title = page.meta.title;
	let backendLayout = page.appearance.backendLayout;
	let link: string | null = null;

	// For schwerpunkt pages, use the parent data
	const isSchwerpunkt =
		page.appearance.backendLayout === "schwerpunkt" &&
		["studiengang", "studienrichtung"].includes(
			page.meta.parentBackendLayout,
		);

	if (isSchwerpunkt) {
		title = page.meta.parentTitle;
		subtitle = page.meta.parentSubtitle;
		backendLayout = page.meta.parentBackendLayout;
		link = page.meta.parentLink;
	}

	const label = useBackendLayoutLabel(backendLayout);

	if (title.trim() === "") return null;

	const sitemap = page.content.colPos150?.find(
		(contentElement) => contentElement.type === "menu_sitemap_pages",
	) as typo3.ContentElementMenu | undefined;

	const content = (
		<>
			{page.meta.layout !== "10" && label && (
				<div className="label head top">{label}</div>
			)}
			<h2 className="title">{title}</h2>
			{subtitle && <div className="label meta bottom">{subtitle}</div>}
		</>
	);

	return (
		<header className="page-title essential-item essentials-header sticky top-0">
			{sitemap &&
				page.meta.parentTitle &&
				page.meta.parentLink &&
				page.meta.parentLink !== path && (
					<div className="button-return-container">
						<Link
							to={page.meta.parentLink}
							className="button--blank icon-button"
						>
							<Return />

							<span>{page.meta.parentTitle}</span>
						</Link>
					</div>
				)}

			<div className="inner">
				{link ? <Link to={link}>{content}</Link> : content}
			</div>
		</header>
	);
}

export default EssentialsPageTitle;
