import type { LinkProps } from "@remix-run/react";
import { Link } from "@remix-run/react";
import { useEffect, useRef, type ReactNode, useMemo } from "react";
import { useIntl } from "react-intl";

import type * as typo3 from "~/modules/typo3/schema";
import { cn } from "~/utils/cn";
import type { ScrollingToEvent } from "~/utils/events";
import { getQuasiEmpty } from "~/utils/typo3";
import usePageData from "~/hooks/usePageData";

type AnyContentElementType = typo3.AnyContentElement["type"];

type ContentElementTypeWithSectionLinks =
	(typeof contentElementsWithSectionLinks)[number];

const contentElementsWithSectionLinks = [
	"nnbuhaarticlesandpersons_articles",
	"nnbuhaarticlesandpersons_lehrangebote",
	"nnbuhaarticlesandpersons_lehrangeboteselected",
	"nnbuhaarticlesandpersons_projects",
	"nnbuhaarticlesandpersons_projectsselected",
	"nnbuhaarticlesandpersons_publications",
	"nnbuhaarticlesandpersons_publicationsselected",
] as const satisfies Array<AnyContentElementType>;

const isContentElementWithSectionLinks = (
	type: string,
): type is ContentElementTypeWithSectionLinks =>
	contentElementsWithSectionLinks.includes(
		type as ContentElementTypeWithSectionLinks,
	);

/*
 * This corresponds to k&r elements/content-head
 */

function Sub({
	type,
	isLabelHead = false,
	link,
	children,
}: {
	type: string;
	children: ReactNode;
	isLabelHead: boolean;
	link: string;
}) {
	const content =
		link.startsWith("/") && isContentElementWithSectionLinks(type) ? (
			<LinkWithPageSection to={link}>{children}</LinkWithPageSection>
		) : (
			<Link to={link}>{children}</Link>
		);

	return (
		<div className={cn("label sub", { head: isLabelHead })}>{content}</div>
	);
}

function appendSectionFilter(to: string, sectionTitle: string) {
	const url = new URL(to, "https://localhost/");
	if (!url.searchParams.has("tx_solr[filter][0]")) {
		url.searchParams.append(
			"tx_solr[filter][0]",
			`section:${sectionTitle}`,
		);
		return url.pathname + url.search;
	}

	return to;
}

function LinkWithPageSection({ to, ...props }: LinkProps) {
	const sectionCategory = usePageData()?.meta.sectionCategory;
	to = useMemo(
		() =>
			sectionCategory &&
			sectionCategory.id !== 1 &&
			sectionCategory.title &&
			typeof to === "string"
				? appendSectionFilter(to, sectionCategory.title)
				: to,
		[sectionCategory, to],
	);

	return <Link to={to} {...props} />;
}

type ContentHeadProps = {
	data: typo3.ContentElementWithHeading & { type?: string };
	rightContent?: ReactNode;
	mode?: "page" | "module" | "label";
	onScrollingTo?(): void;
};

function ContentHead({
	data,
	rightContent,
	mode = "module",
	onScrollingTo,
}: ContentHeadProps) {
	const intl = useIntl();
	const anchorRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const anchorElem = anchorRef.current;
		if (!anchorElem || !onScrollingTo) {
			return;
		}
		const onScrollingTo2 = onScrollingTo;

		function handleScrollingTo(_ev: ScrollingToEvent) {
			onScrollingTo2();
		}

		anchorElem.addEventListener("buha_scrollingTo", handleScrollingTo);
		return () => {
			anchorElem.removeEventListener(
				"buha_scrollingTo",
				handleScrollingTo,
			);
		};
	}, [onScrollingTo]);

	if (data.content.headerLayout >= 100) return null;

	const title = data.content.header.trim();
	if (title === "") return null;

	if (mode === "label") {
		return (
			<div className="content-head element mode-label">
				<h4 className="content-title">{title}</h4>
			</div>
		);
	}

	const subLayout = "vertical" as "vertical" | "horizontal"; // TODO: Receive as prop?

	const cta = "".trim(); // TODO: Receive as prop?
	const hasCta = cta !== "";

	const subtitle = data.content.subheader.trim();
	const hasSub = subtitle !== "";

	const headerLink = getQuasiEmpty(data.content.headerLink);
	const linkText: string =
		headerLink?.title ??
		headerLink?.linkText ??
		intl.formatMessage({ id: "more" });

	return (
		<div
			className={cn(
				"content-head element group/heading",
				`mode-${mode}`,
				{ module: mode === "page" },
			)}
			// data-id="<?= $id ?>" data-brief="<?= $brief ?>" data-nav="<?= $nav ?>"
		>
			<div
				ref={anchorRef}
				// anchor target, use slug instead?
				id={`c${data.id}`}
				// header height, TODO?!
				className="relative top-[-150px]"
			/>

			<div
				className={cn("title-wrapper", {
					"has-cta": hasCta,
					[`sub-layout-${subLayout}`]: headerLink,
				})}
			>
				{hasSub && (
					<div className="label head category">{subtitle}</div>
				)}

				<h2 className="content-title">
					{title}

					<a
						href={`#c${data.id}`}
						className="headline-link invisible group-hover/heading:visible"
						onClick={(e) => e.stopPropagation()}
					>
						#
						{/* FIXME Horrible a11y! Link label should say what it is */}
					</a>

					{rightContent}
				</h2>

				{cta && (
					<div className="cta">
						<button className="button">{cta}</button>
					</div>
				)}
			</div>

			{headerLink && (
				<div className="sub-title-content">
					{/* TODO: Handle multiple sub labels?! foreach ($sub as $i => $item) */}
					<Sub
						type={data.type ?? "default"}
						isLabelHead={
							linkText.startsWith("All") ||
							subLayout === "horizontal"
						}
						link={headerLink.href}
					>
						{linkText}
					</Sub>
				</div>
			)}
		</div>
	);
}

export default ContentHead;
