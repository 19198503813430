import type { ReactNode } from "react";

import { delay, spawnDetached } from "~/utils/async";
import { ScrollingToEvent } from "~/utils/events";

type Item = {
	anchor: string;
	label: ReactNode;
};

type EssentialsOnPageNavProps = {
	items: Array<Item>;
};

export default function EssentialsOnPageNav({
	items,
}: EssentialsOnPageNavProps) {
	return (
		<ul className="essential-item-content link-list jump-to-nav desktop">
			{items.map(({ anchor, label }) => (
				<li key={anchor}>
					<a
						href={`#${encodeURIComponent(anchor)}`}
						onClick={(e) => {
							spawnDetached(async () => {
								const target = document.getElementById(anchor);
								if (!target) {
									return;
								}

								e.preventDefault();

								// allow section to expand
								target.dispatchEvent(new ScrollingToEvent());

								// wait a bit
								await delay(100);

								// scroll to target
								// do not use old dom reference since it might've changed
								document
									.getElementById(anchor)
									?.scrollIntoView({
										behavior: "smooth",
									});
							});
						}}
					>
						{label}
					</a>
				</li>
			))}
		</ul>
	);
}
