import { Suspense, useMemo } from "react";
import { Await } from "@remix-run/react";
import { useIntl } from "react-intl";

import type { LoginStatus } from "~/modules/typo3/login";
import useLoginStatus from "~/hooks/useLoginStatus";
import LockOpen from "~/components/icons/LockOpen";
import LockClosed from "~/components/icons/LockClosed";

function LoginStatusInner({
	loginStatus,
}: {
	loginStatus: null | LoginStatus;
}) {
	const intl = useIntl();

	const username = loginStatus?.username;
	// empty string = not logged in
	const isLoggedIn = !!username;

	let title: string;
	if (loginStatus === null) {
		title = intl.formatMessage({ id: "loginStatus.loading" });
	} else {
		title = isLoggedIn
			? intl.formatMessage({ id: "loginStatus.loggedIn" }, { username })
			: intl.formatMessage({ id: "loginStatus.login" });
	}

	const feLoginUrl = useMemo(() => {
		const url = new URL(
			new URL(
				import.meta.env.VITE_FRONTEND_BASE ?? "http://localhost/",
			).origin,
		);
		url.pathname = "/login";
		url.searchParams.set(
			"redirect_url",
			intl.locale === "en" ? "/en/intranet" : "/intranet",
		);
		return String(url);
	}, [intl.locale]);

	if (isLoggedIn) {
		const logoutLink = `/login?${new URLSearchParams({
			logintype: "logout",
			redirect_url: "/",
		})}`;

		return (
			<a href={logoutLink} title={title}>
				<LockOpen label={title} />
				<span className="tooltip" aria-hidden="true">
					{title}
				</span>
			</a>
		);
	}

	return (
		<a
			href={`/Shibboleth.sso/Login?${new URLSearchParams({ target: feLoginUrl })}`}
			title={title}
		>
			<LockClosed label={title} />
			<span className="tooltip" aria-hidden="true">
				{title}
			</span>
		</a>
	);
}

export default function LoginStatus() {
	const loginStatusPromise = useLoginStatus();

	return (
		<Suspense fallback={<LoginStatusInner loginStatus={null} />}>
			<Await resolve={loginStatusPromise}>
				{(loginStatus) => (
					<LoginStatusInner loginStatus={loginStatus ?? null} />
				)}
			</Await>
		</Suspense>
	);
}
