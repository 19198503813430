import { Link } from "@remix-run/react";

import type * as typo3 from "~/modules/typo3/schema";
import { getFilteredMedia } from "~/modules/typo3/media";
import { cn } from "~/utils/cn";
import Image from "~/components/shared/Image";
import { renderProjectType } from "~/components/shared/ProjectType";

export type ProjectCardProps = {
	article: Pick<
		typo3.ProjectCard,
		| "title"
		| "detailsUrl"
		| "teasertext"
		| "relatedMedia"
		| "isGroupProject"
		| "students"
		| "projectType"
	>;
};

export default function ProjectCard({ article }: ProjectCardProps) {
	const { title, detailsUrl, teasertext, relatedMedia, isGroupProject } =
		article;

	const images = getFilteredMedia(relatedMedia, "image");
	const displayImages = isGroupProject
		? [
				images[0] ?? null,
				images[1] ?? images[0] ?? null,
				images[2] ?? images[1] ?? images[0] ?? null,
			]
		: [images[0] ?? null];
	const label = renderProjectType(article);

	return (
		<article
			className={cn("teaser-card mode-project element", {
				collection: isGroupProject,
			})}
		>
			<Link to={detailsUrl ?? "#"} className="block h-full w-full">
				<div className="buha-article__inner">
					<div className="images-wrapper">
						{displayImages.map((image, imageIndex) => (
							<div
								className="buha-article__image"
								key={imageIndex}
							>
								{image ? (
									<Image
										image={image}
										sourceName="thumbnail"
									/>
								) : (
									<div className="aspect-video w-full"></div>
								)}
							</div>
						))}
					</div>

					<div className="container title">
						{label && (
							<div className="card-label">
								<span>{label}</span>
							</div>
						)}

						<div className="card-title whitespace-pre-line">
							{title}
						</div>
						{/*
						   <?php if (isset($cardMeta) && !empty($cardMeta)) { ?>
							   <div class="card-meta"><?= $cardMeta ?></div>
						   <?php } ?>
					   	*/}

						<div className="card-teaser">{teasertext}</div>
					</div>
				</div>
			</Link>
		</article>
	);
}
