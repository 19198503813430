import { useIntl } from "react-intl";

const idMap = {
	studiengang: "sectionProjectsLabel.studiengang",
	schwerpunkt: "sectionProjectsLabel.schwerpunkt",
	studienrichtung: "sectionProjectsLabel.studienrichtung",
	einrichtung: "sectionProjectsLabel.einrichtung",
} as const;

export default function useBackendLayoutProjectsLabel(
	backendLayout: string,
	title: string,
) {
	const intl = useIntl();
	const id =
		backendLayout in idMap
			? idMap[backendLayout as keyof typeof idMap]
			: null;
	if (id) {
		return intl.formatMessage({ id }, { title });
	}

	return undefined;
}
