export type FileProps = {
	fileType: string;
	size?: "full" | "small";
};

export default function File({ fileType, size = "full" }: FileProps) {
	return (
		<div className={`icon file ${size}`}>
			<div className="shadow"></div>
			<div className="corner"></div>
			<span>{fileType}</span>
		</div>
	);
}
