import { useMemo } from "react";

import type * as typo3 from "~/modules/typo3/schema";
import useIsSearchActive from "~/hooks/useIsSearchActive";
import { extractItem } from "~/utils/array";
import CalendarOverviewNavigation from "~/components/shared/calendar/CalendarOverviewNavigation";
import ScrollContent from "~/components/shared/ScrollContent";
import ScrollContentMain from "~/components/shared/ScrollContentMain";
import EssentialsItem from "~/components/essentials/EssentialsItem";
import EssentialsPageTitle from "~/components/shared/EssentialsPageTitle";
import ContentElementsGroup from "~/components/content-elements/ContentElementsGroup";
import SolrPlugin from "~/components/plugins/solr/Solr";
import AppLayout from "~/components/shared/AppLayout";
import EditInCMSButton from "~/components/EditInCMSButton";
import { useEditPageLink } from "~/components/layouts/PageLayout";
import { useGlobal } from "~/context/global";

type CalendarLayoutProps = {
	page: typo3.Response;
};

export default function CalendarLayout({ page }: CalendarLayoutProps) {
	const { content } = page;
	const isSearchActive = useIsSearchActive();
	const [solrData, contentElements] = useMemo(
		() =>
			extractItem(
				content.colPos0,
				(contentElement): contentElement is typo3.ContentElementSolr =>
					contentElement.type === "solr_pi_results",
			),
		[content.colPos0],
	);
	const editLink = useEditPageLink(page);
	const { isMobile } = useGlobal();
	return (
		<AppLayout layout="calendar">
			<EditInCMSButton link={editLink} />

			<ScrollContentMain>
				<div className="column col-main">
					<div
						className={
							"main-content" +
							(isMobile ? " jump-to-nav mobile" : "")
						}
					>
						{solrData && (
							<SolrPlugin
								data={solrData}
								showResult={isSearchActive}
								showEmptyError={isSearchActive}
							/>
						)}
						{!isSearchActive && (
							<ContentElementsGroup
								elements={contentElements}
								size="full"
							/>
						)}
					</div>
				</div>

				<div className="column col-aside right">
					<div className="calendar-mini module">
						<ScrollContent className="calendar-mini-inner">
							<div className="calendar-mini-content">
								{solrData && (
									<CalendarOverviewNavigation
										solrData={solrData.content.data}
									/>
								)}
							</div>
						</ScrollContent>
					</div>
				</div>

				<div className="column col-aside left">
					<div className="essentials module">
						<ScrollContent className="essentials-inner">
							<EssentialsPageTitle page={page} />

							<div className="essentials-content">
								{content.colPos150?.map((contentElement) => (
									<EssentialsItem
										key={contentElement.id}
										data={contentElement}
									/>
								))}
							</div>
						</ScrollContent>
					</div>
				</div>
			</ScrollContentMain>
		</AppLayout>
	);
}
