import { Fragment, useCallback, type ComponentType } from "react";
import { Link } from "@remix-run/react";
import { useIntl } from "react-intl";

import EnergySlider from "~/components/EnergySlider";
import Search from "~/components/icons/Search";
import Location from "~/components/icons/Location";
import LanguageSwitcher from "~/components/shared/LanguageSwitcher";
import LoginStatus from "~/components/shared/LoginStatus";
import { useGlobal } from "~/context/global";
import { cn } from "~/utils/cn";
import { unreachableValue } from "~/utils/unreachable";
import { type MessageIds } from "~/lang";
import CineModeToggle from "~/components/shared/CineModeToggle";
import { type IconProps } from "~/components/icons";
import useLanguage from "~/hooks/useLanguage";

type BaseItemProps = {
	title: MessageIds;
	link: {
		de: string;
		en: string;
	};
	linkClassName?: string;
};

export type ItemLink = BaseItemProps & {
	type: "link";
	highlight?: boolean;
};

export type ItemIconLink = BaseItemProps & {
	type: "iconLink";
	icon: ComponentType<IconProps>;
	hiddenOnMobile?: boolean;
};

export type Item =
	| { type: "group"; groupName: string; items: Array<Item> }
	| { type: "languageSwitcher" }
	| { type: "energySlider" }
	| { type: "loginStatus" }
	| { type: "cineModeToggle" }
	| { type: "toolsToggle" }
	| ItemLink
	| ItemIconLink;

export const SEARCH_ITEM: Item = {
	type: "iconLink",
	linkClassName: "toolbar-overlay-triggerr",
	icon: Search,
	title: "toolbar.search",
	link: {
		de: "/suche/",
		en: "/en/search/",
	},
};

const ITEMS: Array<Item> = [
	{
		type: "group",
		groupName: "left",
		items: [
			{ type: "languageSwitcher" },
			{
				type: "link",
				highlight: true,
				title: "toolbar.application",
				link: {
					de: "/bewerben/",
					en: "/en/apply/",
				},
			},
			{
				type: "link",
				title: "toolbar.Lehrangebote",
				link: {
					de: "/lehrangebote/",
					en: "/en/courses/",
				},
			},
			{
				type: "link",
				title: "toolbar.Studienarbeiten",
				link: {
					de: "/projekte/",
					en: "/en/projects/",
				},
			},
		],
	},

	{
		type: "group",
		groupName: "center",
		items: [{ type: "energySlider" }],
	},

	{
		type: "group",
		groupName: "right",
		items: [
			{
				type: "link",
				title: "toolbar.media",
				link: {
					de: "/medien/",
					en: "/en/media/",
				},
			},
			{
				type: "link",
				title: "toolbar.press",
				link: {
					de: "/hochschule/presse/",
					en: "/en/university/press/",
				},
			},
			{ type: "toolsToggle" },

			{
				type: "group",
				groupName: "icons",
				items: [
					{
						type: "iconLink",
						linkClassName: "location-overlay-trigger",
						icon: Location,
						title: "toolbar.map",
						link: {
							de: "/hochschule/kontakt/anfahrtsbeschreibungen-lageplaene/",
							en: "/en/hochschule/kontakt/anfahrtsbeschreibungen-lageplaene/",
						},
					},
					SEARCH_ITEM,
					{ type: "loginStatus" },
					{ type: "cineModeToggle" },
				],
			},
		],
	},
];

export default function Toolbar() {
	const { isMobile } = useGlobal();

	if (isMobile) {
		return ITEMS.map((item, itemIndex) => (
			<Fragment key={itemIndex}>{renderMobileItem(item)}</Fragment>
		));
	} else {
		return (
			<div id="toolbar" className="module">
				<div className="inner-wrapper">
					{ITEMS.map((item, itemIndex) => (
						<Fragment key={itemIndex}>
							{renderDesktopItem(item)}
						</Fragment>
					))}
				</div>
			</div>
		);
	}
}

function renderDesktopItem(item: Item) {
	switch (item.type) {
		case "link":
			return <ItemLink {...item} />;

		case "group":
			return (
				<div className={cn("toolbar-item-group", item.groupName)}>
					{item.items.map((child, childIndex) => (
						<Fragment key={childIndex}>
							{renderDesktopItem(child)}
						</Fragment>
					))}
				</div>
			);

		case "energySlider":
			return (
				<div className="toolbar-item">
					<EnergySlider />
				</div>
			);

		case "languageSwitcher":
			return (
				<div className="toolbar-item">
					<LanguageSwitcher />
				</div>
			);

		case "iconLink":
			return (
				<div className="toolbar-item icon-button">
					<ItemIconLink {...item} />
				</div>
			);

		case "loginStatus":
			return (
				<div className="toolbar-item icon-button">
					<LoginStatus />
				</div>
			);

		case "cineModeToggle":
			return (
				<div className="toolbar-item icon-button">
					<CineModeToggle />
				</div>
			);

		case "toolsToggle":
			return (
				<div className="toolbar-item">
					<ToolsToggle />
				</div>
			);

		default:
			unreachableValue(item);
	}
}

export function renderMobileItem(item: Item) {
	switch (item.type) {
		case "link":
			return <ItemLink {...item} />;

		case "group":
			return item.items.map((child, childIndex) => (
				<Fragment key={childIndex}>{renderMobileItem(child)}</Fragment>
			));

		case "energySlider":
			return null;

		case "languageSwitcher":
			return <LanguageSwitcher />;

		case "iconLink":
			if (!item.hiddenOnMobile) {
				return <ItemIconLink {...item} />;
			}
			return null;

		case "loginStatus":
			return <LoginStatus />;

		case "cineModeToggle":
			return <CineModeToggle />;

		case "toolsToggle":
			return <ToolsToggle />;

		default:
			unreachableValue(item);
	}
}

function ItemIconLink(item: ItemIconLink) {
	const intl = useIntl();
	const title = intl.formatMessage({ id: item.title });
	const lang = useLanguage();

	return (
		<Link
			to={item.link[lang]}
			className={item.linkClassName}
			aria-label={title}
		>
			<item.icon label={title} />
			<span className="tooltip" aria-hidden="true">
				{title}
			</span>
		</Link>
	);
}

function ItemLink(item: ItemLink) {
	const { isMobile } = useGlobal();
	const intl = useIntl();
	const title = intl.formatMessage({ id: item.title });
	const lang = useLanguage();

	if (isMobile) {
		return (
			<Link to={item.link[lang]} aria-label={title}>
				<span>{title}</span>
			</Link>
		);
	}

	return (
		<div
			className={cn("toolbar-item", item.linkClassName, {
				highlight: item.highlight,
			})}
		>
			<Link to={item.link[lang]} aria-label={title}>
				<span>{title}</span>
			</Link>
		</div>
	);
}

function ToolsToggle() {
	const intl = useIntl();
	const title = intl.formatMessage({ id: "toolbar.tools" });
	const { openToolbarOverlay, setGlobal } = useGlobal();

	const handleClick = useCallback(() => {
		setGlobal((g) => {
			if (g.openToolbarOverlay === "tools") {
				g.openToolbarOverlay = null;
			} else {
				g.openToolbarOverlay = "tools";
			}
		});
	}, [setGlobal]);

	return (
		<button
			onClick={handleClick}
			className={cn("toolbar-overlay-trigger", {
				active: openToolbarOverlay === "tools",
			})}
			aria-label={title}
		>
			<span>{title}</span>
		</button>
	);
}
