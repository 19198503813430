export default function Return() {
	return (
		<div className="icon return">
			<svg
				width="72"
				height="100"
				viewBox="0 0 72 100"
				fill="none"
				strokeWidth="4"
			>
				<path d="M16 50L56 50" />
				<path d="M36 32L16 50L36 68" />
			</svg>
		</div>
	);
}
