import { type ForwardedRef, forwardRef, type PropsWithChildren } from "react";
import useMeasure from "react-use-measure";

import { makeArray } from "~/utils/array";

export default function Marquee({ children }: PropsWithChildren) {
	const [containerRef, { width: containerWidth }] = useMeasure();
	const [unitRef, { width: unitWidth }] = useMeasure();

	const animationDuration = unitWidth / 10;

	return (
		<div className="marquee marquee-small">
			<div ref={containerRef} className="container">
				<Unit ref={unitRef} animationDuration={animationDuration}>
					{children}
				</Unit>
				{makeArray(Math.ceil(containerWidth / unitWidth) + 1, (i) => (
					<Unit key={i} animationDuration={animationDuration}>
						{children}
					</Unit>
				))}
			</div>
		</div>
	);
}

const Unit = forwardRef(function Unit(
	{
		children,
		animationDuration,
	}: PropsWithChildren<{ animationDuration: number }>,
	ref: ForwardedRef<HTMLDivElement>,
) {
	return (
		<div
			ref={ref}
			className="unit"
			style={{ animationDuration: `${animationDuration}s` }}
		>
			<span className="dot">⬤</span>
			<span className="content">{children}</span>
		</div>
	);
});
