import { FormattedMessage, useIntl } from "react-intl";

import { solrTermFilterGetParameter } from "~/modules/typo3/solr";
import Search from "~/components/icons/Search";

export type SearchBarSimpleProps = {
	searchTerm: string;
	setSearchTerm(term: string): void;
	onSubmit?(): void;
};

export default function SearchBarSimple({
	searchTerm,
	setSearchTerm,
	onSubmit,
}: SearchBarSimpleProps) {
	const intl = useIntl();

	return (
		<div className="element search-bar has-suggestions">
			<div className="content">
				<div className="form large">
					<label className="id">
						<span className="title">
							<FormattedMessage id="search" />
						</span>

						<input
							type="text"
							inputMode="text"
							autoComplete="on"
							placeholder={intl.formatMessage({ id: "search" })}
							name={solrTermFilterGetParameter}
							value={searchTerm}
							onChange={(ev) =>
								setSearchTerm(ev.currentTarget.value)
							}
						/>

						<button
							type={onSubmit === undefined ? "submit" : "button"}
							onClick={onSubmit}
							className="search-submit"
						>
							<Search
								label={intl.formatMessage({ id: "search" })}
							/>
						</button>
					</label>
				</div>
			</div>
		</div>
	);
}
