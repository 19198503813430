import type * as typo3 from "~/modules/typo3/schema";
import TeaserCard from "~/components/shared/cards/TeaserCard";

type PublicationCardProps = {
	article: typo3.ArticleCard;
};

export default function PublicationCard({ article }: PublicationCardProps) {
	return (
		<TeaserCard
			key={article.uid}
			mode="lehrangebot"
			title={article.title}
			image={article.relatedMedia[0]}
			imageSourceName="thumbnailFree"
			imageStyle="free"
			href={article.detailsUrl}
			meta={
				<>
					<span>
						{article.relatedPersons
							.map(({ fullName }) => fullName)
							.join(", ")}
					</span>
				</>
			}
		/>
	);
}
