import type * as typo3 from "~/modules/typo3/schema";
import BodyText from "~/components/shared/BodyText";
import ContentHead from "~/components/shared/ContentHead";
import { tableHeaderPosition } from "~/modules/typo3/schema";

type ContentElementTableProps = {
	data: typo3.ContentElementTable;
};

export default function ContentElementTable({
	data,
}: ContentElementTableProps) {
	const head = [];
	const body = [...data.content.bodytext];
	const headerPosition = data.content.tableHeaderPosition;
	if (body[0] && headerPosition === tableHeaderPosition.top) {
		head.push(body[0]);
		body.shift();
	}

	return (
		<section className="text module">
			<ContentHead data={data} />
			<div className="text-container">
				<div className="inner">
					<BodyText>
						<table className="w-full">
							<thead className="font-bold">
								{head.map((row, i) => (
									<tr key={i}>
										{row.map((cell, j) => (
											<th
												className="text-left"
												scope="col"
												key={j}
											>
												{cell}
											</th>
										))}
									</tr>
								))}
							</thead>

							<tbody>
								{body.map((row, i) => (
									<tr key={i}>
										{row.map((cell, j) =>
											j === 0 &&
											headerPosition ===
												tableHeaderPosition.left ? (
												<th
													className="font-bold"
													scope="row"
													key={j}
												>
													{cell}
												</th>
											) : (
												<td key={j}>{cell}</td>
											),
										)}
									</tr>
								))}
							</tbody>
						</table>
					</BodyText>
				</div>
			</div>
		</section>
	);
}
