import { useLocation, useSearchParams } from "@remix-run/react";

import {
	solrFacetFilterGetParameterRegex,
	solrTermFilterGetParameter,
} from "~/modules/typo3/solr";

import SearchBarForm from "./SearchBarForm";

export default function SearchBar() {
	const location = useLocation();
	const [searchParams] = useSearchParams();

	const initialSearchTermEntry = Array.from(searchParams.entries()).find(
		([key]) => key === solrTermFilterGetParameter,
	);

	const hiddenFields = Array.from(searchParams.entries()).filter(
		([key]) => key.match(solrFacetFilterGetParameterRegex) !== null,
	);

	return (
		<SearchBarForm
			formAction={location.pathname}
			initialSearchTerm={initialSearchTermEntry?.[1]}
			hiddenFields={hiddenFields}
		/>
	);
}
