import { Suspense } from "react";
import { Await } from "@remix-run/react";

import type * as typo3 from "~/modules/typo3/schema";
import { isVideo } from "~/modules/typo3/media";
import { type VimeoVideoData, getVimeoVideoIdFromUrl } from "~/utils/vimeo";
import * as nonNull from "~/utils/nonNullable";
import { pipe } from "~/utils/function";
import MediaCard, {
	type MediaCardSize,
} from "~/components/shared/cards/MediaCard";
import { usePageVimeoVideoData } from "~/hooks/usePageData";

export type VideoCardProps = {
	size: MediaCardSize;
	article: typo3.ArticleCard;
};

export default function VideoCard(props: VideoCardProps) {
	const vimeoDataByIdPromise = usePageVimeoVideoData();

	return (
		<Suspense fallback={<VideoCardInner {...props} />}>
			<Await resolve={vimeoDataByIdPromise}>
				{(vimeoDataById) => (
					<VideoCardInner
						vimeoDataById={vimeoDataById ?? undefined}
						{...props}
					/>
				)}
			</Await>
		</Suspense>
	);
}

function VideoCardInner({
	article,
	size,
	vimeoDataById,
}: VideoCardProps & { vimeoDataById?: Record<number, VimeoVideoData> }) {
	const video = article.relatedMedia.find(isVideo);
	if (!video) {
		return null;
	}

	const vimeoVidId = getVimeoVideoIdFromUrl(video.properties.originalUrl);
	const vimeoData =
		vimeoVidId !== undefined ? vimeoDataById?.[vimeoVidId] : undefined;

	return (
		<MediaCard
			size={size}
			title={video.properties.title}
			date={pipe(
				article.pubDate ?? vimeoData?.uploadDate,
				nonNull.tryMap((date) => new Date(date)),
			)}
			media={video}
			duration={vimeoData?.duration ?? undefined}
			link={article.detailsUrl}
			author={article.relatedPersons[0]?.fullName ?? undefined}
			category={article.sectionCategories[0]?.title ?? undefined}
		/>
	);
}
