import { type PropsWithChildren } from "react";

export type SearchMarkerProps = PropsWithChildren & {
	inverted?: boolean;
};

export default function SearchMarker({
	children,
	inverted = false,
}: SearchMarkerProps) {
	const begin = <HTMLComment text="TYPO3SEARCH_begin" />;
	const end = <HTMLComment text="TYPO3SEARCH_end" />;

	return (
		<>
			{inverted ? end : begin}
			{children}
			{inverted ? begin : end}
		</>
	);
}

function HTMLComment({ text }: { text: string }) {
	return (
		<i
			hidden={true}
			dangerouslySetInnerHTML={{
				__html: `<!--${text}-->`,
			}}
		/>
	);
}
