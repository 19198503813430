import CineModeToggle from "~/components/shared/CineModeToggle";
import { useGlobal } from "~/context/global";

export default function HeaderCinemode() {
	const { isInCinemode } = useGlobal();

	if (!isInCinemode) {
		return null;
	}

	return (
		<header className="header-cinemode">
			<div className="inner-wrapper">
				<div className="page-title">
					{/* TODO */}
					<span>$title</span>
					&middot;
					<span>$label</span>
				</div>
				<div className="item icon-button">
					<CineModeToggle />
				</div>
			</div>
		</header>
	);
}
