import { Link } from "@remix-run/react";

import { useShowConsentModal } from "~/components/ConsentModal";
import FooterIcon from "~/components/icons/FooterIcon";
import { NewsletterSignupForm } from "~/components/shared/NewsletterSignupForm";
import FooterSocial from "~/components/shared/FooterSocial";
import { isMenuContentElement } from "~/modules/typo3/schema";
import type * as typo3 from "~/modules/typo3/schema";
import { useMainNavigationData } from "~/hooks/useMainNavigationData";

const TEXT_CLASSES =
	"uppercase tracking-[0.12em] text-[0.235rem] leading-[1.15] [word-spacing:-0.35em]";

type FooterMenuLinkItemProps = {
	linkItem: typo3.NavigationItem;
};

function FooterMenuLinkItem({ linkItem }: FooterMenuLinkItemProps) {
	const showConsentModal = useShowConsentModal();

	let content;

	if (linkItem.link !== undefined && linkItem.link.startsWith("/")) {
		content = (
			<Link to={linkItem.link} className={`hover:text-link`}>
				{linkItem.title}
			</Link>
		);
	} else if (linkItem?.link === "onClick:showConsentModal") {
		content = (
			<button
				onClick={showConsentModal}
				className={`hover:text-link ${TEXT_CLASSES}`}
			>
				{linkItem.title}
			</button>
		);
	} else {
		content = linkItem?.title;
	}

	return <li>{content}</li>;
}

type LinkSectionProps = {
	colPos: number;
};

function LinkSection({ colPos }: LinkSectionProps) {
	const allCes = useMainNavigationData()[`colPos${colPos}`];

	const items = allCes?.filter(isMenuContentElement);

	return (
		<div className="footer-menu-section">
			<div className="footer-menu-section">
				{items?.map((item, index: number) => (
					<div key={index}>
						<div className="footer-menu-section-header">
							{item.content.header}
						</div>
						<ul>
							{item.content.menu.map((linkItem, index) => (
								<FooterMenuLinkItem
									key={index}
									linkItem={linkItem}
								/>
							))}
						</ul>
					</div>
				))}
			</div>
		</div>
	);
}

export default function Footer() {
	return (
		<footer id="page-footer" className="module">
			<div className="grid">
				<div id="logo" className="footer-item element">
					<div className="logo-inner">
						<Link
							id="footer-logo"
							to="/"
							className="flex w-[8rem] shrink-0 grow-0 content-center"
						>
							<FooterIcon />
						</Link>
					</div>
				</div>

				<FooterSocial />

				<div className="element newsletter-signup">
					<NewsletterSignupForm />
				</div>

				<div className="footer-menu element">
					<nav>
						<LinkSection colPos={1111} />
						<LinkSection colPos={1121} />
						<LinkSection colPos={1131} />
					</nav>
				</div>
			</div>
		</footer>
	);
}
