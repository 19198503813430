import { FormattedDate, FormattedTime } from "react-intl";

import { formatDateForTimeElement } from "~/utils/time";

export type DateProps = {
	date: Date;
	/** @default false */
	weekday?: boolean;
	/** @default false */
	year?: boolean | "2-digit";
	/** @default false */
	time?: boolean;
};

export default function Date({
	date,
	weekday = false,
	year = false,
	time = false,
}: DateProps) {
	return (
		<time
			className="date"
			dateTime={formatDateForTimeElement(date, { time })}
		>
			{weekday && (
				<span className="date-let">
					<FormattedDate value={date} weekday="short" />
				</span>
			)}

			<span className="date-num">
				<FormattedDate
					value={date}
					year={year ? (year !== true ? year : "numeric") : undefined}
					month="2-digit"
					day="2-digit"
				/>
			</span>

			{time && (
				<span className="time">
					<FormattedTime
						value={date}
						hour="2-digit"
						minute="2-digit"
					/>
				</span>
			)}
		</time>
	);
}
