import { useSearchParams } from "@remix-run/react";

export default function useIsSearchActive(prefix: string = "tx_solr"): boolean {
	const [params] = useSearchParams();
	return Array.from(params.keys()).some(
		(key) =>
			key.startsWith(`${prefix}[q]`) ||
			key.startsWith(`${prefix}[filter][`),
	);
}
