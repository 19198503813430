import type * as typo3 from "~/modules/typo3/schema";
import HorizontalScrollCardContainer from "~/components/shared/HorizontalScrollCardContainer";
import TeaserCard from "~/components/shared/cards/TeaserCard";
import { FormattedDate, FormattedTime } from "react-intl";
import { capitalize } from "lodash-es";
import { Link } from "@remix-run/react";

type RowsProps = {
	layout: "rows";
	rows?: number;
	cols?: number;
};

type TableProps = {
	layout: "table";
};

type SharedProps = {
	articles: Array<typo3.ArticleCard>;
};

export type NewsProps = (RowsProps | TableProps) & SharedProps;

export type NewsRowProps = {
	article: typo3.ArticleCard;
};

function NewsRow({ article }: NewsRowProps) {
	const startDate =
		article.eventDate == undefined ? null : new Date(article.eventDate);

	const tag =
		article.articleSubtypeCategory?.title !== "-"
			? "&nbsp;" + capitalize(article.articleSubtypeCategory?.title)
			: "";

	return (
		<Link className="item" key={article.uid} to={article.detailsUrl ?? "#"}>
			<div className="title">{article.title}</div>
			<div className="published label">
				<FormattedDate
					value={article.pubDate}
					month="numeric"
					day="numeric"
					year="numeric"
				/>
				{tag}
			</div>
			{startDate && (
				<div className="date">
					<FormattedDate
						value={startDate}
						month="numeric"
						day="numeric"
						year="numeric"
					/>
					<br />
					{article.eventStartEndtime}
				</div>
			)}
		</Link>
	);
}

export default function News(props: NewsProps) {
	if (props.layout === "rows") {
		const { cols = 10, rows = 3 } = props;

		const allArticles = [...props.articles];
		const itemsInRows: Array<Array<typo3.ArticleCard>> = [];
		while (allArticles.length > 0 && itemsInRows.length < rows) {
			itemsInRows.push(allArticles.splice(0, cols));
		}

		return (
			<HorizontalScrollCardContainer
				mode="news"
				rows={itemsInRows}
				renderItem={renderNewsCard}
			/>
		);
	}

	if (props.layout === "table") {
		return (
			<div className="wrapper">
				<div className="content">
					{props.articles.map((article) => (
						<NewsRow article={article} key={article.uid} />
					))}
				</div>
			</div>
		);
	}

	return null;
}

function renderNewsCard(article: typo3.ArticleCard) {
	return (
		<TeaserCard
			mode="news"
			className="small-basic"
			label={article.articleSubtypeCategory?.title}
			href={article.detailsUrl ?? ""}
			image={article.relatedMedia[0]}
			title={article.title}
		/>
	);
}
