import { Fragment, useCallback, useEffect } from "react";
import { DialogTrigger, Button } from "react-aria-components";
import { AnimatePresence } from "framer-motion";
import { FocusOn } from "react-focus-on";
import { useIntl } from "react-intl";

import MainNavSub from "~/components/shared/MainNavSub";
import { useGlobal } from "~/context/global";
import type { RgbColor } from "~/utils/colors";
import { COLORS, adjustColor } from "~/utils/colors";
import { mainNavItems, mainNavItemsById } from "~/config";
import { type NavState, type NavAction } from "~/components/shared/MainHeader";

type MainNavTopLinkProps = {
	id: string;
	isOpen: boolean;
	dispatch(action: NavAction): void;
	setSubNavColor(color?: RgbColor): void;
};

function MainNavTopLink({
	id,
	isOpen,
	setSubNavColor,
	dispatch,
}: MainNavTopLinkProps) {
	const { energySliderValue, isMobile } = useGlobal();
	const handleHoverStart = useCallback(
		() => dispatch({ type: "mouseEnter", data: { id } }),
		[dispatch, id],
	);
	const handleClick = useCallback(
		() => dispatch({ type: "click", data: { id } }),
		[dispatch, id],
	);

	useEffect(() => {
		if (isOpen) {
			const color = COLORS[`nav-bg-${id}`];
			if (color) {
				setSubNavColor(adjustColor(energySliderValue, color));
			}
		}
	}, [isOpen, energySliderValue, id, setSubNavColor]);

	const intl = useIntl();

	const item = mainNavItemsById[id];
	if (!item) {
		return null;
	}

	const label = intl.formatMessage({ id: `mainNav.${item.id}` });

	return (
		<Button
			onHoverStart={!isMobile ? handleHoverStart : undefined}
			onPress={handleClick}
			className={`nav-top-link ${isOpen ? "active" : ""}`}
			aria-label={label}
		>
			<span className="title">{label}</span>
		</Button>
	);
}

type MainNavProps = {
	setSubNavColor(color?: RgbColor): void;
	state: NavState;
	dispatch(action: NavAction): void;
};

function MainNav({ setSubNavColor, state, dispatch }: MainNavProps) {
	const { isMobile } = useGlobal();

	useEffect(() => {
		if (!state.open) {
			setSubNavColor(undefined);
			// TODO: Restart autoplay video (depending on energy saving mode)
		}
	}, [state.open, setSubNavColor]);

	const forceClose = useCallback(
		() => dispatch({ type: "forceClose" }),
		[dispatch],
	);

	const handleMouseLeave = useCallback(
		() => dispatch({ type: "mouseLeave" }),
		[dispatch],
	);

	if (isMobile) {
		return mainNavItems.map((itemGroup, groupIndex) => (
			<Fragment key={groupIndex}>
				{itemGroup.map((item) => (
					<MainNavTopLink
						key={item.id}
						setSubNavColor={setSubNavColor}
						isOpen={
							state.open !== null && state.open.id === item.id
						}
						id={item.id}
						dispatch={dispatch}
					/>
				))}
			</Fragment>
		));
	}

	return (
		<FocusOn
			enabled={state.open !== null}
			onClickOutside={forceClose}
			onEscapeKey={forceClose}
			scrollLock={false}
		>
			<nav
				id="main-nav"
				className="module main-nav"
				onMouseLeave={handleMouseLeave}
			>
				<DialogTrigger isOpen={state.open !== null}>
					<div className="main-nav-top">
						<div className="inner-wrapper">
							{mainNavItems.map((itemGroup, groupIndex) => (
								<ul key={groupIndex} className="group">
									{itemGroup.map((item) => (
										<li key={item.id}>
											<MainNavTopLink
												setSubNavColor={setSubNavColor}
												isOpen={
													state.open !== null &&
													state.open.id === item.id
												}
												id={item.id}
												dispatch={dispatch}
											/>
										</li>
									))}
								</ul>
							))}
						</div>
					</div>

					<AnimatePresence>
						{state.open && (
							<MainNavSub
								openState={state.open}
								onLinkClick={forceClose}
							/>
						)}
					</AnimatePresence>
				</DialogTrigger>
			</nav>
		</FocusOn>
	);
}

export default MainNav;
