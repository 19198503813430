import { useEffect, useState } from "react";

export type UseWindowDimensionsResult = null | {
	width: number;
	height: number;
};

export default function useWindowDimensions(): UseWindowDimensionsResult {
	const [dimensions, setDimensions] =
		useState<UseWindowDimensionsResult>(null);

	useEffect(() => {
		const handleWindowResize = () => {
			setDimensions({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		};

		handleWindowResize();

		window.addEventListener("resize", handleWindowResize);
		return () => {
			window.removeEventListener("resize", handleWindowResize);
		};
	}, []);

	return dimensions;
}
