import { Form, useActionData } from "@remix-run/react";
import {
	type HTMLInputTypeAttribute,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
	type PropsWithChildren,
} from "react";
import { FormattedMessage } from "react-intl";

import * as typo3 from "~/modules/typo3/schema";
import ContentHead from "~/components/shared/ContentHead";
import { type action } from "~/routes/$";
import { unreachableValue } from "~/utils/unreachable";
import { pipe } from "~/utils/function";
import * as nonNull from "~/utils/nonNullable";
import { cn } from "~/utils/cn";
import BodyText from "~/components/shared/BodyText";

function RequiredMark({ element }: { element: typo3.form.Element }) {
	if (getValidator("NotEmpty", element)) {
		return <span className="required">*</span>;
	}
}

function InputElementWrapper({
	element,
	errors,
	children,
	showLabel = true,
}: PropsWithChildren<{
	element: typo3.form.Element;
	errors: null | Record<string, string>;
	/** @default true */
	showLabel?: boolean;
}>) {
	const errorMessage = errors?.[element.identifier];

	return (
		<div className={cn("form-group", { "with-label": showLabel })}>
			{showLabel && (
				<label htmlFor={element.identifier}>
					{element.label}
					<RequiredMark element={element} />
				</label>
			)}

			{children}

			{errorMessage && <div className="text-red-600">{errorMessage}</div>}
			{/*	<pre>
			{JSON.stringify(
				{
					validators: element.validators,
					properties: element.properties,
					},
					null,
					2,
				)}
			</pre>*/}
		</div>
	);
}

function mapAdditionalAttributes(
	element: typo3.form.Element,
	opts: { required?: boolean } = {},
) {
	return {
		required:
			opts.required !== false &&
			getValidator("NotEmpty", element) !== undefined,
		...pipe(
			getValidator("StringLength", element),
			nonNull.map(({ options }) => ({
				minLength: Number(options.minimum),
				maxLength: Number(options.maximum),
			})),
		),
	};
}

function InputElementOptions({
	element,
	errors,
}: {
	element:
		| typo3.form.ElementRadioButton
		| typo3.form.ElementMultiCheckbox
		| typo3.form.ElementMultiSelect;
	errors: null | Record<string, string>;
}) {
	const ref = useRef<HTMLDivElement>(null);
	const isRequired = getValidator("NotEmpty", element) !== undefined;
	const [enforceRequired, setEnforceRequired] = useState(isRequired);

	const count = getValidator("Count", element);
	const minimumCount =
		nonNull.tryMap(parseInt)(count?.options.minimum) ??
		(isRequired ? 1 : 0);

	const updateEnforceRequired = useCallback(() => {
		if (!ref.current) {
			return;
		}
		const checkedCount = Array.from(
			ref.current.querySelectorAll("input"),
		).filter((input) => input.checked).length;
		setEnforceRequired(checkedCount < minimumCount);
	}, [minimumCount]);

	useEffect(updateEnforceRequired, [updateEnforceRequired]);

	const type = element.type === "RadioButton" ? "radio" : "checkbox";

	return (
		<InputElementWrapper element={element} errors={errors}>
			<div
				ref={ref}
				className={`${type}-group`}
				onChange={isRequired ? updateEnforceRequired : undefined}
			>
				{Object.entries(element.properties.options).map(
					([label, value]) => (
						<label
							key={value}
							className={`${type}-input-container`}
						>
							<input
								type={type}
								name={element.name}
								// name={`${element.name}${element.type === "MultiCheckbox" ? "[]" : ""}`}
								value={value}
								// TODO: handle multiple values in MultiCheckbox
								defaultChecked={element.value === value}
								{...mapAdditionalAttributes(element, {
									required: false,
								})}
								required={enforceRequired}
							/>
							{label}
						</label>
					),
				)}

				{/* <pre>{JSON.stringify(element, null, 2)}</pre> */}
			</div>
		</InputElementWrapper>
	);
}

function InputElementText({
	element,
	errors,
	type = "text",
}: {
	element:
		| typo3.form.ElementText
		| typo3.form.ElementEmail
		| typo3.form.ElementDatePicker;
	errors: null | Record<string, string>;
	type?: HTMLInputTypeAttribute;
}) {
	if (getValidator("EmailAddress", element) !== undefined) {
		type = "email";
	} else if (getValidator("Integer", element) !== undefined) {
		type = "number";
	}

	return (
		<InputElementWrapper element={element} errors={errors}>
			<div className="text-input-container">
				<input
					type={type}
					id={element.identifier}
					name={element.name}
					defaultValue={element.value ?? element.defaultValue}
					// NOTE: We cannot use the pattern directly as it has a different syntax. There is some JS code in the core/backend that handles this, but porting that is too much work for now.
					//pattern={
					//	getValidator("RegularExpression", element)?.options
					//		.regularExpression
					//}
					{...mapAdditionalAttributes(element)}
				/>
			</div>
		</InputElementWrapper>
	);
}

function InputElement({
	element,
	errors,
}: {
	element: typo3.form.Element;
	errors: null | Record<string, string>;
}) {
	switch (element.type) {
		case "RadioButton":
		case "MultiCheckbox":
		case "MultiSelect":
			return <InputElementOptions element={element} errors={errors} />;

		case "SingleSelect":
			return (
				<InputElementWrapper element={element} errors={errors}>
					<div className="select-input-container">
						<select
							id={element.identifier}
							name={element.name}
							{...mapAdditionalAttributes(element)}
							defaultValue={element.value ?? element.defaultValue}
						>
							{element.properties.prependOptionLabel && (
								<option value="">
									{element.properties.prependOptionLabel}
								</option>
							)}

							{Object.entries(element.properties.options).map(
								([label, value]) => (
									<option key={value} value={value}>
										{label}
									</option>
								),
							)}
						</select>
					</div>
				</InputElementWrapper>
			);

		case "Checkbox":
			return (
				<InputElementWrapper
					element={element}
					errors={errors}
					showLabel={false}
				>
					<div className="checkbox-group">
						<label className="checkbox-input-container">
							<input
								type="checkbox"
								id={element.identifier}
								name={element.name}
								value="1"
								defaultChecked={element.value !== null}
								{...mapAdditionalAttributes(element)}
							/>
							<span>
								{element.label}
								<RequiredMark element={element} />
							</span>
						</label>
					</div>
				</InputElementWrapper>
			);

		case "Text":
			return <InputElementText element={element} errors={errors} />;

		case "Email":
			return (
				<InputElementText
					type="email"
					element={element}
					errors={errors}
				/>
			);

		case "DatePicker":
			// TODO: Actually render a date picker or disallow in form editor!
			return <InputElementText element={element} errors={errors} />;

		case "Textarea":
			return (
				<InputElementWrapper element={element} errors={errors}>
					<div className="textarea-input-container">
						<textarea
							id={element.identifier}
							name={element.name}
							defaultValue={element.value ?? element.defaultValue}
							{...mapAdditionalAttributes(element)}
						/>
					</div>
				</InputElementWrapper>
			);

		case "StaticText":
			return element.properties.text;

		case "Hidden":
			return (
				<input
					type="hidden"
					id={element.identifier}
					name={element.name}
					defaultValue={element.value ?? element.defaultValue}
					{...mapAdditionalAttributes(element)}
				/>
			);

		case "Honeypot":
			return (
				<InputElementWrapper element={element} errors={errors}>
					<input
						type="text"
						id={element.identifier}
						name={element.name}
						defaultValue={element.value ?? element.defaultValue}
						{...mapAdditionalAttributes(element)}
						style={{ position: "absolute", margin: "0 0 0 -999em" }}
					/>
				</InputElementWrapper>
			);

		case "Fieldset":
			return (
				<fieldset className="form-slide">
					<legend>{element.label}</legend>

					<InputElements
						elements={element.elements}
						errors={errors}
					/>
				</fieldset>
			);

		case "ContentElement": {
			const contentElement = element.properties
				.contentElement as typo3.AnyContentElement | null;
			if (
				contentElement &&
				"content" in contentElement &&
				"bodytext" in contentElement.content
			) {
				return (
					<BodyText
						dangerouslySetInnerHTML={{
							__html: contentElement.content.bodytext,
						}}
					/>
				);
			}
			return null;
		}

		default:
			unreachableValue(element);
	}
}

function InputElements({
	elements,
	errors,
}: {
	elements: ReadonlyArray<typo3.form.Element>;
	errors: null | Record<string, string>;
}) {
	return (
		<>
			{elements.map((element) => (
				<InputElement
					key={element.identifier}
					element={element}
					errors={errors}
				/>
			))}
		</>
	);
}

type SummaryPageProps = {
	summary: typo3.form.FormSummaryItem[];
};

function SummaryPage({ summary }: SummaryPageProps) {
	return (
		<div>
			{summary.map((item, index) => (
				<div key={index}>
					{item.isSection ? (
						<h4>{item.label}</h4>
					) : (
						<p>
							<strong>{item.label}:</strong> {item.value}
						</p>
					)}
				</div>
			))}
		</div>
	);
}

type ContentElementFormProps = {
	data: typo3.ContentElementForm;
};

export default function ContentElementForm({ data }: ContentElementFormProps) {
	const formId = data.content.form.id;

	const rawActionData = useActionData<typeof action>();
	const parsedAction = useMemo(
		() =>
			rawActionData
				? typo3.form.actionResponseSchema.safeParse(rawActionData)
				: null,
		[rawActionData],
	);

	const actionData = parsedAction?.data;
	const errors = parsedAction?.error
		? { api: "API Error" }
		: (actionData?.api?.errors ?? null);

	const { elements, api } =
		formId === actionData?.id ? actionData : data.content.form;

	return (
		<section className="module multi-form">
			<ContentHead data={data} />

			<Form action={data.content.formAction} method="post">
				<FormPage
					fieldNamePrefix={api.fieldNamePrefix}
					page={api.page}
					elements={elements}
					errors={errors}
				/>
			</Form>
		</section>
	);
}

function FormPage({
	page,
	elements,
	errors,
	fieldNamePrefix,
}: {
	page: typo3.form.FormPage;
	elements: typo3.form.Elements;
	fieldNamePrefix: string;
	errors: Record<string, string> | null;
}) {
	if (page.type === "Result") {
		return (
			<div className="form-slides-container">
				<div className="form-slide">
					{page.text ? (
						<BodyText
							dangerouslySetInnerHTML={{ __html: page.text }}
						/>
					) : (
						<BodyText>
							<p>
								<FormattedMessage id="form.submitted" />
							</p>
						</BodyText>
					)}
				</div>
			</div>
		);
	}

	return (
		<>
			<h3>{page.label}</h3>

			<div className="form-slides-container">
				<div className="form-slide">
					{errors && (
						<div className="rounded-3 mb-4 border bg-red-100 p-4 text-red-900">
							{Object.entries(errors).map(
								([fieldName, errorMessage]) => (
									<div key={fieldName}>
										{fieldName}: {errorMessage}
									</div>
								),
							)}
						</div>
					)}

					{page.type === "SummaryPage" && page.summary ? (
						<SummaryPage summary={page.summary} />
					) : null}

					<InputElements elements={elements} errors={errors} />

					{page.previousPage !== null && (
						<button
							className="button"
							type="submit"
							name={`${fieldNamePrefix}[__currentPage]`}
							value={page.previousPage}
							formNoValidate
						>
							<FormattedMessage id="form.previous" />
						</button>
					)}

					<button
						className="button"
						type="submit"
						name={`${fieldNamePrefix}[__currentPage]`}
						value={page.nextPage || page.pages + 1}
					>
						{page.nextPage !== null ? (
							<FormattedMessage id="form.next" />
						) : (
							<FormattedMessage id="form.submit" />
						)}
					</button>
				</div>
			</div>
		</>
	);
}

function getValidator<T extends typo3.form.Validator["identifier"]>(
	identifier: T,
	element: typo3.form.Element,
): undefined | (typo3.form.Validator & { identifier: T }) {
	if (element.validators === undefined) {
		return undefined;
	}
	for (const val of element.validators) {
		if (val.identifier === identifier) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			return val as any;
		}
	}
	return undefined;
}
