import { Link } from "@remix-run/react";

import type * as typo3 from "~/modules/typo3/schema";
import { cn } from "~/utils/cn";
import Image from "~/components/shared/Image";
import EmailLink from "~/components/shared/EmailLink";

export type PersonCardProps = {
	person: typo3.PersonCard;
	size?: "small" | "full";
};

export default function PersonCard({
	person,
	size = "small",
}: PersonCardProps) {
	const image = person.image !== null && (
		<Image image={person.image} sourceName="thumbnailFree" />
	);

	const name = (
		<>
			{person.title !== "" && (
				<>
					<span itemProp="honorificPrefix">{person.title}</span>{" "}
				</>
			)}
			<span itemProp="givenName">{person.firstname}</span>{" "}
			<span itemProp="familyName">{person.lastname}</span>
		</>
	);

	return (
		<article
			className={cn(`teaser-card mode-person-${size} element`, {
				"card-with-image": person.image !== null,
				"card-with-link": person.detailsUrl,
				"small-basic": size === "small",
			})}
		>
			<div className="card-inner">
				<div className="layout">
					<div className="container image">
						{person.detailsUrl ? (
							<Link
								to={person.detailsUrl}
								className={cn("card-img single-person-link", {
									"bg-gradient": person.image === null,
								})}
							>
								{image}
							</Link>
						) : (
							<div
								className={cn("card-img", {
									"bg-gradient": person.image === null,
								})}
							>
								{image}
							</div>
						)}
					</div>

					<div className="container title">
						<h2 itemProp="name">
							{person.detailsUrl ? (
								<Link
									to={person.detailsUrl}
									className="single-person-link"
								>
									{name}
								</Link>
							) : (
								name
							)}
						</h2>

						<div className="sub">
							{(person.job || person.info) && (
								<div className="person_function">
									<p>
										{person.job}
										{person.info ? ` (${person.info})` : ""}
									</p>
								</div>
							)}

							{person.address && (
								<div className="person_address">
									<p>{person.address}</p>
								</div>
							)}

							{person.phone && (
								<div className="person_data">
									<span className="telephone">
										{person.phone}
									</span>
								</div>
							)}

							{person.email && (
								<div className="person_links">
									<EmailLink emailAddress={person.email} />
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</article>
	);
}
