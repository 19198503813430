import { type IconProps } from "~/components/icons";

export default function Search({ label }: IconProps) {
	return (
		<div className="icon search">
			<svg
				width="100"
				height="100"
				viewBox="0 0 100 100"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				aria-label={label}
			>
				<circle cx="42.5" cy="44.5" r="26.5" />
				<path d="M84.6241 84.6276L61.9966 63.0002" />
			</svg>
		</div>
	);
}
