import type { HTMLProps } from "react";
import { forwardRef } from "react";

import { cn } from "~/utils/cn";
import UserText from "~/components/shared/UserText";

export type BodyTextProps = HTMLProps<HTMLDivElement>;

const BodyText = forwardRef<HTMLDivElement, BodyTextProps>(function BodyText(
	{ children, className, dangerouslySetInnerHTML, ...props },
	ref,
) {
	return (
		<div className={cn(className, "body-text")} {...props} ref={ref}>
			{dangerouslySetInnerHTML !== undefined ? (
				<UserText dangerouslySetInnerHTML={dangerouslySetInnerHTML} />
			) : (
				children
			)}
		</div>
	);
});

export default BodyText;
