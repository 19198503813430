import type * as typo3 from "~/modules/typo3/schema";
import { extractItem } from "~/utils/array";
import ScrollContentMain from "~/components/shared/ScrollContentMain";
import SolrPlugin from "~/components/plugins/solr/Solr";
import ContentElementsGroup from "~/components/content-elements/ContentElementsGroup";
import Calendar from "~/components/shared/calendar/Calendar";
import useIsSearchActive from "~/hooks/useIsSearchActive";
import AppLayout from "~/components/shared/AppLayout";
import { useEditPageLink } from "~/components/layouts/PageLayout";
import EditInCMSButton from "~/components/EditInCMSButton";

export type MediaLayoutProps = {
	page: typo3.Response;
};

export default function MediaLayout({ page }: MediaLayoutProps) {
	const { content } = page;
	const isSearchActive = useIsSearchActive();
	const [solrData, contentElements] = extractItem<
		typo3.ContentElementSolr,
		typo3.AnyContentElement
	>(
		content.colPos0,
		(contentElement): contentElement is typo3.ContentElementSolr =>
			contentElement.type === "solr_pi_results",
	);
	const editLink = useEditPageLink(page);

	return (
		<AppLayout layout="overview">
			<EditInCMSButton link={editLink} />

			<ScrollContentMain>
				<div className="column col-main">
					{solrData && (
						<SolrPlugin
							data={solrData}
							showEmptyError={isSearchActive}
						/>
					)}

					{!isSearchActive && (
						<ContentElementsGroup
							elements={contentElements}
							size="full"
						/>
					)}

					{/*
						<VideoGroup
							layout="quartet"
							videos={makeArray(4, getMockVideo)}
						/>
						<PodcastStripe
							podcasts={makeArray<Podcast>(10, (i) => ({
								title: `Hurra Hurra: Folge ${i + 1}`,
								pubDate: getMockDate(i),
								image: getMockImage(i),
							}))}
						/>
						<MediaStripe title="Jour Fixe Digital" indexOffset={10} />
						<MediaStripe title="Jahresausstellung 2022" indexOffset={20} />
						<MediaStripe
							title="Tipps / Studieren an der BURG / Beratung"
							indexOffset={33}
						/>
						<MediaStripe title="Burg" indexOffset={45} />
					 */}
				</div>

				<div className="column col-aside right">
					<Calendar sectionDescription={page.meta.title} />
				</div>
			</ScrollContentMain>
		</AppLayout>
	);
}
