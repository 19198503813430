import {
	useCallback,
	type PropsWithChildren,
	useState,
	useEffect,
	type DOMAttributes,
} from "react";
import useMeasure from "react-use-measure";
import { motion } from "framer-motion";

import useWindowDimensions from "~/hooks/useWindowDimensions";

export type HoverMarqueeProps = PropsWithChildren<{
	containerClassName?: string;
	contentClassName?: string;
	hoverElem?: HTMLElement;
	dangerouslySetInnerHTML?: DOMAttributes<unknown>["dangerouslySetInnerHTML"];
}>;

export default function HoverMarquee({
	children,
	containerClassName,
	contentClassName,
	hoverElem,
	dangerouslySetInnerHTML,
}: HoverMarqueeProps) {
	const [containerRef, { width: containerWidth }] = useMeasure();
	const [contentRef, { width: contentWidth }] = useMeasure();
	const [isMouseOver, setIsMouseOver] = useState(false);
	const vw = (useWindowDimensions()?.width ?? 0) / 100;

	const handleMouseEnter = useCallback(() => setIsMouseOver(true), []);
	const handleMouseLeave = useCallback(() => setIsMouseOver(false), []);

	useEffect(() => {
		if (hoverElem) {
			hoverElem.addEventListener("mouseenter", handleMouseEnter);
			hoverElem.addEventListener("mouseleave", handleMouseLeave);
			hoverElem.addEventListener("focusin", handleMouseEnter);
			hoverElem.addEventListener("focusout", handleMouseLeave);

			return () => {
				hoverElem.removeEventListener("mouseenter", handleMouseEnter);
				hoverElem.removeEventListener("mouseleave", handleMouseLeave);
				hoverElem.removeEventListener("focusin", handleMouseEnter);
				hoverElem.removeEventListener("focusout", handleMouseLeave);
			};
		}
	}, [hoverElem, handleMouseEnter, handleMouseLeave]);

	const delta = contentWidth - containerWidth + 3 * vw;

	const isMarqueeActive = isMouseOver && containerWidth < contentWidth;

	return (
		<div
			ref={containerRef}
			className={containerClassName}
			style={{ overflow: "hidden" }}
			onMouseEnter={
				hoverElem === undefined ? handleMouseEnter : undefined
			}
			onMouseLeave={
				hoverElem === undefined ? handleMouseLeave : undefined
			}
			onFocus={hoverElem === undefined ? handleMouseEnter : undefined}
			onBlur={hoverElem === undefined ? handleMouseLeave : undefined}
		>
			<motion.span
				ref={contentRef}
				className={contentClassName}
				style={{
					whiteSpace: "nowrap",
					display: "inline-block",
				}}
				animate={{
					x: isMarqueeActive ? -delta : 0,
				}}
				transition={{
					duration: isMarqueeActive ? (delta * 12) / 1_000 : 0.3,
					ease: "easeIn",
				}}
				dangerouslySetInnerHTML={dangerouslySetInnerHTML}
			>
				{children}
			</motion.span>
		</div>
	);
}
