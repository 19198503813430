import { Link } from "@remix-run/react";

import type * as typo3 from "~/modules/typo3/schema";
import { trimSplit } from "~/utils/text";
import ContentElementUnknown from "~/components/content-elements/ContentElementUnknown";
import ContentHead from "~/components/shared/ContentHead";
import Swiper from "~/components/shared/Swiper";
import Video from "~/components/shared/Video";
import Image from "~/components/shared/Image";

function ProjectSlide({ project }: { project: typo3.ProjectCard }) {
	const media = project.relatedMedia[0];
	if (!media) return null;

	const students = trimSplit(project.students ?? "").join(", ");

	return (
		<Link to={project.detailsUrl ?? "#"}>
			<figure className="container image">
				<div className="container-inner">
					{media.type === "image" && <Image image={media} />}
					{media.type === "video" && <Video video={media} />}
				</div>

				<figcaption className="container title">
					<div className="col-left">
						<div className="card-title whitespace-pre-line">
							{media.properties.description}
						</div>
					</div>

					<div className="col-left">
						<div className="card-title">
							<p>{project.title}</p>
						</div>
						<div className="card-students">
							<p>{students}</p>
						</div>
					</div>

					<div className="col-right">
						<div className="card-studiengang">
							{project.sectionCategories[0]?.title}
						</div>

						<div className="card-teachers">
							{project.relatedPersons
								.map(({ fullName }) => fullName)
								.join(", ")}
						</div>
					</div>
				</figcaption>
			</figure>
		</Link>
	);
}

export function ProjectsSlider({
	size,
	data,
}: {
	size: "half" | "full";
	data: typo3.ContentElementProjects;
}) {
	const pluginData = data.content.data;
	if (pluginData.action !== "list") {
		return (
			<ContentElementUnknown type={`${data.type}@${pluginData.action}`} />
		);
	}
	const projects = pluginData.articles;

	return (
		<div className={`works-showcase module ${size} hero`}>
			<ContentHead data={data} />

			<div className="inner">
				<div className="content">
					<Swiper
						swiperClassname={`swiper-gallery size-${size}`}
						slideClassname="element gallery mode-work-showcase"
					>
						{projects.map((project) => (
							<ProjectSlide key={project.uid} project={project} />
						))}
					</Swiper>
				</div>
			</div>
		</div>
	);
}
