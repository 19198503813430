import { useIntl } from "react-intl";
import { useCallback, useMemo, useState } from "react";
import useMeasure from "react-use-measure";
import { motion } from "framer-motion";

import ContentHead from "~/components/shared/ContentHead";
import DocumentDownloadTile from "~/components/shared/DocumentDownloadTile";
import type * as typo3 from "~/modules/typo3/schema";
import { cn } from "~/utils/cn";

export type ContentElementUploadsProps = {
	data: typo3.ContentElementUploads;
};

export default function ContentElementUploads({
	data,
}: ContentElementUploadsProps) {
	const intl = useIntl();
	const [isFoldoutOpen, setFoldoutOpen] = useState(false);
	const [contentRef, { height: contentHeight }] = useMeasure();

	const toggleFoldout = useCallback(
		() => setFoldoutOpen((prev) => !prev),
		[],
	);

	const isFoldout = data.content.headerLayout === 22;
	const headingRight = useMemo(() => {
		if (!isFoldout) {
			return undefined;
		}

		return (
			<span
				className="icon fold-state-indicator"
				aria-label={intl.formatMessage({
					id: isFoldoutOpen ? "ceText.collapse" : "ceText.expand",
				})}
			>
				<span className="inner" />
			</span>
		);
	}, [isFoldout, isFoldoutOpen, intl]);

	let heading = <ContentHead data={data} rightContent={headingRight} />;

	let content = (
		<div className="document-download-wrapper" ref={contentRef}>
			{data.content.media.map((dl, dlIndex) => (
				<DocumentDownloadTile key={dlIndex} download={dl} />
			))}
		</div>
	);

	if (isFoldout) {
		heading = (
			<button
				type="button"
				onClick={toggleFoldout}
				tabIndex={0}
				className="block w-full cursor-pointer text-left"
				title={intl.formatMessage({
					id: isFoldoutOpen ? "ceText.collapse" : "ceText.expand",
				})}
			>
				{heading}
			</button>
		);

		if (isFoldoutOpen) {
			content = (
				<motion.div
					animate={{ height: contentHeight }}
					className="overflow-hidden"
				>
					{content}
				</motion.div>
			);
		} else {
			content = (
				<motion.div
					role="button"
					onClick={toggleFoldout}
					tabIndex={0}
					onKeyDown={toggleFoldout}
					animate={{
						height: `calc(var(--font-size) * var(--line-height-text) * 6)`,
					}}
					className="cursor-pointer overflow-hidden"
					style={{
						maskImage: `linear-gradient(
							to top,
							transparent 0,
							black calc(var(--font-size) * var(--line-height-text) * 4),
							black 100%,
							transparent 100%
						)`,
						maskSize: "100% 100%",
						maskPosition: "center",
						maskRepeat: "no-repeat",
					}}
				>
					{content}
				</motion.div>
			);
		}
	}

	return (
		<section
			className={cn("module", {
				foldout: isFoldout,
				folded: isFoldout && !isFoldoutOpen,
			})}
		>
			{heading}
			<div className="my-gap-xl">{content}</div>
		</section>
	);
}
