import type * as typo3 from "~/modules/typo3/schema";
import * as nonNull from "~/utils/nonNullable";
import { pipe } from "~/utils/function";
import TeaserCard from "~/components/shared/cards/TeaserCard";
import DateComp from "~/components/shared/Date";
import { renderArticleType } from "~/components/shared/ArticleType";

export type ArticleCardProps = {
	article: typo3.ArticleCard;
};

export default function ArticleCard({ article }: ArticleCardProps) {
	const label = renderArticleType(article);

	return (
		<TeaserCard
			mode="news"
			className="small-basic"
			image={article.relatedMedia[0]}
			label={label}
			title={article.title}
			href={article.detailsUrl}
			teaser={article.teasertext}
			published={pipe(
				article.pubDate,
				nonNull.tryMap((pubDate) => new Date(pubDate)),
				nonNull.map((pubDate) => (
					<DateComp
						key="date"
						date={pubDate}
						year
						// TODO: used in the prototype, but we seem to not store the time in the cms:
						// time year="2-digit"
					/>
				)),
			)}
			date={article.eventDate}
		/>
	);
}
