export interface EssentialsItemProps {
	type: string;
}

export default function EssentialsItemUnknown({ type }: EssentialsItemProps) {
	return (
		<div className="essential-item text my-6 bg-yellow-200 p-2 font-bold text-red-900">
			Missing Component for Content Element Type [{type}].
		</div>
	);
}
