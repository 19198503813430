import type * as typo3 from "~/modules/typo3/schema";
import ContentElementUnknown from "~/components/content-elements/ContentElementUnknown";
import ContentHead from "~/components/shared/ContentHead";
import ProjectCard from "~/components/shared/cards/ProjectCard";

export function ProjectsGrid({
	size,
	data,
}: {
	size: "half" | "full";
	data: typo3.ContentElementProjects;
}) {
	const pluginData = data.content.data;
	if (pluginData.action !== "list") {
		return (
			<ContentElementUnknown type={`${data.type}@${pluginData.action}`} />
		);
	}
	const projects = pluginData.articles;

	const colCount = Math.max(
		3,
		Math.min(7, size === "full" ? 5 : Math.floor(projects.length / 3)),
	);

	return (
		<section className={`section works-grid module size-${size}`}>
			<div className="works-inner-wrapper inner">
				{size === "full" && (
					<div className="works-grid-header no-filter">
						<div className="title-container">
							<ContentHead data={data} />
						</div>
					</div>
				)}

				<div
					className={`works-grid-container filterTargetWrapper col-count-${colCount}`}
				>
					{projects.map((project) => (
						<ProjectCard key={project.uid} article={project} />
					))}
				</div>
			</div>
		</section>
	);
}
