import type * as typo3 from "~/modules/typo3/schema";
import { type ContentElementSize } from "~/components/content-elements/ContentElement";

import { ProjectsGrid } from "./projects/ProjectsGrid";
import { ProjectsSlider } from "./projects/ProjectsSlider";

export type ProjectsPluginProps = {
	data: typo3.ContentElementProjects;
	size: ContentElementSize;
};

export default function ProjectsPlugin({ data, size }: ProjectsPluginProps) {
	// Special case for the projects slider on the home page
	if (data.colPos === 20) {
		return <ProjectsSlider data={data} size={size} />;
	}

	return <ProjectsGrid data={data} size={size} />;
}
