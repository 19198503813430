import { useCallback, useEffect, useRef, useState } from "react";
import useMeasure from "react-use-measure";
import { motion } from "framer-motion";
import { useIntl } from "react-intl";

import { cn } from "~/utils/cn";
import BodyText from "~/components/shared/BodyText";
import ContentHead from "~/components/shared/ContentHead";
import { type GroupableContentElement } from "~/components/content-elements/ContentElementsGroup";
import { type ScrollingToEvent } from "~/utils/events";
import DocumentDownloadTile from "~/components/shared/DocumentDownloadTile";

type ContentElementGroupProps = {
	elements: Array<GroupableContentElement>;
};

export default function ContentElementGroup({
	elements,
}: ContentElementGroupProps) {
	const intl = useIntl();
	const [isFoldoutOpen, setFoldoutOpen] = useState(false);
	const [contentRef, { height: contentHeight }] = useMeasure();

	const toggleFoldout = useCallback(
		() => setFoldoutOpen((prev) => !prev),
		[],
	);

	const openFoldout = useCallback(() => setFoldoutOpen(true), []);

	const firstElement = elements[0];
	if (!firstElement) {
		return null;
	}

	const heading = (
		<button
			type="button"
			onClick={toggleFoldout}
			tabIndex={0}
			className="block w-full cursor-pointer text-left"
			title={intl.formatMessage({
				id: isFoldoutOpen ? "ceGroup.collapse" : "ceGroup.expand",
			})}
		>
			<ContentHead
				data={firstElement}
				onScrollingTo={openFoldout}
				rightContent={
					<span
						className="icon fold-state-indicator"
						aria-label={intl.formatMessage({
							id: isFoldoutOpen
								? "ceGroup.collapse"
								: "ceGroup.expand",
						})}
					>
						<span className="inner" />
					</span>
				}
			/>
		</button>
	);

	let content = (
		<div ref={contentRef}>
			{elements.map((element, i) => (
				<ContentElementGroupElement
					key={element.id}
					element={element}
					index={i}
					openFoldout={openFoldout}
				/>
			))}
		</div>
	);

	if (isFoldoutOpen) {
		content = (
			<motion.div
				animate={{ height: contentHeight }}
				className="overflow-hidden"
			>
				{content}
			</motion.div>
		);
	} else {
		content = (
			<motion.div
				role="button"
				onClick={toggleFoldout}
				tabIndex={0}
				onKeyDown={toggleFoldout}
				animate={{
					height: `calc(var(--font-size) * var(--line-height-text) * 6)`,
				}}
				className="cursor-pointer overflow-hidden"
				style={{
					maskImage: `linear-gradient(
							to top,
							transparent 0,
							black calc(var(--font-size) * var(--line-height-text) * 4),
							black 100%,
							transparent 100%
						)`,
					maskSize: "100% 100%",
					maskPosition: "center",
					maskRepeat: "no-repeat",
				}}
			>
				{content}
			</motion.div>
		);
	}

	return (
		<section
			className={cn("text module foldout group", {
				folded: !isFoldoutOpen,
			})}
		>
			{heading}
			<div className="text-container">
				<div className="inner">{content}</div>
			</div>
		</section>
	);
}

function ContentElementGroupElement({
	element,
	index,
	openFoldout,
}: {
	element: GroupableContentElement;
	index: number;
	openFoldout(): void;
}) {
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const elem = ref.current;
		if (!elem) {
			return;
		}

		function handleScrollingTo(_ev: ScrollingToEvent) {
			openFoldout();
		}

		elem.addEventListener("buha_scrollingTo", handleScrollingTo);
		return () => {
			elem.removeEventListener("buha_scrollingTo", handleScrollingTo);
		};
	}, [openFoldout]);

	return (
		<div
			ref={ref}
			/* Only set the id for next elements since the first element id is applied to the wrapper */
			id={index === 0 ? undefined : String(element.id)}
			className={cn("body-text", {
				"columns-2 gap-x-[0.3rem]":
					element.appearance.layout === "layout-two-col-section",
			})}
		>
			{/* For next elements if there is a header show it in the body text */}
			{index > 0 &&
				"header" in element.content &&
				element.content.header.trim() !== "" && (
					<h3>{element.content.header}</h3>
				)}
			{"bodytext" in element.content && element.type === "text" && (
				<BodyText
					dangerouslySetInnerHTML={{
						__html: element.content.bodytext,
					}}
				/>
			)}
			{"media" in element.content && element.type === "uploads" && (
				<div className="my-gap-xl">
					<div className="document-download-wrapper">
						{element.content.media.map((dl, dlIndex) => (
							<DocumentDownloadTile key={dlIndex} download={dl} />
						))}
					</div>
				</div>
			)}
		</div>
	);
}
