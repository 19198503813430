import { useIntl } from "react-intl";

import { trimSplit } from "~/utils/text";

type TextListProps = {
	text: string;
	separator?: string;
};

export default function TextList({ text, separator = "\n" }: TextListProps) {
	const intl = useIntl();
	const list = trimSplit(text, separator);
	return intl.formatList(list);
}
