import Image from "~/components/shared/Image";
import type * as typo3 from "~/modules/typo3/schema";
import ContentHead from "~/components/shared/ContentHead";

export type ContentElementImageSingleProps = {
	data: typo3.ContentElementImage;
};

export default function ContentElementImageSingle({
	data,
}: ContentElementImageSingleProps) {
	const image = data.content.images[0];

	if (!image) {
		return null;
	}

	return (
		<div className="module image">
			<ContentHead data={data} />

			<figure>
				<Image image={image} />

				{image.properties.description !== null && (
					<figcaption>
						<span>{image.properties.description}</span>
					</figcaption>
				)}
			</figure>
		</div>
	);
}
