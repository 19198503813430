import { type ReactNode, useMemo } from "react";

import { cn } from "~/utils/cn";
import type * as typo3 from "~/modules/typo3/schema";
import { getTypo3BackendUrl } from "~/modules/typo3/backend";
import { type LayoutProps } from "~/modules/typo3/backend-layouts";
import ScrollContent from "~/components/shared/ScrollContent";
import ScrollContentMain from "~/components/shared/ScrollContentMain";
import EssentialsPageTitle from "~/components/shared/EssentialsPageTitle";
import MainPageTitle from "~/components/shared/MainPageTitle";
import EssentialsItem from "~/components/essentials/EssentialsItem";
import ContentElementsGroup from "~/components/content-elements/ContentElementsGroup";
import ArticleLayout from "~/components/layouts/ArticleLayout";
import PersonLayout from "~/components/layouts/PersonLayout";
import Calendar from "~/components/shared/calendar/Calendar";
import SearchLayout from "~/components/layouts/SearchLayout";
import SolrPlugin from "~/components/plugins/solr/Solr";
import AppLayout from "~/components/shared/AppLayout";
import EditInCMSButton from "~/components/EditInCMSButton";
import SectionProjects from "~/components/shared/SectionProjects";
import SectionArchive from "~/components/shared/SectionArchive";
import AToZLayout from "~/components/layouts/AToZLayout";
import SearchMarker from "~/components/SearchMarker";
import { useGlobal } from "~/context/global";

type PageLayoutProps = {
	page: typo3.Response;
};

export type SinglePlugin = {
	meta: {
		title: string;
		description: string;
		keywords: string;
	};
	renderLayoutComponent(props: LayoutProps): ReactNode;
};

export function findSinglePlugin({
	page,
}: LayoutProps): SinglePlugin | undefined {
	for (const el of page.content.colPos0 ?? []) {
		switch (el.type) {
			case "nnbuhaarticlesandpersons_articles":
			case "nnbuhaarticlesandpersons_events":
			case "nnbuhaarticlesandpersons_lehrangebote":
			case "nnbuhaarticlesandpersons_projects":
			case "nnbuhaarticlesandpersons_publications": {
				const pluginData = el.content.data;
				if (pluginData.action !== "show") {
					return undefined;
				}

				return {
					meta: {
						title: pluginData.article.title,
						description: pluginData.article.teasertext,
						keywords: "",
					},
					renderLayoutComponent: () => (
						<ArticleLayout
							page={page}
							article={pluginData.article}
						/>
					),
				};
			}

			case "nnbuhaarticlesandpersons_persons":
			case "nnbuhaarticlesandpersons_personlist": {
				const pluginData = el.content.data;
				if (pluginData.action !== "show") {
					return undefined;
				}

				return {
					meta: {
						title: pluginData.person.fullName,
						description: "",
						// description: pluginData.person.teaserText,
						keywords: "",
					},
					renderLayoutComponent: () => (
						<PersonLayout page={page} person={pluginData.person} />
					),
				};
			}

			case "solr_pi_results":
				return {
					meta: page.meta,
					renderLayoutComponent: () => (
						<SearchLayout page={page}>
							<SolrPlugin data={el} />
						</SearchLayout>
					),
				};

			case "nnbuha22base_az":
				return {
					meta: page.meta,
					renderLayoutComponent: () => (
						<AToZLayout page={page} content={el} />
					),
				};
		}
	}
}

export function useEditPageLink(page: typo3.Response) {
	return useMemo(() => {
		const url = getTypo3BackendUrl("/typo3/module/web/layout");
		url.searchParams.append("id", String(page.id));
		return url.toString();
	}, [page.id]);
}

export default function PageLayout({ page }: PageLayoutProps) {
	const { content, meta, appearance } = page;
	const editLink = useEditPageLink(page);
	const { isMobile } = useGlobal();
	return (
		<AppLayout layout="page">
			<EditInCMSButton link={editLink} />

			<ScrollContentMain>
				<div className="column col-main">
					<div
						className={
							"main-content" +
							(isMobile ? " jump-to-nav mobile" : "")
						}
					>
						<EssentialsPageTitle page={page} />

						{appearance.backendLayout === "schwerpunkt" && (
							<MainPageTitle page={page} />
						)}

						<ContentElementsGroup
							elements={content.colPos0 ?? []}
							size="full"
						/>
					</div>
				</div>

				<div className="column col-aside right">
					<Calendar sectionDescription={page.meta.title} />
				</div>

				<div className="column col-aside left">
					<div className="essentials module">
						<ScrollContent className="essentials-inner">
							<EssentialsPageTitle page={page} />

							<div
								className={cn("essentials-content", {
									"no-title": meta.title.trim() === "",
								})}
							>
								{content.colPos150?.map((contentElement) => (
									<EssentialsItem
										key={contentElement.id}
										data={contentElement}
									/>
								))}
							</div>
						</ScrollContent>
					</div>
				</div>
			</ScrollContentMain>

			<SearchMarker inverted>
				{page.projects && <SectionProjects page={page} />}
				{page.archive && <SectionArchive page={page} />}
			</SearchMarker>
		</AppLayout>
	);
}
