import type * as typo3 from "~/modules/typo3/schema";
import Image from "~/components/shared/Image";

export function PersonMenuThumb({
	personImage,
}: {
	personImage: NonNullable<typo3.PersonCard["image"]>;
}) {
	return (
		<div className="image-container bgGradient">
			<div className="image-content background">
				<Image image={personImage} sourceName="bobble" />
			</div>
			<div className="image-content foreground">
				<Image image={personImage} sourceName="bobble" />
			</div>
		</div>
	);
}
