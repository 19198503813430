import { useCallback, useEffect } from "react";
import { DialogTrigger } from "react-aria-components";
import { motion, AnimatePresence } from "framer-motion";

import EnergySlider from "~/components/EnergySlider";
import { type NavAction, type NavState } from "~/components/shared/MainHeader";
import MainNav from "~/components/shared/MainNav";
import MainNavSub from "~/components/shared/MainNavSub";
import MobileMenuSwitcher from "~/components/shared/MobileMenuSwitcher";
import Toolbar, {
	SEARCH_ITEM,
	renderMobileItem,
} from "~/components/shared/Toolbar";
import { useGlobal } from "~/context/global";
import { cn } from "~/utils/cn";
import { type RgbColor } from "~/utils/colors";
import CalendarControl from "~/components/shared/CalendarControl";

export type MobileNavProps = {
	setSubNavColor(color?: RgbColor): void;
	state: NavState;
	dispatch(action: NavAction): void;
};

export default function MobileNav({
	setSubNavColor,
	state,
	dispatch,
}: MobileNavProps) {
	const { isMobileNavOpen, setGlobal } = useGlobal();

	useEffect(() => {
		if (!isMobileNavOpen) {
			dispatch({ type: "forceClose" });
		}
	}, [dispatch, isMobileNavOpen]);

	const handleSubLinkClick = useCallback(() => {
		setGlobal((g) => {
			g.isMobileNavOpen = false;
		});
	}, [setGlobal]);

	return (
		<DialogTrigger isOpen={isMobileNavOpen}>
			<MobileMenuSwitcher />

			<div
				id="mobile-nav"
				className={cn("v2", { "sub-nav-folded-out": state.open })}
			>
				<div className="layout">
					<div className="wrapper-main-nav">
						<MainNav
							setSubNavColor={setSubNavColor}
							state={state}
							dispatch={dispatch}
						/>
					</div>

					<AnimatePresence>
						{state.open && (
							<motion.div
								className="wrapper-sub-nav"
								key={state.open.id}
								initial={{ x: "100%", opacity: 0 }}
								animate={{ x: 0, opacity: 1 }}
								exit={{ x: "100%", opacity: 0 }}
								transition={{
									ease: "easeInOut",
									duration: 0.2,
								}}
							>
								<MainNavSub
									openState={state.open}
									onLinkClick={handleSubLinkClick}
								/>
							</motion.div>
						)}
					</AnimatePresence>

					<div className="wrapper-toolbar">
						<Toolbar />
					</div>

					<div className="wrapper-energy-slider">
						<EnergySlider />
					</div>

					<div className="wrapper-global-search">
						{renderMobileItem(SEARCH_ITEM)}
					</div>
				</div>
			</div>

			<CalendarControl />
		</DialogTrigger>
	);
}
