import {
	type PropsWithChildren,
	Children,
	useState,
	type MouseEvent,
	useCallback,
	useEffect,
	type FocusEvent,
	useRef,
} from "react";
import { motion } from "framer-motion";
import useMeasure from "react-use-measure";
import { mergeRefs } from "react-merge-refs";

import { delay, spawnDetached } from "~/utils/async";
import { useGlobal } from "~/context/global";

const TRANSITION_DURATION_S = 0.3;

export type MouseXScrollProps = PropsWithChildren<{
	className?: string;
	contentClassName?: string;
	isEnabled?: boolean;
}>;

export default function MouseXScroll({
	children,
	className = "",
	contentClassName = "",
	isEnabled = true,
}: MouseXScrollProps) {
	const { isMobile } = useGlobal();
	const [containerRef, containerRect] = useMeasure();
	// 0 = left most, 1 = right most
	const [mousePosition, setMousePosition] = useState(0);

	const [isMouseOn, setIsMouseOn] = useState(false);
	const [isTransitionEnabled, setIsTransitionEnabled] = useState(true);

	useEffect(
		() =>
			spawnDetached(async () => {
				if (isMouseOn) {
					setIsTransitionEnabled(true);
					await delay(TRANSITION_DURATION_S * 1_000);
					setIsTransitionEnabled(false);
				} else {
					setIsTransitionEnabled(true);
				}
			}),
		[isMouseOn],
	);

	const handleMouseEnter = useCallback(() => setIsMouseOn(true), []);
	const handleMouseLeave = useCallback(() => setIsMouseOn(false), []);

	const handleMouseMove = useCallback(
		(ev: MouseEvent<HTMLElement>) => {
			const x = ev.pageX - containerRect.x;
			setMousePosition(x / containerRect.width);
		},
		[containerRect],
	);

	const wrapperClassName = `${isEnabled ? "mouse-x-scroll" : ""} ${className}`;

	if (!isEnabled || isMobile) {
		return (
			<div className={wrapperClassName}>
				{Children.map(children, (child) => (
					<div className={`content ${contentClassName}`}>{child}</div>
				))}
			</div>
		);
	} else {
		return (
			<div
				className={wrapperClassName}
				ref={containerRef}
				style={{
					// need to override flex, we measure the width of the rows instead of
					// the scroll overflow
					display: "block",
				}}
				onMouseMove={handleMouseMove}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>
				{Children.map(children, (child) => (
					<MouseXScrollRow
						className={contentClassName}
						mousePosition={mousePosition}
						setMousePosition={setMousePosition}
						containerWidth={containerRect.width}
						isTransitionEnabled={isTransitionEnabled}
					>
						{child}
					</MouseXScrollRow>
				))}
			</div>
		);
	}
}

export type MouseXScrollRowProps = PropsWithChildren<{
	className: string;
	/**
	 * 0 = left most, 1 = right most
	 */
	mousePosition: number;
	setMousePosition(mousePosition: number): void;
	containerWidth: number;
	isTransitionEnabled: boolean;
}>;

function MouseXScrollRow({
	children,
	className,
	mousePosition,
	setMousePosition,
	containerWidth,
	isTransitionEnabled,
}: MouseXScrollRowProps) {
	const ref = useRef<HTMLElement>();
	const [measureRef, { width }] = useMeasure();

	let x: number;
	if (containerWidth < width) {
		x = mousePosition * width - mousePosition * containerWidth;
	} else {
		x = mousePosition * width * 0.5;
	}

	const handleFocus = useCallback(
		(e: FocusEvent<HTMLElement>) => {
			if (ref.current) {
				const { left: rowLeft } = ref.current.getBoundingClientRect();
				const { left } = e.target.getBoundingClientRect();
				const x = (left - rowLeft) / width;
				setMousePosition(Math.max(Math.min(x, 1), 0));
			}
		},
		[setMousePosition, width],
	);

	return (
		<motion.div
			className={`content ${className}`}
			style={{
				display: "inline-block",
				position: "relative",
			}}
			ref={mergeRefs([ref, measureRef])}
			animate={{
				x: -x,
			}}
			transition={{
				duration: isTransitionEnabled ? TRANSITION_DURATION_S : 0,
			}}
			onFocus={handleFocus}
		>
			{children}
		</motion.div>
	);
}
