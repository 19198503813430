import { type ReactNode, useMemo, useState } from "react";
import { Link } from "@remix-run/react";
import { FormattedDate } from "react-intl";

import type * as typo3 from "~/modules/typo3/schema";
import ButtonArrow from "~/components/icons/ButtonArrow";
import { cn } from "~/utils/cn";
import { formatDateForTimeElement } from "~/utils/time";
import { buildRangeDateSetUrlForSingleDate } from "~/utils/solr";

type CalendarOverviewNavigationProps = {
	solrData: typo3.SolrData;
};

const isEventDateFacet = (
	facet: typo3.SolrFacet,
): facet is typo3.SolrRangeDateFacet => facet.type === "rangeDate";

type OverviewState = {
	year: number;
	month: number;
};

function getPreviousMonth({ year, month }: OverviewState): OverviewState {
	if (month === 0) {
		return { year: year - 1, month: 11 };
	}
	return { year, month: month - 1 };
}

function getNextMonth({ year, month }: OverviewState): OverviewState {
	if (month === 11) {
		return { year: year + 1, month: 0 };
	}
	return { year, month: month + 1 };
}

export default function CalendarOverviewNavigation({
	solrData,
}: CalendarOverviewNavigationProps) {
	const [start, setStart] = useState<OverviewState>(() => {
		const now = new Date();
		return { year: now.getFullYear(), month: now.getMonth() };
	});

	const eventDateFacet = solrData.facets.find(
		(facet) => facet.name === "eventDate",
	);

	const setUrlTemplate =
		(eventDateFacet &&
			isEventDateFacet(eventDateFacet) &&
			eventDateFacet.uris.set) ||
		"";

	return (
		<div className="calendar-overview-navigation">
			<div className="timeline">
				<ButtonArrow
					type="next"
					onClick={() =>
						setStart((prevStart) => getNextMonth(prevStart))
					}
				/>
				<ButtonArrow
					type="prev"
					onClick={() =>
						setStart((prevStart) => getPreviousMonth(prevStart))
					}
				/>

				<div className="calendar-view">
					<Month
						setUrlTemplate={setUrlTemplate}
						date={new Date(start.year, start.month)}
					/>
					<Month
						setUrlTemplate={setUrlTemplate}
						date={new Date(start.year, start.month + 1)}
					/>
					<Month
						setUrlTemplate={setUrlTemplate}
						date={new Date(start.year, start.month + 2)}
					/>
					<Month
						setUrlTemplate={setUrlTemplate}
						date={new Date(start.year, start.month + 3)}
					/>
					<Month
						setUrlTemplate={setUrlTemplate}
						date={new Date(start.year, start.month + 4)}
					/>
					<Month
						setUrlTemplate={setUrlTemplate}
						date={new Date(start.year, start.month + 5)}
					/>
					<Month
						setUrlTemplate={setUrlTemplate}
						date={new Date(start.year, start.month + 6)}
					/>
					<Month
						setUrlTemplate={setUrlTemplate}
						date={new Date(start.year, start.month + 7)}
					/>
					<Month
						setUrlTemplate={setUrlTemplate}
						date={new Date(start.year, start.month + 8)}
					/>
					<Month
						setUrlTemplate={setUrlTemplate}
						date={new Date(start.year, start.month + 9)}
					/>
					<Month
						setUrlTemplate={setUrlTemplate}
						date={new Date(start.year, start.month + 10)}
					/>
					<Month
						setUrlTemplate={setUrlTemplate}
						date={new Date(start.year, start.month + 11)}
					/>
					<Month
						setUrlTemplate={setUrlTemplate}
						date={new Date(start.year, start.month + 12)}
					/>
				</div>
			</div>
		</div>
	);
}

type MonthProps = {
	date: Date;
	setUrlTemplate: string;
};

function Month({ date, setUrlTemplate }: MonthProps) {
	const days: ReactNode = useMemo(() => {
		const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
		const lastDateDay = new Date(
			date.getFullYear(),
			date.getMonth() + 1,
			0,
		);

		const dayProps: Array<Pick<DayProps, "date" | "status">> = [];

		// pad with days from previous month to correctly align the week
		const daysToStartPad = (firstDay.getDay() + 6) % 7;
		if (0 < daysToStartPad) {
			const startPadFirstDay = new Date(
				date.getFullYear(),
				date.getMonth(),
				-(daysToStartPad - 1),
			).getDate();
			const startPadLastDay = new Date(
				date.getFullYear(),
				date.getMonth(),
				0,
			).getDate();
			for (let day = startPadFirstDay; day <= startPadLastDay; day += 1) {
				dayProps.push({
					date: new Date(
						date.getFullYear(),
						date.getMonth() - 1,
						day,
					),
					status: "muted",
				});
			}
		}

		const lastDay = lastDateDay.getDate();
		for (let day = 1; day <= lastDay; day += 1) {
			dayProps.push({
				date: new Date(date.getFullYear(), date.getMonth(), day),
				status: "hasEvents", // TODO: Actually get the info
			});
		}

		// pad with days from next month to correctly right-align the week
		const daysToEndPad = (7 - lastDateDay.getDay()) % 7;
		for (let day = 1; day <= daysToEndPad; day += 1) {
			dayProps.push({
				date: new Date(date.getFullYear(), date.getMonth() + 1, day),
				status: "muted",
			});
		}

		return dayProps.map((day, index) => (
			<Day key={index} setUrlTemplate={setUrlTemplate} {...day} />
		));
	}, [date, setUrlTemplate]);

	return (
		<div className="table">
			<div className="thead">
				<time dateTime={formatDateForTimeElement(date, { day: false })}>
					<FormattedDate value={date} month="long" year="numeric" />
				</time>
			</div>

			<div className="tbody">{days}</div>
		</div>
	);
}

type DayProps = {
	date: Date;
	status: "hasNoEvents" | "hasEvents" | "muted";
	setUrlTemplate: string;
};

function Day({ date, status, setUrlTemplate }: DayProps) {
	const dayOfWeek = date.getDay();
	const url = buildRangeDateSetUrlForSingleDate(setUrlTemplate, date);

	return (
		<div
			className={cn({
				mark: status === "hasEvents",
				muted: status === "muted",
				weekend: dayOfWeek === 0 || dayOfWeek === 6,
			})}
		>
			<Link
				to={url}
				className={cn({
					//disabled: status !== "hasEvents",
				})}
			>
				<span>
					<time dateTime={formatDateForTimeElement(date)}>
						{date.getDate()}
					</time>
				</span>
			</Link>
		</div>
	);
}
