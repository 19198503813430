import File from "~/components/icons/File";
import type * as typo3 from "~/modules/typo3/schema";

export type DocumentDownloadTileProps = {
	download: typo3.DocumentDownload;
	/** @default "full" */
	size?: "full" | "small";
};

export default function DocumentDownloadTile({
	download: {
		publicUrl,
		properties: { title, extension, mimeType, filename },
	},
	size = "full",
}: DocumentDownloadTileProps) {
	return (
		// eslint-disable-next-line react/jsx-no-target-blank
		<a
			className={`document-download-tile ${size}`}
			href={publicUrl}
			download={filename}
			type={mimeType}
			target="_blank"
		>
			<div className="icon-container">
				<File size={size} fileType={extension} />
			</div>
			<div className="title-container">
				<span>{title ?? filename}</span>
			</div>
		</a>
	);
}
