import { type PropsWithChildren } from "react";

import SearchMarker from "~/components/SearchMarker";
import Footer from "~/components/shared/Footer";
import HeaderCinemode from "~/components/shared/HeaderCinemode";
import MainHeader from "~/components/shared/MainHeader";
import { useGlobal } from "~/context/global";
import { cn } from "~/utils/cn";

export type Layout =
	| "page"
	| "error"
	| "studiengang"
	| "calendar"
	| "frontpage"
	| "overview"
	| "persons-list"
	| "intranet"
	| "course-overview"
	| "works-overview";

const FULL_WIDTH_LAYOUTS: Set<Layout> = new Set([
	"frontpage",
	"overview",
	"persons-list",
	"intranet",
	"course-overview",
	"works-overview",
]);

export type AppLayoutProps = PropsWithChildren<{
	layout: Layout;
	className?: string;
}>;

export default function AppLayout({
	layout,
	children,
	className,
}: AppLayoutProps) {
	const { renderStrippedDownSite } = useGlobal();

	const isFullWidth = FULL_WIDTH_LAYOUTS.has(layout);

	return (
		<>
			<div
				className={cn(
					`app-layout app-layout--${layout}`,
					{
						"app-layout--full-width": isFullWidth,
						"app-layout--columns": !isFullWidth,
					},
					className,
				)}
			>
				{!renderStrippedDownSite && (
					<>
						<MainHeader />
						<HeaderCinemode />
					</>
				)}

				<SearchMarker>{children}</SearchMarker>

				{!renderStrippedDownSite && <Footer />}
			</div>
		</>
	);
}
