import { useCallback, useMemo, type RefObject } from "react";
import { FocusOn } from "react-focus-on";

import { isMenuContentElement } from "~/modules/typo3/schema";
import { useGlobal } from "~/context/global";
import NavigationItem from "~/components/shared/NavigationItem";
import { useMainNavigationData } from "~/hooks/useMainNavigationData";

const TOOLS_NAV_COL_POS: number = 811;

export type OverlayToolsProps = {
	mainHeaderRef: RefObject<Element>;
};

export default function OverlayTools({ mainHeaderRef }: OverlayToolsProps) {
	const { setGlobal } = useGlobal();

	const allCes = useMainNavigationData()[`colPos${TOOLS_NAV_COL_POS}`];

	const navItems = useMemo(
		() =>
			allCes
				?.filter(isMenuContentElement)
				.flatMap((menuCe) => menuCe.content.menu) ?? [],
		[allCes],
	);

	const close = useCallback(() => {
		setGlobal((g) => {
			g.openToolbarOverlay = null;
		});
	}, [setGlobal]);

	return (
		<FocusOn
			shards={[mainHeaderRef]}
			onClickOutside={close}
			onEscapeKey={close}
		>
			<div className="toolbar-overlay" data-id="tools">
				<ul
					className="tools-dashboard-grid"
					style={{
						// &.count-19 {
						// 	grid-template-columns: repeat(5, 1fr);
						// }
						gridTemplateColumns: "repeat(5, 1fr)",
					}}
				>
					{navItems.map((item, index) => (
						<NavigationItem key={index} item={item} />
					))}
				</ul>
			</div>
		</FocusOn>
	);
}
