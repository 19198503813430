import { Form } from "@remix-run/react";
import { useState } from "react";

import SearchBarSimple from "../../shared/SearchBarSimple";

export type SearchBarFormProps = {
	formAction: string;
	initialSearchTerm?: string;
	hiddenFields?: Array<[string, string]>;
};

export default function SearchBarForm({
	formAction,
	initialSearchTerm = "",
	hiddenFields,
}: SearchBarFormProps) {
	const [searchTerm, setSearchTerm] = useState(initialSearchTerm);

	return (
		<Form action={formAction}>
			<SearchBarSimple
				searchTerm={searchTerm}
				setSearchTerm={setSearchTerm}
			/>

			{hiddenFields &&
				hiddenFields.map(([key, value]) => (
					<input key={key} type="hidden" name={key} value={value} />
				))}
		</Form>
	);
}
