export type DurationProps = {
	/** in Seconds */
	duration: number;
};

export default function Duration({ duration }: DurationProps) {
	const minutes = Math.floor(duration / 60);
	const seconds = duration % 60;
	return (
		<time
			dateTime={`PT${Math.floor(minutes / 60)}H${minutes % 60}M${seconds}S`}
		>
			{minutes.toString().padStart(2, "0")}:
			{seconds.toString().padStart(2, "0")}
		</time>
	);
}
