import { unreachableValue } from "~/utils/unreachable";
import type * as typo3 from "~/modules/typo3/schema";
import { NewFaces } from "~/components/plugins/persons/NewFaces";
import { EssentialsFoldout } from "~/components/plugins/persons/EssentialsFoldout";
import { PersonsMainContentList } from "~/components/plugins/persons/MainContentList";

type PersonsPluginProps = {
	data: typo3.ContentElementPersons;
};

export default function PersonsPlugin({ data }: PersonsPluginProps) {
	const contentData = data.content.data;
	switch (contentData.action) {
		case "list":
			return (
				<PersonsPluginList data={data} persons={contentData.persons} />
			);

		case "show":
			return "PersonsPlugin show action not implemented";

		default:
			unreachableValue(contentData);
	}
}

export type PersonsPluginListProps = {
	data: typo3.ContentElementPersons;
	persons: Array<typo3.PersonCard>;
};

function PersonsPluginList(props: PersonsPluginListProps) {
	const { colPos } = props.data;
	if (colPos === 0) {
		return <PersonsMainContentList {...props} />;
	}

	// In 3-col layout with different layout:
	if (colPos > 200 && colPos < 300) {
		return <NewFaces {...props} />;
	}

	return <EssentialsFoldout {...props} />;
}
