import { useCallback } from "react";

import { useGlobal } from "~/context/global";
import { cn } from "~/utils/cn";

export default function MobileMenuSwitcher() {
	const { isMobileNavOpen, setGlobal } = useGlobal();

	const handleToggle = useCallback(() => {
		setGlobal((g) => {
			g.isMobileNavOpen = !g.isMobileNavOpen;
		});
	}, [setGlobal]);

	return (
		<button
			id="mobile-menu-switcher"
			className={cn({ active: isMobileNavOpen })}
			tabIndex={0}
			onClick={handleToggle}
		/>
	);
}
