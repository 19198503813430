import { FormattedDate } from "react-intl";

import type * as typo3 from "~/modules/typo3/schema";
import { formatDateForTimeElement } from "~/utils/time";
import AddToCalendarButton from "~/components/shared/AddToCalendarButton";
import { renderArticleType } from "~/components/shared/ArticleType";

import ProjectEssentialsTitle from "./ProjectEssentialsTitle";

function EventDetails({ article }: { article: typo3.Article }) {
	if (!article.eventDate) {
		return null;
	}

	const startDate = new Date(article.eventDate);
	const endDate =
		article.eventEndDate && article.eventEndDate !== article.eventDate
			? new Date(article.eventEndDate)
			: null;

	return (
		<div className="page-title-context">
			<div className="calendar-date">
				<p>
					<time
						className="date"
						dateTime={formatDateForTimeElement(startDate)}
					>
						<FormattedDate
							value={startDate}
							month="numeric"
							day="numeric"
							year={endDate ? undefined : "numeric"}
						/>
					</time>
					{endDate && (
						<>
							{" - "}
							<time
								className="date"
								dateTime={formatDateForTimeElement(endDate)}
							>
								<FormattedDate
									value={endDate}
									month="numeric"
									day="numeric"
									year="numeric"
								/>
							</time>
						</>
					)}
				</p>
			</div>
			<AddToCalendarButton
				event={{
					id: String(article.uid),
					title: article.title,
					description: article.teasertext,
					startTime: startDate,
					endTime: endDate,
					url: article.detailsUrl ?? location.href,
				}}
			/>
		</div>
	);
}

export default function ArticleEssentialsTitle({
	article,
}: {
	article: typo3.Article;
}) {
	if (article.articleType === "projekt") {
		return <ProjectEssentialsTitle article={article} />;
	}

	const label = renderArticleType(article);

	return (
		<>
			<div className="inner">
				{label && <div className="label head top">{label}</div>}
				<h2 className="title">{article.title}</h2>
			</div>

			{article.isEvent && <EventDetails article={article} />}
		</>
	);
}
