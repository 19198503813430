import { useMemo, type ReactNode, Fragment, useEffect, useState } from "react";
import {
	motion,
	useMotionValue,
	easeInOut,
	animate,
	useIsomorphicLayoutEffect,
	usePresence,
	type ValueAnimationTransition,
} from "framer-motion";
import useMeasure from "react-use-measure";
import { Dialog, Heading } from "react-aria-components";
import { FormattedMessage } from "react-intl";

import { mainNavItemsById } from "~/config";
import {
	colPosToNumber,
	isMenuContentElement,
	isSolrContentElement,
} from "~/modules/typo3/schema";
import { cn } from "~/utils/cn";
import * as nonNull from "~/utils/nonNullable";
import { typesafeObjectEntries } from "~/utils/object";
import { slugify } from "~/utils/slugify";
import { type NavOpenState } from "~/components/shared/MainHeader";
import { useGlobal } from "~/context/global";
import NavigationItem from "~/components/shared/NavigationItem";
import { spawnDetached } from "~/utils/async";
import MainNavSubPersons from "~/components/shared/MainNavSubPersons";
import { useMainNavigationData } from "~/hooks/useMainNavigationData";
import type * as typo3 from "~/modules/typo3/schema";

type SectionProps = {
	title?: string;
	children: ReactNode;
};

function Section({ title, children }: SectionProps) {
	const slug = title !== undefined ? slugify(title) : undefined;

	return (
		<div
			className={`section ${title !== undefined ? "has-title" : ""}`}
			data-id={slug}
		>
			{title !== undefined && <h3>{title}</h3>}
			{children}
		</div>
	);
}

const MOTION_HEIGHT_TRANSITION_OPTS: ValueAnimationTransition<number> = {
	ease: easeInOut,
	duration: 0.1,
};

type MainNavSubContentProps = {
	selectedId: string;
	children: ReactNode;
};

const MainNavSubContent = ({
	selectedId,
	children,
}: MainNavSubContentProps) => {
	const { isMobile } = useGlobal();
	const [contentRef, { height: contentHeight }] = useMeasure();
	const [isPresent, safeToRemove] = usePresence();

	const [isInTransition, setIsInTransition] = useState(false);

	const motionHeight = useMotionValue(0);

	useEffect(() => {
		const unsubStart = motionHeight.on("animationStart", () =>
			setIsInTransition(true),
		);
		const unsubEnd = motionHeight.on("animationComplete", () =>
			setIsInTransition(false),
		);
		return () => {
			unsubStart();
			unsubEnd();
		};
	}, [motionHeight]);

	useIsomorphicLayoutEffect(() => {
		// switch on isInTransition as soon as possible, so directly after a content change,
		// to prevent flicker
		setIsInTransition(true);
	}, [selectedId]);

	useEffect(() => {
		animate(motionHeight, contentHeight, MOTION_HEIGHT_TRANSITION_OPTS);
	}, [motionHeight, contentHeight]);

	useEffect(
		() =>
			spawnDetached(async (signal) => {
				if (!isPresent) {
					await animate(
						motionHeight,
						0,
						MOTION_HEIGHT_TRANSITION_OPTS,
					);
					if (!signal.aborted) {
						safeToRemove();
					}
				}
			}),

		[isPresent, motionHeight, safeToRemove],
	);

	if (isMobile) {
		return (
			<div className="sub-nav" data-id={selectedId}>
				<div className="content">{children}</div>
			</div>
		);
	}

	return (
		<motion.div
			className={cn("sub-nav", {
				"in-transition": isInTransition,
			})}
			data-id={selectedId}
			style={{ height: motionHeight }}
		>
			<div ref={contentRef} className="content">
				{children}
			</div>
		</motion.div>
	);
};

type MainNavSubProps = {
	openState: NavOpenState;
	onLinkClick(): void;
};

function MainNavSub({ openState, onLinkClick }: MainNavSubProps) {
	const { isMobile } = useGlobal();

	const mainNavigationData = useMainNavigationData();

	const parentData = mainNavItemsById[openState.id];

	const specialType = openState.id === "personen" ? "persons" : undefined;

	const selectedData = useMemo(() => {
		if (mainNavigationData !== undefined && parentData !== undefined) {
			return typesafeObjectEntries(mainNavigationData)
				.map(
					([colPos, value]) =>
						[colPosToNumber(colPos), value] as const,
				)
				.filter(([colPos]) =>
					(parentData.cols as Array<number>).includes(colPos),
				)
				.sort(([colPosA], [colPosB]) => colPosA - colPosB)
				.map(([, value]) => value)
				.filter(nonNull.is)

				.map((value) =>
					value.filter(
						(item) =>
							isMenuContentElement(item) ||
							isSolrContentElement(item),
					),
				);
		}
	}, [mainNavigationData, parentData]);

	if (selectedData === undefined && specialType == undefined) {
		return null;
	}

	const solrElement = selectedData?.length
		? selectedData.flat().find((item) => item?.type === "solr_pi_results")
		: undefined;

	const menuElements = selectedData?.length
		? selectedData.map((value) =>
				value.filter((item) => isMenuContentElement(item)),
			)
		: undefined;

	const content = (
		<MainNavSubContent selectedId={openState.id}>
			<Dialog className="focus:outline-0">
				<Heading slot="title" className="sr-only">
					<FormattedMessage id="menu" />
					{parentData?.id && (
						<FormattedMessage id={`mainNav.${parentData.id}`} />
					)}
				</Heading>

				{specialType === "persons" && solrElement && (
					<MainNavSubPersons data={solrElement} />
				)}

				{!specialType && menuElements && (
					<div
						className={cn("section-wrapper", {
							"multi-section": menuElements.length > 1,
						})}
					>
						{menuElements.map((menus, index) => (
							<Fragment key={index}>
								{menus.map((menu) => (
									<Section
										key={menu.id}
										title={
											menu.content.headerLayout === 100
												? undefined
												: menu.content.header
										}
									>
										<ul>
											{menu.content.menu.map(
												(item, index) => (
													<NavigationItem
														key={index}
														item={item}
														onClick={onLinkClick}
													/>
												),
											)}
										</ul>
									</Section>
								))}
							</Fragment>
						))}
					</div>
				)}
			</Dialog>
		</MainNavSubContent>
	);

	if (isMobile) {
		return content;
	} else {
		return (
			<nav className="main-nav-sub shadow-lg transition-[background-color]">
				{content}
			</nav>
		);
	}
}

export default MainNavSub;
