import type * as typo3 from "~/modules/typo3/schema";
import OverviewAlphabetical from "~/components/shared/OverviewAlphabetical";
import PersonCard from "~/components/shared/cards/PersonCard";

type SearchResultsPersonProps = {
	documents: Array<typo3.SearchResultDocument>;
};

type PersonSearchDocument = typo3.SearchResultDocument & {
	type: "tx_nnbuhaarticlesandpersons_domain_model_person";
	data: typo3.PersonCard;
};

const isPersonSearchDocument = (
	document: typo3.SearchResultDocument,
): document is PersonSearchDocument =>
	document.type === "tx_nnbuhaarticlesandpersons_domain_model_person";

export default function SearchResultsPerson({
	documents,
}: SearchResultsPersonProps) {
	const persons = documents.filter(isPersonSearchDocument);

	return (
		<OverviewAlphabetical
			mode="persons-list"
			size="full"
			itemsPerRow={4}
			getLetterForItem={getLetterForItem}
			renderItem={renderItem}
			data={persons}
		/>
	);
}

function getLetterForItem(doc: PersonSearchDocument): string {
	if (doc.type === "tx_nnbuhaarticlesandpersons_domain_model_person") {
		return doc.data.lastname
			.substring(0, 1)
			.normalize("NFD")
			.substring(0, 1)
			.toUpperCase();
	}

	return "#";
}

function renderItem(doc: PersonSearchDocument) {
	return <PersonCard person={doc.data} />;
}
