import type * as typo3 from "~/modules/typo3/schema";
import useBackendLayoutProjectsLabel from "~/hooks/useBackendLayoutProjectsLabel";
import { SolrResults } from "~/components/plugins/solr/Solr";

type SectionProjectsProps = {
	page: typo3.Response;
};

const loadMoreUrlTemplate = ({ loadMoreLink }: { loadMoreLink: string }) =>
	`section-projects/${loadMoreLink}`;

export default function SectionProjects({ page }: SectionProjectsProps) {
	const title = useBackendLayoutProjectsLabel(
		page.appearance.backendLayout,
		page.meta.sectionCategory?.title ?? page.meta.title,
	);

	if (!page.projects) return null;

	return (
		<div className="section works-grid module size-full" id="works">
			<div className="works-inner-wrapper inner">
				<SolrResults
					title={title}
					data={page.projects}
					showLoadMore
					loadMoreUrlTemplate={loadMoreUrlTemplate}
				/>
			</div>
		</div>
	);
}
