import { type ReactNode } from "react";

import type * as typo3 from "~/modules/typo3/schema";
import { invariant } from "~/utils/invariant";
import ContentElementGroup from "~/components/content-elements/ContentElementGroup";
import ContentElement, {
	type ContentElementSize,
} from "~/components/content-elements/ContentElement";

export type GroupableContentElementTypes = (typeof groupableTypes)[number];
export type GroupableContentElement = typo3.AnyContentElement extends {
	type: infer T;
}
	? T extends GroupableContentElementTypes
		? typo3.AnyContentElement
		: never
	: never;

export const groupableTypes = [
	"header",
	"text",
	"uploads",
] as const satisfies Array<typo3.AnyContentElement["type"]>;
const groupStartHeaderLayout = 22;
const groupNextHeaderLayout = 32;

function isGroupable(
	contentElement: typo3.AnyContentElement,
): contentElement is GroupableContentElement {
	return groupableTypes.includes(
		contentElement.type as unknown as GroupableContentElementTypes,
	);
}

export type ContentElementsGroupProps = {
	elements: Array<typo3.AnyContentElement>;
	size: ContentElementSize;
};

export default function ContentElementsGroup({
	elements,
	size,
}: ContentElementsGroupProps) {
	const children: Array<ReactNode> = [];

	for (let i = 0; i < elements.length; i++) {
		const contentElement = elements[i];
		invariant(contentElement !== undefined);

		if (
			isGroupable(contentElement) &&
			contentElement.content.headerLayout === groupStartHeaderLayout
		) {
			const group = [contentElement];
			let nextContentElement: (typeof elements)[number] | undefined;
			while (
				(nextContentElement = elements[i + 1]) &&
				isGroupable(nextContentElement) &&
				nextContentElement.content.headerLayout ===
					groupNextHeaderLayout
			) {
				group.push(nextContentElement);
				i++;
			}

			if (group.length > 1) {
				children.push(
					<ContentElementGroup
						key={contentElement.id}
						elements={group}
					/>,
				);
				continue;
			}
		}

		children.push(
			<ContentElement
				key={contentElement.id}
				data={contentElement}
				size={size}
			/>,
		);
	}

	return children;
}
