import MenuItem from "~/components/shared/MenuItem";
import type * as typo3 from "~/modules/typo3/schema";

export type NavigationItemProps = {
	item: typo3.NavigationItem;
	onClick?(): void;
};

export default function NavigationItem({ item, onClick }: NavigationItemProps) {
	return (
		<MenuItem
			title={item.title}
			link={item.link}
			// handle empty target as no target
			linkTarget={item.target || undefined}
			sub={item.abbreviation ?? undefined}
			img={item.thumbnail?.[0]?.publicUrl ?? "/img/gradient-24.jpg"}
			onClick={onClick}
		/>
	);
}
