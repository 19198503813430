import { NavLink } from "@remix-run/react";

import type * as typo3 from "~/modules/typo3/schema";
import { cn } from "~/utils/cn";
import ContentHead from "~/components/shared/ContentHead";
import MenuItemThumb from "~/components/shared/MenuItemThumb";

function Entry({ data }: { data: typo3.NavigationItem }) {
	const thumbnailSrc = data.thumbnail?.[0]?.publicUrl;

	return (
		<li
			className={cn("snippet-with-link", {
				"snippet-with-image": thumbnailSrc,
			})}
		>
			<NavLink
				to={data.link}
				target={data.target === "" ? undefined : data.target}
			>
				<div className="image-wrapper">
					<div className="img-menu-thumb">
						<MenuItemThumb src={thumbnailSrc} />
					</div>
				</div>

				<div className="snippet-info-wrapper">
					<div className="snippet-title">{data.title}</div>
					<div className="snippet-sub-info">{data.subtitle}</div>
				</div>
			</NavLink>
		</li>
	);
}

export default function FoldoutMenu({
	data,
}: {
	data: typo3.ContentElementMenu;
}) {
	return (
		<div className="essential-item essential-foldout-section module">
			<ContentHead mode="label" data={data} />
			<ul className="link-list">
				{data.content.menu.map((navigationItem, index) => (
					<Entry key={index} data={navigationItem} />
				))}
			</ul>
		</div>
	);
}
