import { useMemo } from "react";

import { isMenuContentElement } from "~/modules/typo3/schema";
import type * as typo3 from "~/modules/typo3/schema";
import { useMainNavigationData } from "~/hooks/useMainNavigationData";

const SOCIAL_MEDIA_NAV_COL_POS: number = 1101;

export type SocialEntry = {
	label: string;
	imgUrl: string;
	href: string;
	linkTarget: string | undefined;
};

export default function FooterSocial() {
	const allCes = useMainNavigationData()[`colPos${SOCIAL_MEDIA_NAV_COL_POS}`];

	const socialLinkItems = useMemo(
		() =>
			allCes
				?.filter(isMenuContentElement)
				.flatMap((menuCe) => menuCe.content.menu) ?? [],
		[allCes],
	);

	function Social({ label, href, imgUrl, linkTarget }: SocialEntry) {
		return (
			<div className="social-media-item">
				<a
					href={href}
					target={linkTarget}
					rel="noreferrer"
					className="inner"
				>
					<div className="social-media-item-image">
						<img
							className="mb-[0.2rem] w-[1.25rem] self-center rounded-full object-cover object-center transition-transform group-hover/social:scale-[1.05]"
							loading="lazy"
							src={imgUrl}
							alt={`For ${label}`}
						/>
					</div>
					<div className="social-media-item-label">{label}</div>
				</a>
			</div>
		);
	}

	return (
		<div
			className={`social footer-item element count-${socialLinkItems.length}`}
		>
			{socialLinkItems.map(
				(item: typo3.NavigationItem, index: number) => (
					<Social
						key={index}
						label={item.title}
						href={item.link}
						// handle empty target as no target
						imgUrl={
							item.thumbnail?.[0]?.publicUrl ??
							"/img/gradient-24.jpg"
						}
						linkTarget={item.target || undefined}
					/>
				),
			)}
		</div>
	);
}
