import { useLocation } from "@remix-run/react";
import { useIntl } from "react-intl";

import MenuItem from "~/components/shared/MenuItem";
import type * as typo3 from "~/modules/typo3/schema";
import { ensureNonNullable } from "~/utils/nonNullable";

type SearchFacetSingleProps = {
	template: typo3.SolrTemplate;
	facet: typo3.SolrOptionsFacet;
	/**  @default the current location */
	allUrl?: string;
};

export default function SearchFacetSingle({
	template,
	facet,
	allUrl,
}: SearchFacetSingleProps) {
	const location = useLocation();
	const intl = useIntl();

	return (
		<div className="section-wrapper">
			<div className="section">
				<ul>
					<MenuItem
						title={intl.formatMessage({
							id: `solr.all.${template}`,
						})}
						link={allUrl ?? location.pathname}
						img={
							template === "person"
								? getPersonImage(0)
								: undefined
						}
					/>
					{facet.options.map((option, index) => {
						return (
							<MenuItem
								key={option.value}
								title={option.label}
								link={
									option.active
										? option.uris.unset
										: option.uris.set
								}
								img={
									template === "person"
										? getPersonImage(index + 1)
										: undefined
								}
							/>
						);
					})}
					{template === "person" && (
						<MenuItem
							title={intl.formatMessage({
								id: `solr.all.former`,
							})}
							link={intl.formatMessage({
								id: `urlFormer`,
							})}
							img={
								template === "person"
									? getPersonImage(0)
									: undefined
							}
						/>
					)}
				</ul>
			</div>
		</div>
	);
}

function getPersonImage(index: number): string {
	return ensureNonNullable(PERSONS_IMAGES[index % PERSONS_IMAGES.length]);
}

// TODO: host ourselfs
const PERSONS_IMAGES: Array<string> = [
	"/fileadmin/person_thumbnails/6_personen_alle-personen.jpg",
	"/fileadmin/person_thumbnails/6_personen_behindertenbeauftragte.jpg",
	"/fileadmin/person_thumbnails/6_personen_gleichstellung.jpg",
	"/fileadmin/person_thumbnails/6_personen_international-office.jpg",
	"/fileadmin/person_thumbnails/6_personen_fachschaftsrat-design.jpg",
	"/fileadmin/person_thumbnails/6_personen_oeffentlichkeitsarbeit.jpg",
	"/fileadmin/person_thumbnails/6_personen_professor-innen.jpg",
	"/fileadmin/person_thumbnails/6_personen_kuenstlerische-mitarbeiterinnen.jpg",
	"/fileadmin/person_thumbnails/6_personen_behindertenbeauftragte.jpg",
	"/fileadmin/person_thumbnails/6_personen_werkstaetten.jpg",
	"/fileadmin/person_thumbnails/6_personen_personalrat.jpg",
	"/fileadmin/person_thumbnails/6_personen_stura.jpg",
	"/fileadmin/person_thumbnails/6_personen_fachschaftsrat-kunst.jpg",
	"/fileadmin/person_thumbnails/6_personen_rektorat.jpg",
	"/fileadmin/person_thumbnails/6_personen_senat.jpg",
	"/fileadmin/person_thumbnails/6_personen_verwaltung.jpg",
];
