import { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { escapeRegExp } from "lodash-es";
import clsx from "clsx";
import { Link } from "@remix-run/react";

import { cmpByKey, insensitiveCaseCmp } from "~/utils/cmp";
import { getQuasiEmpty } from "~/utils/typo3";
import type * as typo3 from "~/modules/typo3/schema";
import ScrollContentMain from "~/components/shared/ScrollContentMain";
import AppLayout from "~/components/shared/AppLayout";
import type { ClickFilterOptions } from "~/components/shared/ClickFilter";
import ClickFilter from "~/components/shared/ClickFilter";
import OverviewHeader from "~/components/shared/OverviewHeader";
import SearchBarSimple from "~/components/shared/SearchBarSimple";
import OverviewAlphabetical from "~/components/shared/OverviewAlphabetical";
import DocumentDownloadTile from "~/components/shared/DocumentDownloadTile";
import UserText from "~/components/shared/UserText";
import { useEditPageLink } from "~/components/layouts/PageLayout";
import EditInCMSButton from "~/components/EditInCMSButton";
import { useGlobal } from "~/context/global";

type AToZLayoutProps = {
	page: typo3.Response;
	content: typo3.ContentElementAz;
};

export default function AToZLayout({ page, content }: AToZLayoutProps) {
	const [searchTerm, setSearchTerm] = useState("");
	const [activeOption, setActiveOption] = useState<null | string>(null);

	const [processedData, options] = useMemo(() => {
		const termWords = searchTerm
			.split(/\s+/)
			.filter((word) => 0 < word.length);
		let termRegEx: null | RegExp;
		if (0 < termWords.length) {
			termRegEx = new RegExp(termWords.map(escapeRegExp).join("|"), "i");
		}

		const optionsMap = new Map<string, ClickFilterOptions>();

		// TODO: improve search term evaluation
		const filtered = content.items.filter((item) => {
			item.categories.forEach((category) => {
				const id = String(category.id);
				if (!optionsMap.has(id)) {
					optionsMap.set(id, {
						id,
						label: category.title,
					});
				}
			});

			if (
				activeOption !== null &&
				!item.categories.find(({ id }) => String(id) === activeOption)
			) {
				return false;
			}

			if (
				termRegEx &&
				!item.content.header.match(termRegEx) &&
				!item.content.bodytext.match(termRegEx)
			) {
				return false;
			}

			return true;
		});

		const data: Array<typo3.AzItem> = filtered.sort(
			cmpByKey((item) => item.content.header, insensitiveCaseCmp),
		);

		const options = Array.from(optionsMap.values()).sort(
			cmpByKey((item) => item.label, insensitiveCaseCmp),
		);

		return [data, options] as const;
	}, [searchTerm, activeOption, content.items]);

	const editLink = useEditPageLink(page);
	const { isMobile } = useGlobal();
	return (
		<AppLayout layout="overview">
			<EditInCMSButton link={editLink} />

			<ScrollContentMain>
				<div className="column col-main">
					<div
						className={
							"main-content" +
							(isMobile ? " jump-to-nav mobile" : "")
						}
					>
						<OverviewHeader
							title={
								// TODO: use page title?
								<FormattedMessage id={`solr.title.aToZ`} />
							}
						>
							<div className="filter-container">
								<SearchBarSimple
									searchTerm={searchTerm}
									setSearchTerm={setSearchTerm}
									// ignore submits
									onSubmit={() => {}}
								/>

								<ClickFilter
									onResetClick={() => {
										setActiveOption(null);
										setSearchTerm("");
									}}
									activeOption={activeOption}
									setActiveOption={setActiveOption}
									options={options}
								/>
							</div>
						</OverviewHeader>

						<OverviewAlphabetical
							mode="a-to-z"
							size="full"
							itemsPerRow={3}
							getLetterForItem={getLetterForItem}
							renderItem={renderItem}
							data={processedData}
						/>
					</div>
				</div>

				<div className="column col-aside right">
					{/* <Calendar
						data={page.events}
						sectionDescription={page.meta.title}
					/> */}
				</div>
			</ScrollContentMain>
		</AppLayout>
	);
}

function getLetterForItem({ content: { header } }: typo3.AzItem): string {
	return header
		.substring(0, 1)
		.normalize("NFD")
		.substring(0, 1)
		.toUpperCase();
}

function Item({ item }: { item: typo3.AzItem }) {
	const intl = useIntl();
	const headerLink = getQuasiEmpty(item.content.headerLink);

	return (
		<article
			className={clsx("element", { "long-content": headerLink !== null })}
		>
			<h2>
				<span>
					<UserText
						dangerouslySetInnerHTML={{
							__html: item.content.header,
						}}
					/>
				</span>
			</h2>

			<div className="content">
				<UserText
					dangerouslySetInnerHTML={{ __html: item.content.bodytext }}
				/>

				{item.content.media?.map((dl, dlIndex) => (
					<DocumentDownloadTile
						key={dlIndex}
						size="small"
						download={dl}
					/>
				))}
			</div>

			{headerLink && (
				<div className="more label sub">
					<Link to={headerLink.href}>
						{headerLink.title ??
							headerLink.linkText ??
							intl.formatMessage({ id: "more" })}
					</Link>
				</div>
			)}
		</article>
	);
}

function renderItem(item: typo3.AzItem) {
	return <Item item={item} />;
}
