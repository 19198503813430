import { FormattedMessage } from "react-intl";

import { trimSplit } from "~/utils/text";

export type StudentsEssentialsProps = {
	students: string;
};

export default function StudentsEssentials({
	students,
}: StudentsEssentialsProps) {
	const list = trimSplit(students);
	if (!list.length) {
		return null;
	}

	return (
		<div className="essential-item">
			<div className="content-head mode-label element">
				<h4 className="content-title">
					<FormattedMessage
						id={
							list.length > 1
								? "studentsEssentialsTitle.students"
								: "studentsEssentialsTitle.student"
						}
					/>
				</h4>
			</div>

			<div className="essential-item-content link-list jump-to-nav desktop">
				{list.map((student) => (
					<div key={student}>{student}</div>
				))}
			</div>
		</div>
	);
}
