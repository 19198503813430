import { NavLink } from "@remix-run/react";

import type * as typo3 from "~/modules/typo3/schema";
import ContentHead from "~/components/shared/ContentHead";

type MenuEntryProps = {
	data: typo3.NavigationItem;
};

function Entry({ data }: MenuEntryProps) {
	return (
		<>
			<NavLink
				to={data.link}
				target={data.target === "" ? undefined : data.target}
			>
				{data.title}
			</NavLink>

			{data.children && (
				<ul className="mb-0.5 ml-2">
					{data.children.map((child, index) => (
						<li key={index}>
							<Entry data={child} />
						</li>
					))}
				</ul>
			)}
		</>
	);
}

type EssentialsItemMenuProps = {
	data: typo3.ContentElementMenu;
};

export default function BasicMenu({ data }: EssentialsItemMenuProps) {
	return (
		<div className="essential-item essential-item-nav module">
			<ContentHead mode="label" data={data} />
			<ul className="link-list">
				{data.content.menu.map((navigationItem, index) => (
					<li className="contents" key={index}>
						<Entry data={navigationItem} />
					</li>
				))}
			</ul>
		</div>
	);
}
