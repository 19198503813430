import type * as typo3 from "~/modules/typo3/schema";

import EssentialsItemUnsupported from "./EssentialsItemUnsupported";
import EssentialsItem from "./EssentialsItem";

type EssentialsItemProps = {
	data: typo3.AnyContentElement;
};

export default function EssentialsItemShortcut({ data }: EssentialsItemProps) {
	return data.content.shortcut.map((shortcut: typo3.AnyContentElement) => {
		const contentElement = shortcut as typo3.AnyContentElement;
		switch (contentElement.type) {
			case "text":
			case "header":
			case "html":
			case "menu_sitemap":
			case "menu_sitemap_pages":
			case "menu_pages":
			case "menu_subpages":
			case "nnbuhaarticlesandpersons_persons":
			case "nnbuhaarticlesandpersons_personlist":
				return <EssentialsItem data={contentElement} />;
			default:
				return <EssentialsItemUnsupported type={contentElement.type} />;
		}
	});
}
