import { useIntl } from "react-intl";

const idMap = {
	studiengang: "pageTitleLabel.studiengang",
	schwerpunkt: "pageTitleLabel.schwerpunkt",
	studienrichtung: "pageTitleLabel.studienrichtung",
	einrichtung: "pageTitleLabel.einrichtung",
} as const;

export default function useBackendLayoutLabel(backendLayout: string) {
	const intl = useIntl();
	const id =
		backendLayout in idMap
			? idMap[backendLayout as keyof typeof idMap]
			: null;
	if (id) {
		return intl.formatMessage({ id });
	}

	return undefined;
}
