import { type IconProps } from "~/components/icons";

export default function LockOpen({ label }: IconProps) {
	return (
		<div className="icon lock open">
			<svg
				width="100"
				height="100"
				viewBox="0 0 100 100"
				fill="none"
				aria-label={label}
			>
				<path d="M75 83V47H25V83H75Z" />
				<circle cx="50" cy="61" r="6" />
				<path d="M50 68V76" />
				<path d="M37.3339 46.9054L34.1474 35.0133C31.6399 25.655 37.1799 16.9236 46.2489 14.4936C55.3178 12.0636 64.4813 16.8552 66.9889 26.2134L68.1048 30.3781" />
			</svg>
		</div>
	);
}
