import { useRef } from "react";

import type * as typo3 from "~/modules/typo3/schema";
import UserText from "~/components/shared/UserText";
import useClientNavigationLinks from "~/hooks/useClientNavigationLinks";

type ContentElementApplicationProps = {
	data: typo3.ContentElementApplication;
};

export default function ContentElementApplication({
	data,
}: ContentElementApplicationProps) {
	const contentElementRef = useRef<HTMLDivElement>(null);
	useClientNavigationLinks(contentElementRef);

	return (
		<div id={"c" + data.id} className="application-table module">
			<div className="content-head mode-module element">
				<div className="title-wrapper">
					<h2 className="content-title">{data.content.header}</h2>
				</div>
			</div>
			<div className="inner" ref={contentElementRef}>
				<UserText
					dangerouslySetInnerHTML={{
						__html: data.content.bodytext,
					}}
				/>
			</div>
		</div>
	);
}
