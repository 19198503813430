import type * as typo3 from "~/modules/typo3/schema";
import ContentHead from "~/components/shared/ContentHead";
import Image from "~/components/shared/Image";

export type EssentialsItemImageProps = {
	data: typo3.ContentElementImage;
};

export default function EssentialsItemImage({
	data,
}: EssentialsItemImageProps) {
	return (
		<div className="essential-item">
			<ContentHead mode="label" data={data} />

			<div className="essential-item-content">
				<div className="flex flex-col">
					{data.content.images.map((image, imageIndex) => (
						// TODO: add <figure> etc
						<Image
							key={imageIndex}
							image={image}
							widthPercentage={30}
							widthPercentageMobile={100}
						/>
					))}
				</div>
			</div>
		</div>
	);
}
