export default function AddToCalendar() {
	return (
		<div className="icon">
			<svg width="72" height="100" viewBox="0 0 72 100" fill="none">
				<rect x="2" y="20" width="68" height="59" strokeWidth="4" />
				<path d="M2 37H70" strokeWidth="4" />
				<path d="M26 57L46 57" strokeWidth="4" />
				<path d="M36 47L36 67" strokeWidth="4" />
				<path d="M22 12L22 28" strokeWidth="4" />
				<path d="M50 12L50 28" strokeWidth="4" />
			</svg>
		</div>
	);
}
