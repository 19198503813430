import AppLayout from "~/components/shared/AppLayout";
import ScrollContentMain from "~/components/shared/ScrollContentMain";
import { useGlobal } from "~/context/global";

type ErrorLayoutProps = {
	title: string;
	children: React.ReactNode;
};

export default function ErrorLayout({ title, children }: ErrorLayoutProps) {
	const { isMobile } = useGlobal();

	return (
		<AppLayout layout="error">
			<ScrollContentMain>
				<div className="column col-main">
					<div className="main-content">
						{/* includeSnippet('modules/news',['size' => 'full','count' => 8]); */}
						{/* includeSnippet('modules/media-hero',['podcasts'=>1]); */}

						<h1 className="mb-4 text-4xl font-bold">{title}</h1>
						<div>{children}</div>
					</div>
				</div>
			</ScrollContentMain>
		</AppLayout>
	);
}
