import type * as typo3 from "~/modules/typo3/schema";
import PersonsPlugin from "~/components/plugins/Persons";

import EssentialsItemHeader from "./EssentialsItemHeader";
import EssentialsItemImage from "./EssentialsItemImage";
import EssentialsItemHTML from "./EssentialsItemHTML";
import EssentialsItemText from "./EssentialsItemText";
import EssentialsItemUnknown from "./EssentialsItemUnknown";
import EssentialsItemMenu from "./EssentialsItemMenu";
import EssentialsItemMenuSection from "./EssentialsItemMenuSection";
import EssentialsItemShortcut from "./EssentialsItemShortcut";

type EssentialsItemProps = {
	data: typo3.AnyContentElement;
};

export default function EssentialsItem({ data }: EssentialsItemProps) {
	switch (data.type) {
		case "text":
			return <EssentialsItemText data={data} />;
		case "header":
			return <EssentialsItemHeader data={data} />;
		case "shortcut":
			return <EssentialsItemShortcut data={data} />;
		case "html":
			return <EssentialsItemHTML data={data} />;

		case "menu_sitemap":
		case "menu_sitemap_pages":
		case "menu_pages":
		case "menu_subpages":
			return <EssentialsItemMenu data={data} />;

		case "menu_section":
			return <EssentialsItemMenuSection data={data} />;

		case "image":
			return <EssentialsItemImage data={data} />;

		case "nnbuhaarticlesandpersons_persons":
		case "nnbuhaarticlesandpersons_personlist":
			return <PersonsPlugin data={data} />;

		default:
			return <EssentialsItemUnknown type={data.type} />;
	}
}
