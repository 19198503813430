import { Link } from "@remix-run/react";

import type * as typo3 from "~/modules/typo3/schema";
import Image from "~/components/shared/Image";
import Swiper from "~/components/shared/Swiper";
import { type ContentElementSize } from "~/components/content-elements/ContentElement";
import Video from "~/components/shared/Video";

type SlideProps = {
	media: typo3.Media;
};

function Slide({ media }: SlideProps) {
	let content = (
		<>
			<figure className="container image">
				<div className="container-inner">
					{media.type === "image" && <Image image={media} />}
					{media.type === "video" && <Video video={media} />}
				</div>

				<figcaption className="container title">
					<div className="col-left">
						<div className="card-title whitespace-pre-line">
							{media.properties.description}
						</div>
					</div>

					{/* // TODO: work-showcase */}
					{/* <div className="col-left">
							<div className="card-title">
								<p>{title}</p>
							</div>
							<div className="card-students">
								<p>{students}</p>
							</div>
						</div>

						<div className="col-right">
							<div className="card-studiengang">
								{studiengang}
							</div>

							<div className="card-teachers">{teachers}</div>
						</div> */}
				</figcaption>
			</figure>
		</>
	);

	if (media.properties.link !== null) {
		content = <Link to={media.properties.link}>{content}</Link>;
	}

	return content;
}

export type SlideshowProps = {
	medias: typo3.Media[];
	size: ContentElementSize;
	mode: "work-showcase";
};

export default function Slideshow({ medias, size, mode }: SlideshowProps) {
	return (
		<Swiper
			swiperClassname={`swiper-gallery size-${size}`}
			slideClassname={`element gallery mode-${mode}`}
		>
			{medias.map((media, index) => (
				<Slide key={index} media={media} />
			))}
		</Swiper>
	);
}
