import { type IconProps } from "~/components/icons";

export default function Fullscreen({ label }: IconProps) {
	return (
		<div className="icon fullscreen">
			<svg
				width="100"
				height="100"
				viewBox="0 0 100 100"
				fill="none"
				aria-label={label}
			>
				<path d="M20 40L20 20L40 20" />
				<path d="M60 20L80 20L80 40" />
				<path d="M60 80L80 80L80 60" />
				<path d="M20 60L20 80L40 80" />
			</svg>
		</div>
	);
}
