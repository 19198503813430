import type * as typo3 from "~/modules/typo3/schema";
import ArticlesPlugin from "~/components/plugins/Articles";
import PublicationsPlugin from "~/components/plugins/Publications";
import CalendarPlugin from "~/components/plugins/Calendar";
import LehrangebotePlugin from "~/components/plugins/Lehrangebote";
import ProjectsPlugin from "~/components/plugins/Projects";
import PersonsPlugin from "~/components/plugins/Persons";
import MediaPlugin from "~/components/plugins/Media";
import PodcastStripePlugin from "~/components/plugins/PodcastStripe";
import MediaQuartetPlugin from "~/components/plugins/MediaQuartet";
import ContentElementVideo from "~/components/content-elements/ContentElementVideo";
import ContentElementHeader from "~/components/content-elements/ContentElementHeader";
import ContentElementImage from "~/components/content-elements/ContentElementImage";
import ContentElementHTML from "~/components/content-elements/ContentElementHTML";
import ContentElementTable from "~/components/content-elements/ContentElementTable";
import ContentElementText from "~/components/content-elements/ContentElementText";
import ContentElementUnknown from "~/components/content-elements/ContentElementUnknown";
import ContentElementMenu from "~/components/content-elements/ContentElementMenu";
import ContentElementUploads from "~/components/content-elements/ContentElementUploads";
import ContentElementSlideshow from "~/components/content-elements/ContentElementSlideshow";
import ContentElementForm from "~/components/content-elements/ContentElementForm";
import SolrPlugin from "~/components/plugins/solr/Solr";
import ContentElementSuperNews from "~/components/content-elements/ContentElementSuperNews";
import ContentElementApplication from "~/components/content-elements/ContentElementApplication";
import ContentElementGrantTeaser from "~/components/content-elements/ContentElementGrantTeaser";
import ContentElementShortcut from "~/components/content-elements/ContentElementShortcut";

export type ContentElementSize = "half" | "full";

export type ContentElementProps = {
	data: typo3.AnyContentElement;
	size: ContentElementSize;
};

export default function ContentElement({ data, size }: ContentElementProps) {
	switch (data.type) {
		case "text":
			return <ContentElementText data={data} />;
		case "header":
			return <ContentElementHeader data={data} />;
		case "html":
			return <ContentElementHTML data={data} />;
		case "nnbuha22base_application":
			return <ContentElementApplication data={data} />;
		case "table":
			return <ContentElementTable data={data} />;
		case "shortcut":
			return <ContentElementShortcut data={data} />;

		case "menu_sitemap":
		case "menu_sitemap_pages":
		case "menu_pages":
		case "menu_subpages":
			return <ContentElementMenu data={data} />;

		case "image":
			return <ContentElementImage data={data} />;

		case "video":
			return <ContentElementVideo data={data} size={size} />;

		case "uploads":
			return <ContentElementUploads data={data} />;

		case "form_formframework":
			return <ContentElementForm data={data} />;

		case "nnbuhaarticlesandpersons_articles":
		case "nnbuhaarticlesandpersons_articlestable":
			return <ArticlesPlugin data={data} />;

		case "nnbuhaarticlesandpersons_events":
			return <ContentElementGrantTeaser data={data} />;

		case "nnbuhaarticlesandpersons_lehrangebote":
		case "nnbuhaarticlesandpersons_lehrangeboteselected":
			return <LehrangebotePlugin data={data} />;

		case "nnbuhaarticlesandpersons_projects":
		case "nnbuhaarticlesandpersons_projectsselected":
			return <ProjectsPlugin data={data} size={size} />;

		case "nnbuhaarticlesandpersons_publications":
		case "nnbuhaarticlesandpersons_publicationsselected":
			return <PublicationsPlugin data={data} size={size} />;

		case "nnbuhaarticlesandpersons_persons":
		case "nnbuhaarticlesandpersons_personlist":
			return <PersonsPlugin data={data} />;

		case "nnbuhaarticlesandpersons_calendar":
			return <CalendarPlugin data={data} />;

		case "nnbuha22base_sildeshow":
			return <ContentElementSlideshow data={data} size={size} />;

		case "nnbuha22base_supernews":
			return <ContentElementSuperNews data={data} />;

		case "nnbuhaarticlesandpersons_media":
			return <MediaPlugin data={data} />;

		case "nnbuhaarticlesandpersons_mediaquartet":
			return <MediaQuartetPlugin data={data} />;

		case "nnbuhaarticlesandpersons_podcaststripe":
			return <PodcastStripePlugin data={data} />;

		case "solr_pi_results":
			return <SolrPlugin data={data} />;

		default:
			return <ContentElementUnknown type={data.type} />;
	}
}
