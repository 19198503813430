import { Link } from "@remix-run/react";
import { FormattedMessage } from "react-intl";

import type * as typo3 from "~/modules/typo3/schema";
import ArticleCard from "~/components/shared/cards/ArticleCard";
import PersonCard from "~/components/shared/cards/PersonCard";
import { unreachableValue } from "~/utils/unreachable";
import { cn } from "~/utils/cn";

export type SearchResultsDefaultProps = {
	documents: Array<typo3.SearchResultDocument>;
};

type SearchResultProps = {
	document: typo3.SearchResultDocument;
};

export default function SearchResultsDefault({
	documents,
}: SearchResultsDefaultProps) {
	return (
		<div className="news-overview module">
			<div className="wrapper">
				<div className="content filterTargetWrapper">
					{documents.map((document) => (
						<SearchResult key={document.uid} document={document} />
					))}
				</div>
			</div>
		</div>
	);
}

function SearchResult({ document }: SearchResultProps) {
	if (document.data === null) {
		return null;
	}

	switch (document.type) {
		case "pages":
			return (
				<article
					className={cn(
						"teaser-card element",
						`mode-news`,
						"small-basic",
					)}
				>
					<Link to={document.data.url} className="card-inner">
						<div className="layout">
							<div className="title container">
								<div className="card-title whitespace-pre-line">
									{document.data.title}
								</div>
								<div className="card-teaser">
									{document.data.abstract}
								</div>
							</div>
						</div>
					</Link>
				</article>
			);

		case "tx_nnbuhaarticlesandpersons_domain_model_article":
			return <ArticleCard article={document.data} />;

		case "tx_nnbuhaarticlesandpersons_domain_model_person":
			return <PersonCard person={document.data} />;

		default:
			unreachableValue(document);
	}
}
