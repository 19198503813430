import type * as typo3 from "~/modules/typo3/schema";
import { cn } from "~/utils/cn";
import ContentHead from "~/components/shared/ContentHead";
import ContentElementUnknown from "~/components/content-elements/ContentElementUnknown";
import LehrangebotCard from "~/components/shared/cards/LehrangebotCard";

type LehrangebotePluginProps = {
	data: typo3.ContentElementLehrangebote;
	isOverview?: boolean;
};

export default function LehrangebotePlugin({
	data,
	isOverview = false,
}: LehrangebotePluginProps) {
	const pluginData = data.content.data;

	if (pluginData.action !== "list") {
		return (
			<ContentElementUnknown type={`${data.type}@${pluginData.action}`} />
		);
	}

	return (
		<section className={cn("lehrangebot module", { overview: isOverview })}>
			<ContentHead data={data} />

			<div className={cn("inner", { filterTargetWrapper: isOverview })}>
				{pluginData.articles.map((article) => (
					<LehrangebotCard key={article.uid} article={article} />
				))}
			</div>
		</section>
	);
}
