import { Link } from "@remix-run/react";

import type * as typo3 from "~/modules/typo3/schema";
import DateComponent from "~/components/shared/Date";
import Options, { type RenderOptionReturn } from "~/components/shared/Options";

export type SearchActiveFacetsProps = {
	facets: typo3.ContentElementSolr["content"]["data"]["facets"];
};

export default function SearchActiveFacets({
	facets,
}: SearchActiveFacetsProps) {
	return (
		<div className="active-filter-bar">
			{facets.map((facet) => {
				if (facet.type === "rangeDate" && facet.minSelected) {
					const date = new Date(facet.minSelected);

					return (
						<div
							key={facet.label}
							className="active-filter-bar_section"
						>
							<h3>{facet.label}</h3>
							<ul>
								<li className="option active">
									<Link
										to={facet.uris.unset}
										className="pill-label center"
										preventScrollReset={true}
									>
										<span>
											<DateComponent date={date} year />
										</span>
									</Link>
								</li>
							</ul>
						</div>
					);
				}

				if (facet.type !== "options") {
					return null;
				}

				const activeOptions = facet.options.filter(
					(option) => option.active,
				);

				if (activeOptions.length === 0) {
					return null;
				}

				return (
					<div
						key={facet.label}
						className="active-filter-bar_section"
					>
						<h3>{facet.label}</h3>

						<Options
							options={activeOptions}
							renderOption={renderOption}
						/>
					</div>
				);
			})}
		</div>
	);
}

type Option = typo3.SolrOptionsFacet["options"][number];

function renderOption(o: Option): RenderOptionReturn {
	return {
		key: o.value,
		isActive: true,
		content: (
			<Link
				to={o.uris.unset}
				className="pill-label center"
				preventScrollReset={true}
			>
				<span>{o.label}</span>
			</Link>
		),
	};
}
