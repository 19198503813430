type TopItem = {
	id: string;
	cols: Array<number>;
};

export const mainNavItems = [
	[
		{
			id: "kunst",
			cols: [711, 712, 713],
		},
		{
			id: "design",
			cols: [721, 722, 723],
		},
		{
			id: "studium",
			cols: [731],
		},
	],

	[
		{
			id: "werkstatten",
			cols: [741, 742],
		},
		{
			id: "einrichtungen",
			cols: [751],
		},
	],

	[
		{
			id: "personen",
			cols: [761],
		},
		{
			id: "institution",
			cols: [771],
		},
	],
] as const satisfies Array<Array<TopItem>>;

export const mainNavItemsById = Object.fromEntries(
	mainNavItems.flat().map((item) => [item.id, item] as const),
);
