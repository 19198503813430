import { type IconProps } from "~/components/icons";

export default function Location({ label }: IconProps) {
	return (
		<div className="icon location">
			<svg
				width="100"
				height="100"
				viewBox="0 0 100 100"
				fill="none"
				aria-label={label}
			>
				<circle cx="50.5" cy="42.5" r="11.5" />
				<path d="M50 83C35.8875 67.4492 24.7842 57.1471 25.0032 41.6447C25.0919 35.3656 27.5318 29.1135 32.323 24.3223C42.0861 14.5592 57.9152 14.5592 67.6783 24.3223C72.4695 29.1135 74.9095 35.3656 74.9982 41.6447C75.2172 57.1471 64.1125 67.4492 50 83Z" />
			</svg>
		</div>
	);
}
