import { Link } from "@remix-run/react";
import { useEffect, useRef } from "react";

import { useGlobal } from "~/context/global";
import useLanguage from "~/hooks/useLanguage";
import { getElementOffset } from "~/utils/html";
import { mapNonNullable } from "~/utils/nonNullable";

function LogoType() {
	const language = useLanguage();
	const { isMobile } = useGlobal();

	const logoRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const logo = logoRef.current;
		if (logo === null) {
			return;
		}

		if (isMobile) {
			logo.style.transform = `translateY(0)`;
			return;
		}

		const scrollHandler = () => {
			const winHeight = window.innerHeight;
			const docHeight = document.body.scrollHeight;
			const scrollHeight = docHeight - winHeight;
			// 0..1
			const scrollProgress = window.scrollY / scrollHeight;

			// console.log(
			// 	"docHeight, winHeight, scrollHeight",
			// 	docHeight,
			// 	winHeight,
			// 	scrollHeight,
			// );

			const { height } = logo.getBoundingClientRect();

			const footerLogo = document.querySelector("#footer-logo");
			const { top: footerTop, height: footerHeight } = mapNonNullable(
				getElementOffset,
			)(footerLogo) ?? {
				top: docHeight - 200,
				height: 0,
			};

			const paddingB =
				docHeight - footerTop - (footerHeight - height) * 0.325;
			const logoTop = (winHeight - paddingB) * scrollProgress;
			logo.style.transform = `translateY(${logoTop}px)`;
		};
		scrollHandler();

		document.addEventListener("scroll", scrollHandler, { passive: true });
		return () => {
			document.removeEventListener("scroll", scrollHandler);
		};
	}, [isMobile]);

	return (
		<div id="logotype" className={`element logotype ${language}`}>
			<Link to={language === "en" ? "/en/" : "/"}>
				<div id="logo" ref={logoRef} aria-hidden>
					<svg
						version="1.1"
						viewBox="0 0 29.813 49.84"
						xmlSpace="preserve"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g transform="matrix(1.3333 0 0 -1.3333 0 49.84)">
							<g transform="scale(.1)">
								<path
									d="m71.777 300.2h80l-40 45.981zm-4.1171-17.945v-122.62h88.231v122.62zm-19.438-140.56v160l63.554 72.153 63.551-72.153v-160zm-44.488-48.234v18.32h216.09v-18.32zm11.215-93.457-14.949 11.211c25.797 34.024 65.797 55.703 111.78 55.703 45.981 0 85.981-21.68 111.78-55.703l-14.961-11.211c-22.43 29.531-57.196 48.606-96.821 48.606-39.629 0-74.394-19.074-96.828-48.606"
									fill="#231f20"
								/>
							</g>
						</g>
					</svg>
				</div>

				<div className={`logo-text desktop ${language}`}>
					<span className="line">
						<span>Burg</span> <span>Giebichenstein</span>
					</span>

					{language === "en" ? (
						<>
							<span className="line">
								<span>University of</span>
							</span>
							<span className="line">
								<span>Arts and Design</span> <span>Halle</span>
							</span>
						</>
					) : (
						<>
							<span className="line">
								<span>Kunsthochschule</span> <span>Halle</span>
							</span>
						</>
					)}
				</div>

				<div className="logo-text mobile">
					<span className="line">
						<span>Burg Giebichenstein </span>
						<span>Kunsthochschule Halle</span>
					</span>
				</div>
			</Link>
		</div>
	);
}

export default LogoType;
