import obfuscate from "react-obfuscate";

const Obfuscate = (
	typeof obfuscate === "function"
		? obfuscate
		: // @ts-expect-error workaround for some crazy import/export issue
			obfuscate.default
) as typeof obfuscate;

export default Obfuscate;
