import type { Article } from "~/modules/typo3/schema";

type ArticleDownloadsProps = {
	relatedFiles: Article["relatedFiles"];
};

export default function ArticleDownloads({
	relatedFiles,
}: ArticleDownloadsProps) {
	if (!relatedFiles.length) return null;

	return (
		<div className="essential-item">
			<div className="content-head mode-label element">
				<h4 className="content-title">Downloads</h4>
			</div>

			<div className="essential-item-content">
				<ul>
					{relatedFiles.map((file, dlIndex) => (
						<li key={dlIndex}>
							{/* eslint-disable-next-line react/jsx-no-target-blank */}
							<a
								className="block py-gap-xs"
								href={file.publicUrl}
								download={file.properties.filename}
								type={file.properties.mimeType}
								target="_blank"
							>
								{file.properties.alternative ??
									file.properties.filename}
							</a>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
}
