import { type ReactNode } from "react";

import type * as typo3 from "~/modules/typo3/schema";
import ScrollContentMain from "~/components/shared/ScrollContentMain";
import Calendar from "~/components/shared/calendar/Calendar";
import AppLayout from "~/components/shared/AppLayout";
import { useEditPageLink } from "~/components/layouts/PageLayout";
import EditInCMSButton from "~/components/EditInCMSButton";

export type SearchLayoutProps = {
	page: typo3.Response;
	children: ReactNode;
};

export default function SearchLayout({ page, children }: SearchLayoutProps) {
	const editLink = useEditPageLink(page);

	return (
		<AppLayout layout="overview">
			<EditInCMSButton link={editLink} />

			<ScrollContentMain>
				<div className="column col-main">{children}</div>

				<div className="column col-aside right">
					<Calendar sectionDescription={page.meta.title} />
				</div>
			</ScrollContentMain>
		</AppLayout>
	);
}
