import { Link } from "@remix-run/react";

import type * as typo3 from "~/modules/typo3/schema";
import { getFilteredMedia } from "~/modules/typo3/media";
import Image from "~/components/shared/Image";
import { renderArticleType } from "~/components/shared/ArticleType";

export type ArchiveCardProps = {
	article: typo3.ArticleCard;
};

export default function ArchiveCard({ article }: ArchiveCardProps) {
	const { title, detailsUrl, relatedMedia } = article;
	const images = getFilteredMedia(relatedMedia, "image");
	const displayImages = [images[0] ?? null];
	const label = renderArticleType(article);

	return (
		<article className="teaser-card mode-project element">
			<Link to={detailsUrl ?? "#"} className="block h-full w-full">
				<div className="buha-article__inner">
					<div className="images-wrapper">
						{displayImages.map((image, imageIndex) => (
							<div
								className="buha-article__image"
								key={imageIndex}
							>
								{image ? (
									<Image
										image={image}
										sourceName="thumbnail"
									/>
								) : (
									<div className="aspect-video w-full"></div>
								)}
							</div>
						))}
					</div>

					<div className="container title">
						{label ? (
							<div className="card-label">{label}</div>
						) : null}
						<div className="card-title whitespace-pre-line">
							{title}
						</div>
					</div>
				</div>
			</Link>
		</article>
	);
}
