function leftPad2(num: number) {
	return num < 10 ? `0${num}` : `${num}`;
}

function formatSolrFacetDate(date: Date) {
	const year = date.getFullYear();
	const month = leftPad2(date.getMonth() + 1);
	const day = leftPad2(date.getDate());
	const hours = leftPad2(date.getHours());
	const minutes = leftPad2(date.getMinutes());
	return `${year}${month}${day}${hours}${minutes}`;
}

export function buildRangeDateSetUrlForSingleDate(
	template: string,
	date: Date,
) {
	const todayMidnight = new Date(date);
	todayMidnight.setHours(0, 0, 0, 0);

	const endOfToday = new Date(date);
	endOfToday.setHours(23, 59, 59, 999);

	return template
		.replace("___FROM___", formatSolrFacetDate(todayMidnight))
		.replace("___TO___", formatSolrFacetDate(endOfToday));
}
