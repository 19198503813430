import type { ReactNode } from "react";

import { cn } from "~/utils/cn";

type CalendarUnitProps = {
	className?: string;
	title: ReactNode;
	children: ReactNode;
};

export default function CalendarUnit({
	className = "",
	title,
	children,
}: CalendarUnitProps) {
	return (
		<div className={cn("cal-unit", className)}>
			<div className="unit-title">{title}</div>
			<div className="cal-unit-inner pushy">{children}</div>
		</div>
	);
}
