import { FormattedMessage } from "react-intl";

import { ShowConsentModalButton } from "~/components/ConsentModal";
import {
	getImageSourceRatio,
	getMainImageSource,
} from "~/components/shared/Image";
import { useGlobal } from "~/context/global";
import type * as typo3 from "~/modules/typo3/schema";

export type VideoProps = {
	video: typo3.Video;
};

export default function Video({ video }: VideoProps) {
	const haveVimeoConsent = useGlobal().consents.vimeo;
	const haveYoutubeConsent = useGlobal().consents.youtube;

	const ratio = getImageSourceRatio(
		getMainImageSource(video.imageSources.main),
	);

	if (video.properties.extension === "vimeo" && !haveVimeoConsent) {
		return (
			<div
				className="flex w-full flex-row items-center justify-center rounded-sm bg-gray-200 p-4 text-gray-800 shadow-md"
				style={{ aspectRatio: ratio }}
			>
				<span className="text-center leading-relaxed">
					<FormattedMessage
						id="vimeoVideo.noConsent"
						values={{
							videoLink: (children) => (
								<span className="button-wrapper">
									<a
										href={video.properties.originalUrl}
										target="_blank"
										rel="noreferrer"
										className="video-link"
									>
										{children}
									</a>
								</span>
							),
							showConsentModal: (children) => (
								<span className="button-wrapper">
									<ShowConsentModalButton>
										{children}
									</ShowConsentModalButton>
								</span>
							),
							br: <br />,
						}}
					/>
				</span>
			</div>
		);
	}

	if (video.properties.extension === "youtube" && !haveYoutubeConsent) {
		return (
			<div
				className="flex w-full flex-row items-center justify-center rounded-sm bg-gray-200 p-4 text-gray-800 shadow-md"
				style={{ aspectRatio: ratio }}
			>
				<span className="text-center leading-relaxed">
					<FormattedMessage
						id="youtubeVideo.noConsent"
						values={{
							videoLink: (children) => (
								<span className="button-wrapper">
									<a
										href={video.properties.originalUrl}
										target="_blank"
										rel="noreferrer"
										className="video-link"
									>
										{children}
									</a>
								</span>
							),
							showConsentModal: (children) => (
								<span className="button-wrapper">
									<ShowConsentModalButton>
										{children}
									</ShowConsentModalButton>
								</span>
							),
							br: <br />,
						}}
					/>
				</span>
			</div>
		);
	}

	return (
		<iframe
			title={video.properties.title ?? undefined}
			src={video.publicUrl}
			className={video.properties.extension ?? "video"}
			style={{ width: "100%", aspectRatio: ratio }}
			frameBorder="0"
			allow="autoplay; fullscreen; picture-in-picture; encrypted-media"
			allowFullScreen
		/>
	);
}
