export type FormatForTimeElementOptions = {
	day?: boolean;
	time?: boolean;
};

export function formatDateForTimeElement(
	date: Date,
	{ day = true, time = false }: FormatForTimeElementOptions = {},
): string {
	let res = `${padZero(date.getFullYear(), 4)}-${padZero(date.getMonth() + 1)}`;

	if (day) {
		res += `-${padZero(date.getDate())}`;

		if (time) {
			res += ` ${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}`;
		}
	}

	return res;
}

function padZero(num: number, length: number = 2): string {
	return num.toString().padStart(length, "0");
}
