import { Link } from "@remix-run/react";
import type { HTMLProps, ReactNode } from "react";

import { cn } from "~/utils/cn";
import type * as typo3 from "~/modules/typo3/schema";
import Image from "~/components/shared/Image";
import Video from "~/components/shared/Video";

type TeaserCardProps = Omit<HTMLProps<HTMLDivElement>, "label"> & {
	mode?:
		| "default"
		| "news"
		| "event"
		| "supernews"
		| "lehrangebot"
		| "event-main"
		| "event-main-highlight"
		| "event-aside"
		| "person-small"
		| "timeline"
		| "grant-teaser";
	image?: typo3.Media;
	video?: typo3.Video;
	label?: ReactNode;
	teaser?: string;
	published?: ReactNode;
	date?: ReactNode;
	time?: ReactNode;
	meta?: ReactNode;
	/** Called "ref" in the prototype */
	href?: string;
	active?: boolean;
	imageSourceName?: typo3.ImageSourceName;
	imageStyle?: "contain" | "cover" | "free";
};

function TeaserCard({
	mode = "default",
	title = "Printing in mid air",
	href = "#",
	imageSourceName = "thumbnail",
	imageStyle = "cover",

	image,
	video,
	label,
	teaser,
	published,
	className,
	date,
	time,
	meta,
	active = false,
	...attrs
}: TeaserCardProps) {
	const hasImage = image !== undefined;
	const hasVideo = video !== undefined;
	const hasMedia = hasImage || hasVideo;
	const hasDateTime = Boolean(date || time);

	return (
		<article
			className={cn("teaser-card element", `mode-${mode}`, className, {
				"no-img": !hasMedia,
				"has-vid": hasVideo,
				"has-img": hasImage,
				active: active,
				[`img-${imageStyle}`]: hasImage,
			})}
			{...attrs}
		>
			<Link to={href} className="card-inner">
				<div className="layout">
					{published && (
						<div className="published container">
							<div className="card-published">{published}</div>
						</div>
					)}

					{hasDateTime && (
						<div className="event container">
							<div className="card-date">{date}</div>
							<div className="card-time">{time}</div>
						</div>
					)}

					<div className="image container">
						{hasImage && imageStyle === "contain" && (
							<div className="card-bg-img">
								{image && (
									<Image
										image={image}
										sourceName={imageSourceName}
									/>
								)}
							</div>
						)}

						<div
							className={cn("card-img", {
								"bg-gradient": hasMedia || mode === "supernews",
							})}
						>
							{/*
							TODO: Handle special case substr($mode, 0, 5) == 'event' && $cardLabel == 'Gremien'
							 => Show Burg Logo
							*/}
							{hasVideo && <Video video={video} />}
							{hasImage && (
								<Image
									image={image}
									sourceName={imageSourceName}
								/>
							)}
						</div>
					</div>

					<div className="title container">
						{label && label !== "-" ? (
							<div className="card-label">{label}</div>
						) : null}
						<div className="card-title whitespace-pre-line">
							{title}
						</div>
						{meta && <div className="card-meta">{meta}</div>}
						{teaser && <div className="card-teaser">{teaser}</div>}
					</div>

					{mode === "event" && (
						<div className="cta container">
							<button>
								{/* TODO */}
								{Math.random() >= 0.8
									? "Registrierung"
									: "Ticket"}
							</button>
						</div>
					)}
				</div>
			</Link>
		</article>
	);
}

export default TeaserCard;
