import type * as typo3 from "~/modules/typo3/schema";
import { isVideo } from "~/modules/typo3/media";
import VideoGroup from "~/components/shared/VideoGroup";

type MediaQuartetPluginProps = {
	data: typo3.ContentElementMediaQuartetStripe;
};

export default function MediaQuartetPlugin({ data }: MediaQuartetPluginProps) {
	const articles = data.content.data.articles.filter(
		(article) => article.relatedMedia.filter(isVideo).length > 0,
	);

	return <VideoGroup layout="quartet" articles={articles} />;
}
