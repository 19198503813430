import type * as typo3 from "~/modules/typo3/schema";
import PodcastStripe from "~/components/shared/PodcastStripe";

type PodcastStripePluginProps = {
	data: typo3.ContentElementPodcastStripe;
};

export default function PodcastStripePlugin({
	data,
}: PodcastStripePluginProps) {
	return (
		<PodcastStripe
			title={data.content.header}
			podcasts={data.content.data.articles}
		/>
	);
}
