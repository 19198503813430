import type { ReactNode } from "react";
import { useMemo } from "react";
import htmlToReact, {
	type HTMLReactParserOptions,
	type DOMNode,
	Element,
	domToReact,
} from "html-react-parser";

import Obfuscate from "./Obfuscate";

export type UserTextProps = {
	dangerouslySetInnerHTML: {
		__html: string | TrustedHTML;
	};
};

export default function UserText({ dangerouslySetInnerHTML }: UserTextProps) {
	const htmlContent = dangerouslySetInnerHTML.__html;
	const content: ReactNode = useMemo(
		() => htmlToReact(String(htmlContent), htmlToReactOptions),
		[htmlContent],
	);
	return content;
}

const linkProtocolsToObfuscate = new Set(["mailto", "tel", "sms", "facetime"]);

const htmlToReactOptions: HTMLReactParserOptions = {
	replace(node) {
		if (
			node instanceof Element &&
			node.name === "a" &&
			node.attribs.href !== undefined
		) {
			const hrefProtEnd = node.attribs.href.indexOf(":");
			if (
				0 <= hrefProtEnd &&
				linkProtocolsToObfuscate.has(
					node.attribs.href.substring(0, hrefProtEnd),
				)
			) {
				return (
					<Obfuscate
						element="a"
						href={node.attribs.href}
						// @ts-expect-error broken prop types
						className="text-nowrap break-keep"
					>
						{domToReact(node.children as DOMNode[])}
					</Obfuscate>
				);
			}
		}
	},
};
