import { Link } from "@remix-run/react";

import usePageData from "~/hooks/usePageData";

export default function LanguageSwitcher() {
	const data = usePageData();
	if (!data) {
		return null;
	}

	const otherLanguages = data.i18n.filter(
		({ active, available }) => available === 1 && active === 0,
	);

	return (
		<>
			{otherLanguages.length === 0 ? (
				<Link
					to="/en"
					hrefLang="en"
					lang="en"
					dir="ltr"
					key="en"
					reloadDocument={true}
				>
					<span>English</span>
				</Link>
			) : (
				otherLanguages.map((lang) => (
					<Link
						to={lang.link}
						lang={lang.hreflang}
						hrefLang={lang.hreflang}
						dir={lang.direction}
						key={lang.languageId}
						reloadDocument={true}
					>
						<span>{lang.title}</span>
					</Link>
				))
			)}
		</>
	);
}
