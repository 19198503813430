export default function BrightSun() {
	return (
		<span
			id="brightsun"
			className="icon"
			tabIndex={-1}
			aria-hidden={true}
			// focusable="false"
		>
			<svg width="100" height="100" viewBox="0 0 100 100" fill="none">
				<mask
					id="ray-mask"
					style={{ maskType: "alpha" }}
					maskUnits="userSpaceOnUse"
					x="8"
					y="8"
					width="84"
					height="84"
				>
					<circle cx="50" cy="50" r="37" fill="white" />
				</mask>

				<g id="rays" mask="url(#ray-mask)">
					<path d="M50 4V23" strokeWidth="4" />
					<path d="M50 96L50 77" strokeWidth="4" />
					<path d="M96 50L77 50" strokeWidth="4" />
					<path d="M4 50L23 50" strokeWidth="4" />
					<path
						d="M82.5269 17.4731L69.0919 30.9081"
						strokeWidth="4"
					/>
					<path
						d="M17.4731 82.5269L30.9081 69.0919"
						strokeWidth="4"
					/>
					<path
						d="M82.5269 82.5269L69.0919 69.0919"
						strokeWidth="4"
					/>
					<path
						d="M17.4731 17.4731L30.9081 30.9081"
						strokeWidth="4"
					/>
				</g>

				<circle id="core" cx="50" cy="50" r="17" strokeWidth="4" />
			</svg>
		</span>
	);
}
