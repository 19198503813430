import type * as typo3 from "~/modules/typo3/schema";
import BasicMenu from "~/components/essentials/menu/BasicMenu";
import FoldoutMenu from "~/components/essentials/menu/FoldoutMenu";

export default function EssentialsItemMenu({
	data,
}: {
	data: typo3.ContentElementMenu;
}) {
	if (data.appearance.layout === "201") {
		return <FoldoutMenu data={data} />;
	}

	return <BasicMenu data={data} />;
}
