import { type IconProps } from "~/components/icons";

export default function LockClosed({ label }: IconProps) {
	return (
		<div className="icon lock closed">
			<svg
				width="100"
				height="100"
				viewBox="0 0 100 100"
				fill="none"
				aria-label={label}
			>
				<path d="M33 45V32.6884C33 23 40.6112 16 50 16C59.3888 16 67 23 67 32.6884V45" />
				<path d="M75 83V47H25V83H75Z" />
				<circle cx="50" cy="61" r="6" />
				<path d="M50 68V76" />
			</svg>
		</div>
	);
}
