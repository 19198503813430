export type ChunkArrayResult<T> = Array<Array<T>>;

export function chunkArray<T>(
	chunkSize: number,
	values: Array<T>,
): ChunkArrayResult<T> {
	const result: ChunkArrayResult<T> = [];
	for (let i = 0; i < values.length; i += chunkSize) {
		const chunk = values.slice(i, i + chunkSize);
		result.push(chunk);
	}
	return result;
}

export function makeArray<T>(
	length: number,
	f: (index: number) => T,
): Array<T> {
	return Array.from({ length }, (_, i) => f(i));
}

export function extractItem<SpecificT extends BaseT, BaseT = unknown>(
	values: Array<BaseT> = [],
	predicate: (item: BaseT) => item is SpecificT,
): [SpecificT | null, Array<BaseT>] {
	const index = values.findIndex(predicate);
	if (index > -1) {
		return [
			values[index] as SpecificT,
			values.filter((_, i) => i !== index),
		] as const;
	}

	return [null, values] as const;
}
