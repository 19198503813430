import { useCallback, useEffect, useState } from "react";
import { Link } from "@remix-run/react";
import { FormattedMessage } from "react-intl";

import type * as typo3 from "~/modules/typo3/schema";
import ContentElement from "~/components/content-elements/ContentElement";
import Calendar from "~/components/shared/calendar/Calendar";
import { useGlobal } from "~/context/global";
import usePrevious from "~/hooks/usePrevious";
import useSpecialPageUrl from "~/hooks/useSpecialPageUrl";
import AppLayout from "~/components/shared/AppLayout";
import ScrollContentMain from "~/components/shared/ScrollContentMain";
import { useEditPageLink } from "~/components/layouts/PageLayout";
import EditInCMSButton from "~/components/EditInCMSButton";

type HomeLayoutProps = {
	page: typo3.Response;
};

export default function HomeLayout({ page }: HomeLayoutProps) {
	const content = page.content;
	const mediaPageUrl = useSpecialPageUrl("mediaPage") ?? "/media";
	const { isMobileReal, setGlobal, isCalendarOpen } = useGlobal();
	const [calendarCloseOnScrollEnabled, setCalendarCloseOnScrollEnabled] =
		useState(false);
	const [isMouseOverCalendar, setIsMouseOverCalendar] = useState(false);

	const prevIsMobileReal = usePrevious(isMobileReal);
	// enable calendar close-on-scroll only on desktop
	useEffect(() => {
		if (prevIsMobileReal === null && isMobileReal !== null) {
			setCalendarCloseOnScrollEnabled(!isMobileReal);
		}
	}, [prevIsMobileReal, isMobileReal]);

	const prevIsCalendarOpen = usePrevious(isCalendarOpen);
	// manually or on-scroll closing the calendar disables close-on-scroll
	useEffect(() => {
		if (prevIsCalendarOpen && !isCalendarOpen) {
			setCalendarCloseOnScrollEnabled(false);
		}
	}, [isCalendarOpen, prevIsCalendarOpen]);

	useEffect(() => {
		if (!calendarCloseOnScrollEnabled) {
			return;
		}

		// open the calendar initially
		setGlobal((g) => {
			g.isCalendarOpen = true;
		});

		if (!isMouseOverCalendar) {
			const handleScroll = () => {
				setGlobal((g) => {
					g.isCalendarOpen = false;
				});
			};

			window.addEventListener("scroll", handleScroll, { passive: true });
			return () => {
				window.removeEventListener("scroll", handleScroll);
			};
		}
	}, [setGlobal, calendarCloseOnScrollEnabled, isMouseOverCalendar]);

	const handleMouseEnterCalendar = useCallback(
		() => setIsMouseOverCalendar(true),
		[],
	);
	const handleMouseLeaveCalendar = useCallback(
		() => setIsMouseOverCalendar(false),
		[],
	);
	const editLink = useEditPageLink(page);

	const { isMobile } = useGlobal();

	return (
		<AppLayout layout="frontpage">
			<EditInCMSButton link={editLink} />

			<ScrollContentMain>
				<div className="column col-main">
					<div
						className={
							"main-content" +
							(isMobile ? " jump-to-nav mobile" : "")
						}
					>
						{/* Supernews */}
						{content.colPos50?.map((contentElement) => (
							<ContentElement
								key={contentElement.id}
								data={contentElement}
								size="full"
							/>
						))}

						{/*
						// Interne personalisierte News
						if( $loggedIn ) {
							includeSnippet('modules/news',['title'=>'Deine neuesten internen Meldungen','sub'=>'Alle Deinen internen Meldungen','size' => 'full','layout' => 'table']);
						}

						// Interne personalisierte News
						if( $loggedIn ) {
							$newsSub = '';
							$newsSubRef = '';
						} else {
							$newsSub = ['Alle Meldungen','Interne Meldungen'];
							$newsSubRef = ['news-overview.php','index.php?login=true'];
						}
						includeSnippet('modules/news',['size' => 'full','count' => 8,'sub' => $newsSub,'subRef' => $newsSubRef]);
						*/}

						{content.colPos0?.map((contentElement) => (
							<ContentElement
								key={contentElement.id}
								data={contentElement}
								size="full"
							/>
						))}

						{/* includeSnippet('modules/media-hero',['podcasts'=>1]); */}
						<div className="media-hero module">
							<div className="card">
								<div className="inner">
									<div className="content-head mode-module element">
										<div className="title-wrapper sub-layout-horizontal">
											<h2 className="content-title">
												<FormattedMessage id="home.media.title" />
											</h2>

											<div className="sub-title-content">
												<div className="label sub head">
													<Link to={mediaPageUrl}>
														<FormattedMessage id="home.media.allMedia" />
													</Link>
												</div>
											</div>
										</div>
									</div>

									{content.colPos100?.map(
										(contentElement) => (
											<ContentElement
												key={contentElement.id}
												data={contentElement}
												size="full"
											/>
										),
									)}
								</div>
							</div>
						</div>

						{/*
							// later editors will be able to decide through the backend if they want to show the "grant teaser module" or the "burg galerie teaser module"
							$rand = random_int(1,2); // set to 1,2
							if($rand == 1) {
								includeSnippet('modules/grant-teaser');
							} else {
								includeSnippet('modules/burg-galerie-teaser');
							}

							includeSnippet('modules/publications',['title' => 'Ausgewählte Publikationen','size'=>'half','id'=>'bild-raum-objekt-glas']);

							includeSnippet('modules/new-faces');

							*/}
						<div className="modules-row">
							{content.colPos210?.map((contentElement) => (
								<ContentElement
									key={contentElement.id}
									data={contentElement}
									size="half"
								/>
							))}
							{content.colPos220?.map((contentElement) => (
								<ContentElement
									key={contentElement.id}
									data={contentElement}
									size="half"
								/>
							))}
							{content.colPos230?.map((contentElement) => (
								<ContentElement
									key={contentElement.id}
									data={contentElement}
									size="half"
								/>
							))}
						</div>

						{content.colPos20?.map((contentElement) => (
							<ContentElement
								key={contentElement.id}
								data={contentElement}
								size="full"
							/>
						))}

						{/* includeSnippet('modules/friction-teaser',['title' => 'Fragments of Change','id' => '01']); */}
						{/* <ContentElementFrictionTeaser mockData={1} /> */}
					</div>
				</div>

				<div
					className="column col-aside right"
					onMouseEnter={handleMouseEnterCalendar}
					onMouseLeave={handleMouseLeaveCalendar}
				>
					<Calendar sectionDescription="Burg" />
				</div>
			</ScrollContentMain>
		</AppLayout>
	);
}
