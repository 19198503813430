import SearchBarForm from "~/components/plugins/solr/SearchBarForm";
import SearchFacetSingle from "~/components/plugins/solr/SearchFacetSingle";
import type * as typo3 from "~/modules/typo3/schema";
import useLanguage from "~/hooks/useLanguage";

export type ContentElementProps = {
	data: typo3.ContentElementSolr;
};

export default function MainNavSubPersons({ data }: ContentElementProps) {
	const facets = data.content?.data?.facets ?? [];
	const facet: typo3.SolrOptionsFacet | undefined =
		facets.length > 0 ? facets[0] : undefined;
	const lang = useLanguage();
	const allUrl = lang === "de" ? "/personen" : "/en/persons";

	return (
		<>
			<SearchBarForm formAction="/personen" />
			{facet && (
				<SearchFacetSingle
					template="person"
					allUrl={allUrl}
					facet={facet}
				/>
			)}
		</>
	);
}
