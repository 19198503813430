import type * as typo3 from "~/modules/typo3/schema";
import ContentElementImageSingle from "~/components/content-elements/ContentElementImage/ContentElementImageSingle";
import ContentElementImageGroup from "~/components/content-elements/ContentElementImage/ContentElementImageGroup";

export type ContentElementImageProps = {
	data: typo3.ContentElementImage;
};

export default function ContentElementImage({
	data,
}: ContentElementImageProps) {
	const images = data.content.images;

	if (images.length > 1) {
		return <ContentElementImageGroup data={data} />;
	}

	return <ContentElementImageSingle data={data} />;
}
