import type * as typo3 from "~/modules/typo3/schema";
import BodyText from "~/components/shared/BodyText";
import ContentHead from "~/components/shared/ContentHead";

type EssentialsItemTextProps = {
	data: typo3.ContentElementText;
};

export default function EssentialsItemText({ data }: EssentialsItemTextProps) {
	return (
		<div className="essential-item module">
			<ContentHead mode="label" data={data} />

			<div className="essential-item-content">
				<BodyText
					dangerouslySetInnerHTML={{ __html: data.content.bodytext }}
				/>
			</div>
		</div>
	);
}
