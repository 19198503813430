export type CalculateContentDistanceReturn = {
	left: number;
	right: number;
	top: number;
	bottom: number;
};

export function calculateContentDistance(
	elem: Element,
): CalculateContentDistanceReturn {
	const s = getComputedStyle(elem);
	return {
		left: parseInt(s.marginLeft) + parseInt(s.paddingLeft),
		right: parseInt(s.marginRight) + parseInt(s.paddingRight),
		top: parseInt(s.marginTop) + parseInt(s.paddingTop),
		bottom: parseInt(s.marginBottom) + parseInt(s.paddingBottom),
	};
}

export function getElementOffset(elem: Element): DOMRect {
	const bodyR = document.body.getBoundingClientRect();
	const r = elem.getBoundingClientRect();

	return new DOMRect(r.x - bodyR.x, r.y - bodyR.y, r.width, r.height);
}

export function isMainActionEventCause(e: {
	altKey: boolean;
	button?: number;
	ctrlKey: boolean;
	metaKey: boolean;
	shiftKey: boolean;
}): boolean {
	return (
		!e.altKey &&
		!e.ctrlKey &&
		!e.metaKey &&
		!e.shiftKey &&
		(e.button === undefined || e.button === 0)
	);
}
