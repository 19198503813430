import type * as typo3 from "~/modules/typo3/schema";

import ContentHead from "../shared/ContentHead";

type ContentElementHeaderProps = {
	data: typo3.ContentElementHeader;
};

export default function ContentElementHeader({
	data,
}: ContentElementHeaderProps) {
	return (
		<section className="text module">
			<ContentHead data={data} />
		</section>
	);
}
