import { type ReactNode, type Key, useMemo } from "react";
import { motion, AnimatePresence } from "framer-motion";

import { cn } from "~/utils/cn";

export type RenderOptionReturn = {
	isActive: boolean;
	key: Key;
	content: ReactNode;
};

export type OptionsProp<T> = {
	className?: string;
	options: ReadonlyArray<T>;
	renderOption(option: T): RenderOptionReturn;
	/** @default false */
	staggerAnimation?: boolean;
};

export default function Options<T>({
	className,
	options,
	renderOption,
	staggerAnimation = false,
}: OptionsProp<T>) {
	const content = useMemo(
		() =>
			options.map((option) => {
				const { key, isActive, content } = renderOption(option);
				return (
					<motion.li
						key={key}
						className={cn("option", { active: isActive })}
						variants={{
							initial: { opacity: 0 },
							animate: { opacity: 1 },
						}}
						initial={staggerAnimation ? undefined : "initial"}
						animate={staggerAnimation ? undefined : "animate"}
						exit={staggerAnimation ? undefined : "initial"}
						transition={{ duration: 0.2 }}
					>
						{content}
					</motion.li>
				);
			}),
		[options, renderOption, staggerAnimation],
	);

	return (
		<ul className={className}>
			{staggerAnimation ? (
				content
			) : (
				<AnimatePresence>{content}</AnimatePresence>
			)}
		</ul>
	);
}
