export interface EssentialsItemProps {
	type: string;
}

export default function EssentialsItemUnsupported({
	type,
}: EssentialsItemProps) {
	return (
		<div className="essential-item text my-6 bg-yellow-200 p-2 font-bold text-red-900">
			Unsupported Element of Type [{type}] in Shortcut Content Element.
		</div>
	);
}
