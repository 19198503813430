import { FormattedMessage } from "react-intl";

import AddToCalendar from "~/components/icons/AddToCalendar";
import { isNonNullable } from "~/utils/nonNullable";

const ICS_FEED_NAME = "Burg";

type IcsEvent = {
	id: string;
	title: string;
	description: string;
	startTime: Date;
	endTime: Date | null;
	url: string;
};

type AddEventButtonProps = {
	event: IcsEvent;
};

function formatIcsDate(date: Date): string {
	return (
		date
			.toISOString() // UTC
			.slice(0, -5) // remove MS and Z
			.replace(/[:-]/g, "") + // Remove colons and dashes
		"Z"
	);
}

function createIcsEvent(event: IcsEvent): string {
	const { hostname } = location;

	return (
		[
			"BEGIN:VCALENDAR",
			"VERSION:2.0",
			`PRODID:-//${hostname}//Notifications Event`,
			`X-WR-CALNAME:${ICS_FEED_NAME}`,
			`NAME:${ICS_FEED_NAME}`,
			"CALSCALE:GREGORIAN",
			"BEGIN:VEVENT",
			`DTSTAMP:${formatIcsDate(new Date())}`,
			`UID:${event.id}@${hostname}`,
			`DTSTART:${formatIcsDate(event.startTime)}`,
			event.endTime && `DTEND:${formatIcsDate(event.endTime)}`,
			`SUMMARY:${event.title}`,
			`DESCRIPTION:${event.description} ${event.url}`,
			"END:VEVENT",
			"END:VCALENDAR",
		]
			.filter(isNonNullable)
			// Split every 72 characters and add a new line (CRLF) with one space
			.map((str) => str.match(/.{1,72}/g)?.join("\r\n ") ?? str)
			.join("\r\n")
	);
}

export default function AddToCalendarButton({ event }: AddEventButtonProps) {
	//const handleGCalClick = () => {
	//	const { title, description, startTime, endTime, url } = event;
	//	const googleCalendarEventUrl = new URL(
	//		"https://www.google.com/calendar/render",
	//	);
	//	googleCalendarEventUrl.searchParams.append("action", "TEMPLATE");
	//	googleCalendarEventUrl.searchParams.append("text", title + " " + url);
	//	googleCalendarEventUrl.searchParams.append(
	//		"dates",
	//		`${formatIcsDate(startTime)}/${formatIcsDate(endTime || startTime)}`,
	//	);
	//	googleCalendarEventUrl.searchParams.append("details", description);
	//
	//	window.open(googleCalendarEventUrl, "_blank");
	//};

	const handleIcsClick = () => {
		const blob = new Blob([createIcsEvent(event)], {
			type: "text/calendar;charset=utf-8",
		});

		const link = document.createElement("a");
		link.href = URL.createObjectURL(blob);
		link.download = "event.ics";
		link.target = "_blank";
		link.click();
		URL.revokeObjectURL(link.href);
	};

	return (
		<>
			{/*
			<button
				className="button--add-to-calendar icon-button"
				onClick={handleGCalClick}
			>
				<AddToCalendar />
				Google
			</button>
			*/}

			<button
				className="button--add-to-calendar icon-button"
				onClick={handleIcsClick}
			>
				<AddToCalendar />
				<FormattedMessage id="addToCalendar" />
			</button>
		</>
	);
}
