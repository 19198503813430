import { FormattedMessage } from "react-intl";

import type * as typo3 from "~/modules/typo3/schema";
import { isEventType } from "~/modules/typo3/schema";

type ArticleTypeProps = Pick<
	typo3.ArticleCard,
	| "articleType"
	| "eventType"
	| "articleSubtypeCategory"
	| "articleTypeCategory"
>;

export function renderArticleType({
	articleType,
	eventType,
	articleSubtypeCategory,
	articleTypeCategory,
}: ArticleTypeProps) {
	if (articleType === "termin") {
		return (
			<FormattedMessage
				id={
					isEventType(eventType)
						? `eventType.${eventType}`
						: "eventType.default"
				}
			/>
		);
	}

	if (articleType === "mitteilung" && articleSubtypeCategory?.title) {
		if (articleSubtypeCategory?.title === "-") {
			return null;
		}
		return articleSubtypeCategory?.title;
	}

	return articleTypeCategory?.title;
}

export default function ArticleType(props: ArticleTypeProps) {
	return renderArticleType(props);
}
