import {
	type ReactNode,
	type MouseEvent,
	useCallback,
	useState,
	useEffect,
	useRef,
	type KeyboardEventHandler,
} from "react";
import useMeasure from "react-use-measure";
import { motion } from "framer-motion";

import type * as typo3 from "~/modules/typo3/schema";
import { useGlobal } from "~/context/global";
import { delay, spawnDetached } from "~/utils/async";
import { cn } from "~/utils/cn";
import ContentHead from "~/components/shared/ContentHead";
import Image from "~/components/shared/Image";

const TRANSITION_DURATION_S = 0.3;
const ANIMATION_DURATION = 400;

export type ContentElementImageGroupProps = {
	data: typo3.ContentElementImage;
};

export default function ContentElementImageGroup({
	data,
}: ContentElementImageGroupProps) {
	const {
		isInCinemode,
		isInTransition,
		isExtend: globalIsExtend,
		setGlobal,
		isMobile,
	} = useGlobal();

	const wrapperRef = useRef<HTMLDivElement>(null);
	const [hoverControlRef, hoverControlRect] = useMeasure();
	// 0 = center, -1 = left most, +1 = right most
	const [mousePosition, setMousePosition] = useState(0);
	const [isExtended, setIsExtended] = useState(false);
	const [isMouseOn, setIsMouseOn] = useState(false);
	const [isTransitionEnabled, setIsTransitionEnabled] = useState(true);

	useEffect(
		() =>
			spawnDetached(async () => {
				if (isMouseOn) {
					setIsTransitionEnabled(true);
					await delay(TRANSITION_DURATION_S * 1_000);
					setIsTransitionEnabled(false);
				} else {
					setIsTransitionEnabled(true);
				}
			}),
		[isMouseOn],
	);

	const handleMouseEnter = useCallback(() => setIsMouseOn(true), []);
	const handleMouseLeave = useCallback(() => setIsMouseOn(false), []);

	const handleMouseMove = useCallback(
		(ev: MouseEvent<HTMLElement>) => {
			const x = ev.pageX - hoverControlRect.x;
			const xRel = (x / hoverControlRect.width - 0.5) * 2;
			setMousePosition(xRel);
		},
		[hoverControlRect],
	);

	const toggleExtended = useCallback(
		() =>
			spawnDetached(async () => {
				if (isInCinemode || isInTransition) {
					return;
				}

				if (!isExtended && !globalIsExtend) {
					setIsExtended(true);
					wrapperRef.current?.scrollIntoView({
						behavior: "smooth",
						block: "nearest",
					});

					setGlobal((g) => {
						g.isInTransition = true;
						g.isExtend = true;
					});

					await delay(ANIMATION_DURATION);

					setGlobal((g) => {
						g.isInTransition = false;
					});
				} else {
					setIsExtended(false);

					setGlobal((g) => {
						g.isInTransition = true;
						g.isExtend = false;
					});

					await delay(ANIMATION_DURATION);

					setGlobal((g) => {
						g.isInTransition = false;
					});
				}
			}),
		[globalIsExtend, isExtended, isInCinemode, isInTransition, setGlobal],
	);

	const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback(
		(ev) => {
			if (ev.key === "Enter" || ev.key === " ") {
				ev.preventDefault();
				toggleExtended();
			}
		},
		[toggleExtended],
	);

	const items: ReactNode = data.content.images.map((image, index) => (
		<div key={index} className="gallery-item">
			<figure>
				<Image
					image={image}
					widthPercentage={
						isMobile || isExtended || isInCinemode ? 100 : 10
					}
				/>

				<figcaption>
					<span>{image.properties.description}</span>
				</figcaption>
			</figure>
		</div>
	));

	let content;
	if (isMobile) {
		content = <div className="gallery-inner">{items}</div>;
	} else {
		content = (
			<motion.div
				className="gallery-inner"
				animate={{
					x: `${-mousePosition * 50}%`,
				}}
				transition={{
					duration: isTransitionEnabled ? TRANSITION_DURATION_S : 0,
					ease: "linear",
				}}
			>
				{items}
			</motion.div>
		);
	}

	return (
		<div
			tabIndex={0}
			role="button"
			className={cn(
				"module image-group media-wrapper-outer-wrapper extendable",
				{ extended: isExtended || isInCinemode },
			)}
			onMouseEnter={!isMobile ? handleMouseEnter : undefined}
			onMouseLeave={!isMobile ? handleMouseLeave : undefined}
			onMouseMove={!isMobile ? handleMouseMove : undefined}
			onClick={toggleExtended}
			onKeyDown={!isExtended ? handleKeyDown : undefined}
		>
			<ContentHead data={data} />

			<div className="gallery-wrapper" ref={wrapperRef}>
				{content}

				<div className="hover-control" ref={hoverControlRef} />
			</div>

			<div className="extender">⭤</div>
		</div>
	);
}
