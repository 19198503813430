import {
	createContext,
	useContext,
	useEffect,
	useMemo,
	type PropsWithChildren,
	useRef,
} from "react";
import { mergeRefs } from "react-merge-refs";
import useMeasure from "react-use-measure";

import { useGlobal } from "~/context/global";

const ColumnsContext = createContext({
	mainHeight: 0,
});

export function useScrollContentContext() {
	return useContext(ColumnsContext);
}

export default function ScrollContentMain({ children }: PropsWithChildren) {
	const { isMobile } = useGlobal();
	const [mainMeasureRef, { height: mainHeight }] = useMeasure();
	const mainRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!mainRef.current) {
			return;
		}
		const mainElem = mainRef.current;

		if (isMobile) {
			mainElem.style.removeProperty("min-height");
			return;
		}

		function updateEnvironment() {
			// TODO: observe .scroll-content for height changes?
			const scrollContentElements = Array.from(
				mainElem.querySelectorAll(
					".col-aside.left .essentials .scroll-content",
				),
			) as Array<HTMLElement>;
			const totalScrollContentHeight = scrollContentElements.reduce(
				(total, scrollElement) => total + scrollElement.offsetHeight,
				0,
			);
			const minHeight = Math.max(
				totalScrollContentHeight,
				window.innerHeight * 1.5,
			);
			mainElem.style.minHeight = `${minHeight}px`;
		}
		updateEnvironment();

		window.addEventListener("resize", updateEnvironment, { passive: true });
		return () => {
			window.removeEventListener("resize", updateEnvironment);
		};
	}, [isMobile]);

	const contextValue = useMemo(() => ({ mainHeight }), [mainHeight]);

	return (
		<ColumnsContext.Provider value={contextValue}>
			<div id="main" ref={mergeRefs([mainRef, mainMeasureRef])}>
				{children}
			</div>
		</ColumnsContext.Provider>
	);
}
