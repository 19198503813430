import { FormattedMessage } from "react-intl";

import type * as typo3 from "~/modules/typo3/schema";
import { isEventType } from "~/modules/typo3/schema";
import { cn } from "~/utils/cn";
import TeaserCard from "~/components/shared/cards/TeaserCard";
import DateComponent from "~/components/shared/Date";

type EventCardProps = {
	article: typo3.ArticleCard;
	isHighlight?: boolean;
	isOverview?: boolean;
};

export default function EventCard({
	article,
	isHighlight,
	isOverview,
}: EventCardProps) {
	const timeStart = article.eventStartEndtime ?? "";
	const dateStart = article.eventDate ? new Date(article.eventDate) : null;
	const dateEnd = article.eventEndDate
		? new Date(article.eventEndDate)
		: null;

	return (
		<TeaserCard
			mode={
				isOverview
					? isHighlight
						? "event-main-highlight"
						: "event-main"
					: "event-aside"
			}
			className={cn({ "small-basic": !isOverview })}
			image={article.relatedMedia[0]}
			title={article.title}
			label={
				<FormattedMessage
					id={
						isEventType(article.eventType)
							? `eventType.${article.eventType}`
							: "eventType.default"
					}
				/>
			}
			href={article.detailsUrl}
			time={timeStart}
			date={
				dateStart && (
					<>
						<DateComponent
							date={dateStart}
							weekday
							year={dateEnd ? false : "2-digit"}
						/>
						{dateEnd && (
							<>
								<span className="dash">&ndash;</span>
								<DateComponent
									date={dateEnd}
									weekday
									year="2-digit"
								/>
							</>
						)}
					</>
				)
			}
		/>
	);
}
