export interface ContentElementProps {
	type: string;
}

export default function ContentElementUnsupported({
	type,
}: ContentElementProps) {
	return (
		<div className="module text my-6 bg-yellow-200 p-2 font-bold text-red-900">
			<>
				Unsupported Element of Type [{type}] in Shortcut Content
				Element.
			</>
		</div>
	);
}
