import type { Image, Media, Video, Audio } from "./schema";

export const isVideo = (media: Media): media is Video => media.type === "video";
export const isImage = (media: Media): media is Image => media.type === "image";
export const isPodcast = (media: Media): media is Audio => media.type === "audio";

export function filterMedia<MediaType extends Media["type"]>(
	media: Media,
	type: MediaType,
): media is Media & {
	type: MediaType;
} {
	return media.type === type;
}

export function getFilteredMedia<MediaType extends Media["type"]>(
	media: Media[],
	type: MediaType,
) {
	return media.filter((m) => filterMedia(m, type));
}

export function findFirstMediaByType<MediaType extends Media["type"]>(
	media: Media[],
	type: MediaType,
) {
	return media.find((m) => filterMedia(m, type));
}
