import type * as typo3 from "~/modules/typo3/schema";
import ArchiveCard from "~/components/shared/cards/ArchiveCard";
import { isArticleSearchDocument } from "~/components/plugins/solr/results/SearchResultsArticle";

export type SearchResultsArchiveProps = {
	documents: Array<typo3.SearchResultDocument>;
};

export default function SearchResultsSectionArchive({
	documents,
}: SearchResultsArchiveProps) {
	const colCount = Math.max(3, Math.min(7, Math.floor(documents.length / 3)));

	return (
		<div
			className={`works-grid-container filterTargetWrapper col-count-${colCount}`}
		>
			{documents
				.filter(isArticleSearchDocument)
				.map(({ data: article }) => (
					<ArchiveCard key={article.uid} article={article} />
				))}
		</div>
	);
}
