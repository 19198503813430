import { Link } from "@remix-run/react";

import MenuItemThumb from "~/components/shared/MenuItemThumb";
import { slugify } from "~/utils/slugify";

type MenuItemProps = {
	title: string;
	link: string;
	linkTarget?: string;
	sub?: string;
	tag?: string;
	img?: string;
	onClick?: () => void;
};

function MenuItem({
	title,
	sub,
	tag,
	link,
	linkTarget,
	img,
	onClick,
}: MenuItemProps) {
	const slug = slugify(title);
	const subSlug = sub && slugify(sub);

	return (
		<li className="element menu-item" data-id={slug} data-tag={tag}>
			<Link
				prefetch="intent"
				to={link}
				target={linkTarget}
				onClick={onClick}
				className="menu-item-inner"
			>
				{img && (
					<div className="image-wrapper">
						<MenuItemThumb src={img} />
					</div>
				)}

				<div className="title-wrapper">
					<div className="menu-title">
						<span>{title}</span>
						{sub && (
							<span className="sub" data-id={subSlug}>
								{sub}
							</span>
						)}
					</div>
				</div>
			</Link>
		</li>
	);
}

export default MenuItem;
