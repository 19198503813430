import type { RefObject } from "react";
import { useEffect } from "react";
import { useNavigate } from "@remix-run/react";

import { isMainActionEventCause } from "~/utils/html";

function isAnchorOpeningInCurrentWindow(target: HTMLAnchorElement) {
	return (
		[null, undefined, "", "self"].includes(target.target) &&
		!target.hasAttribute("download")
	);
}

export default function useClientNavigationLinks(
	elementRef: RefObject<HTMLElement>,
) {
	const navigate = useNavigate();

	useEffect(() => {
		const controller = new AbortController();
		elementRef.current?.addEventListener(
			"click",
			(event) => {
				const target = (event.target as Partial<HTMLElement>).closest?.(
					"a",
				);
				if (!target) return;

				const url = new URL(target.href, location.origin);
				if (
					url.origin === location.origin &&
					isMainActionEventCause(event) &&
					isAnchorOpeningInCurrentWindow(target)
				) {
					event.preventDefault();
					navigate(url.pathname + url.search + url.hash);
				}
			},
			{ signal: controller.signal },
		);

		return () => controller.abort();
	});
}
