function MenuItemThumb({ src = "/img/gradient-24.jpg" }: { src?: string }) {
	return (
		<div className="img-menu-thumb">
			<div className="image-container bg-gradient">
				<div className="image-content background">
					<img src={src} loading="lazy" alt="" />
				</div>
				<div className="image-content foreground">
					<img src={src} loading="lazy" alt="" />
				</div>
			</div>
		</div>
	);
}

export default MenuItemThumb;
