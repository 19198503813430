import { type ReactNode } from "react";

import Marquee from "~/components/shared/Marquee";
import MouseXScroll from "~/components/shared/MouseXScroll";

type MediaStripeProps = {
	title: string;
	children: ReactNode;
};

export default function MediaStripe({ title, children }: MediaStripeProps) {
	return (
		<div className="module stripe">
			<Marquee>{title}</Marquee>

			<MouseXScroll className="media-grid panel">
				<div className="panel-scroller content">
					<div className="panel-inner layout-row">{children}</div>
				</div>
			</MouseXScroll>
		</div>
	);
}
