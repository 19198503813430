import { NavLink } from "@remix-run/react";

import type * as typo3 from "~/modules/typo3/schema";
import ContentHead from "~/components/shared/ContentHead";
import MenuItemThumb from "~/components/shared/MenuItemThumb";

type MenuEntryProps = {
	data: typo3.NavigationItem;
};

function MenuEntry({ data }: MenuEntryProps) {
	return (
		<li
			className="element menu-item"
			data-id="designmethoden-und-experiment"
		>
			<NavLink
				className="menu-item-inner"
				to={data.link}
				target={data.target === "" ? undefined : data.target}
			>
				<div className="image-wrapper">
					<MenuItemThumb src={data.thumbnail?.[0]?.publicUrl} />
				</div>

				<div className="title-wrapper">
					<div className="menu-title no-sub">
						<span>{data.title}</span>
					</div>
				</div>
			</NavLink>
		</li>
	);
}

export type ContentElementMenuProps = {
	data: typo3.ContentElementMenu;
};

export default function ContentElementMenu({ data }: ContentElementMenuProps) {
	return (
		<section className="schwerpunkte module">
			<ContentHead data={data} />

			<ul className="pushy item-count-6">
				{data.content.menu.map((navigationItem, index) => (
					<li key={index}>
						<MenuEntry data={navigationItem} />
					</li>
				))}
			</ul>
		</section>
	);
}
